import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { VirtualsService } from 'src/app/core/services/virtuals.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { UserType } from 'src/app/shared/models/account.model';
import { VirtualsMenuGame } from 'src/app/shared/models/virtuals.model';

@Component({
  selector: 'app-virtuals-offerings',
  templateUrl: './virtuals-offerings.component.html',
  styleUrls: ['./virtuals-offerings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsOfferingsComponent implements OnInit, OnDestroy {
  // TODO: Logic bound to this subject should be replaced once the code of branch epic/VIR-7006 is merged
  readonly loginDialogGame$: BehaviorSubject<VirtualsMenuGame> = new BehaviorSubject<VirtualsMenuGame>(undefined);
  readonly filteredGameCategories$: BehaviorSubject<VirtualsMenuGame[]> = new BehaviorSubject([]);
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public virtualsQuery: VirtualsQuery,
    private readonly virtualsService: VirtualsService,
    private readonly authenticationService: AuthenticationService,
    private readonly accountService: AccountService,
    private readonly accountQuery: AccountQuery,
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly accountStore: AccountStore
  ) {}

  ngOnInit(): void {
    this.processMenuSections();
  }

  processMenuSections(): void {
    this.checkIfVirtualsOrGoldenRaceIsEnabled();
    if (this.virtualsQuery.menuSections === undefined) {
      // if menuSections is still undefined, that means that the data has not been retrieved yet
      this.virtualsService.getVirtualsMenuSections().subscribe();
    }
    this.virtualsQuery.menuSections$
      .pipe(
        map((sections: []) => {
          const newSections = sections?.map((section: any) => {
            section.id = section?.name?.toLowerCase()?.replaceAll(' ', '-');
            return section;
          });

          this.route.queryParams.subscribe(params => {
            const { category, showAll } = params;
            if (newSections?.length && category) {
              const prioritizedCategory = newSections[newSections?.findIndex(section => section?.id === category)];
              if (prioritizedCategory) {
                this.filteredGameCategories$?.next([prioritizedCategory]);
              } else {
                this.filteredGameCategories$?.next(newSections);
              }
              if (showAll !== 'false') {
                this.filteredGameCategories$?.next([prioritizedCategory, ...newSections.filter(section => section?.id !== category)]);
              }
            } else {
              this.filteredGameCategories$?.next(newSections);
            }
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  isGameDisabled(game: VirtualsMenuGame): boolean {
    const isSubAccount = this.accountQuery.userData && this.accountQuery.userData.userTypeCode === UserType.SubAccountPlayer;
    const virtualsConfig = this.appConfig.get('virtuals');
    const disabledGamesForSubAccountPlayers = virtualsConfig.goldenRace.disabledGamesForSubAccountPlayers;
    const disabledLeaguesForSubAccountPlayers = virtualsConfig.config.disabledLeaguesForSubAccountPlayers;

    return !!(
      isSubAccount &&
      (disabledGamesForSubAccountPlayers.includes(game.code) || disabledLeaguesForSubAccountPlayers.includes(game.code))
    );
  }

  openGame(game: VirtualsMenuGame): void {
    const virtualsConfig = this.appConfig.get('virtuals');

    if (this.isGameDisabled(game)) {
      this.notificationService.showInfoNotification($localize`Please check back soon`, $localize`Coming Soon`);
      return;
    }

    this.loginDialogGame$.next(undefined);

    if (this.accountQuery.isAuthenticated) {
      // validate if the token is still valid
      this.authenticationService
        .validateToken(this.accountQuery.accessToken)
        .pipe(first())
        .subscribe(
          data => {
            if (data && data.isValidated === false) {
              // if token is invalid, logout the user
              this.accountService.clearUserData();

              // show the login dialog if required
              if (virtualsConfig && virtualsConfig.showLoginDialog) {
                this.loginDialogGame$.next(game);
              } else {
                this.router.navigate(['/virtual', game.code]);
              }
            } else {
              // if token is valid or no data was returned, proceed to the game
              this.router.navigate(['/virtual', game.code]);
            }
          },
          () => {
            // if an error occurs during token validation, proceed to the game with the current user details
            this.router.navigate(['/virtual', game.code]);
          }
        );
    } else {
      if (virtualsConfig && virtualsConfig.showLoginDialog) {
        setTimeout(() => {
          // A timeout is required since we want a delay between setting the game
          // to undefined and defining a new game value. This will ensure the previous
          // instance of the login dialog is removed before creating a new one
          this.loginDialogGame$.next(game);
        });
      } else {
        this.router.navigate(['/virtual', game.code]);
      }
    }
  }

  private checkIfVirtualsOrGoldenRaceIsEnabled() {
    const accessToken = this.accountQuery.userData && this.accountQuery.userData.accessToken;

    if (!accessToken) {
      return;
    }

    const loggedInUserData = this.accountQuery.userData;

    forkJoin([this.accountService.isVirtualsEnabled(accessToken), this.accountService.isGoldenRaceEnabled(accessToken)])
      .pipe(
        map(([isVirtualsEnabled, isGoldenRaceEnabled]) => {
          const userData = { ...loggedInUserData, isVirtualsEnabled: isVirtualsEnabled, isGoldenRaceEnabled: isGoldenRaceEnabled };
          this.accountStore.updateUserData(userData);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
