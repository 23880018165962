import { Injectable, OnDestroy } from '@angular/core';
import { format } from 'date-fns';
import { trim } from 'lodash-es';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { ResolverService } from 'src/app/core/services/resolver.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  CategoryModel,
  CorrectScoreOddsModel,
  EventSelectionState,
  EventSummaryModel,
  FlattenedSportModel,
  MarketModel,
  MatchModel,
  presetTypeMap,
  QuicklinksState,
  QuicklinkType,
  RegionModel,
  SelectionModel,
  SportModel,
  SportQuicklink,
  SportQuicklinks,
  TournamentModel,
} from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class SportService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly regionsCache: any = [];
  private readonly areasCache: any = [];
  private readonly isPlayerAreaSub$: Subscription = undefined;
  private initialPrematchRequestMade: boolean;

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly sportStore: SportStore,
    private readonly apiService: APIService,
    private readonly couponService: CouponService,
    private readonly sportQuery: SportQuery,
    private readonly applicationQuery: ApplicationQuery,
    private readonly resolverService: ResolverService
  ) {
    this.isPlayerAreaSub$ = this.sportQuery.isPlayerArea$.subscribe(isPlayerAreaSub => {
      if (!isPlayerAreaSub) {
        this.sportStore.clearPlayersData();
      }
    });

    this.applicationQuery.isSportsSection$
      .pipe(
        distinctUntilChanged(),
        tap(isSportsSection => {
          if (!isSportsSection) {
            // When going out of sports pages, reset selected quicklink
            if (this.sportQuery.eventSelectionQuicklinks && this.sportQuery.eventSelectionQuicklinks.quicklinks.length > 0) {
              this.updateEventSelection({ selectedQuicklink: this.sportQuery.eventSelectionQuicklinks.quicklinks[0] });
            }
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  restoreAreaRegionsCacheToggle(bool: boolean): void {
    this.sportStore.updateEventSelection({ restoreAreaRegionsCache: bool });
  }

  restoreAreaRegionsCache(): void {
    this.restoreAreaRegionsCacheToggle(true);
    const newAreas = [];
    const newRegions = [];
    const regionAndAreaCaches = [];
    this.areasCache.forEach(areas => {
      areas.forEach(area => {
        const newArea = new AreaModel({
          id: area.AreaID,
          name: area.AreaName,
          order: area.AreaOrder,
        });
        newAreas.push(newArea);
      });

      this.regionsCache.forEach(regions => {
        regions.forEach(region => {
          const newRegion = new RegionModel({
            areaIds: region.AreaIds,
            id: region.RegionID,
            name: region.RegionName,
            order: region.RegionOrder,
          });
          newRegions.push(newRegion);
        });
      });
      const reducedAreas = newAreas.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

      const reducedRegions = newRegions.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

      const reducedAreasWithOwnership = reducedAreas.map(area => {
        const belongsTo = [];
        regionAndAreaCaches.forEach((prematch, index) => {
          prematch.areas.forEach(prematchArea => {
            if (area.id === prematchArea.id) {
              belongsTo.push(index);
            }
          });
        });
        return { ...area, belongsTo };
      });

      const reducedRegionsWithOwnership = reducedRegions.map(region => {
        const belongsTo = [];
        regionAndAreaCaches.forEach((prematch, index) => {
          prematch.regions.forEach(prematchRegion => {
            if (region.id === prematchRegion.id) {
              belongsTo.push(index);
            }
          });
        });
        return { ...region, belongsTo };
      });

      this.sportStore.updateAreas(reducedAreasWithOwnership);
      this.sportStore.updateRegions(reducedRegionsWithOwnership);
    });
  }

  getPrematchEventData(
    language: string = 'en',
    scheduleTimeFrame: number,
    leagueIds: string[],
    areaId: number = 0,
    regionId: number = 0,
    cacheRegionsAndAreas: boolean = false,
    initialRegionArea: boolean = false
  ): Observable<any[]> {
    // If we make a request for defaults with areaId 0 and regionId 0, after updating the selected area in the store, don't make an
    // additional request with the changed selected area id
    if (this.initialPrematchRequestMade) {
      this.initialPrematchRequestMade = false;
      return new Observable();
    }
    if ((areaId === 0 && regionId === 0) || initialRegionArea) {
      this.initialPrematchRequestMade = true;
    }
    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true,
    });

    const apiCalls = [];

    leagueIds.forEach(leagueId => {
      const url = `api/feeds/prematch/${language}/${scheduleTimeFrame}/${leagueId}/${areaId}/${regionId}`;

      const call = this.apiService.get(APIType.SportsbookFeed, url, apiSettings);
      apiCalls.push(call);
    });

    return forkJoin(apiCalls).pipe(
      tap((dataArray: any) => {
        const newAreas = [];
        const newRegions = [];
        const parsed = [];
        const regionAndAreaCaches = [];
        dataArray.forEach((data, index) => {
          if (data.AreaMatches.length === 0) {
            return;
          }
          const cache = { areas: [], regions: [], visible: true };
          if (data.Areas.length) {
            this.areasCache[index] = data.Areas;

            this.areasCache[index].forEach(area => {
              const newArea = new AreaModel({
                id: area.AreaID,
                name: area.AreaName,
                order: area.AreaOrder,
              });
              newAreas.push(newArea);
              cache.areas.push(newArea);
            });

            if (data.Regions.length) {
              this.regionsCache[index] = data.Regions;

              this.regionsCache[index].forEach(region => {
                const newRegion = new RegionModel({
                  areaIds: region.AreaIds,
                  id: region.RegionID,
                  name: region.RegionName,
                  order: region.RegionOrder,
                });
                newRegions.push(newRegion);
                cache.regions.push(newRegion);
              });
              regionAndAreaCaches.push(cache);
            }
          }

          if (data.AreaMatches[0]) {
            parsed.push(this.mapMatchSummaryDataToModel(data.AreaMatches[0]));
          } else {
            parsed.push({ ...this.sportQuery.selectedPrematch[index] });
          }
          if (!parsed) {
            return;
          }

          if (parsed[index] && parsed[index].area) {
            const correctScoreAreaIds = this.appConfig.get('correctScoreAreaIds');
            correctScoreAreaIds.forEach((id: number) => {
              if (parsed[index].area.id === id) {
                this.sportStore.updateIsItCorrectScore(true);
              } else {
                this.sportStore.updateIsItCorrectScore(false);
              }
            });
          }
        });

        if (cacheRegionsAndAreas) {
          this.sportStore.updateEventSelection({ areaAndRegionCache: regionAndAreaCaches });
        }

        const reducedAreas = newAreas.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

        const reducedRegions = newRegions.reduce((acc, x) => acc.concat(acc.find(y => y.name === x.name) ? [] : [x]), []);

        const reducedAreasWithOwnership = reducedAreas.map(area => {
          const belongsTo = [];
          regionAndAreaCaches.forEach((prematch, index) => {
            prematch.areas.forEach(prematchArea => {
              if (area.id === prematchArea.id) {
                belongsTo.push(index);
              }
            });
          });
          return { ...area, belongsTo };
        });

        const reducedRegionsWithOwnership = reducedRegions.map(region => {
          const belongsTo = [];
          regionAndAreaCaches.forEach((prematch, index) => {
            prematch.regions.forEach(prematchRegion => {
              if (region.id === prematchRegion.id) {
                belongsTo.push(index);
              }
            });
          });
          return { ...region, belongsTo };
        });

        this.sportStore.updateAreas(reducedAreasWithOwnership);
        this.sportStore.updateRegions(reducedRegionsWithOwnership);

        this.sportStore.updateSelectedPrematch(parsed);

        if (this.sportQuery.selectedArea) {
          parsed.forEach(data => {
            if (data.area.id === this.sportQuery.selectedArea.id) {
              this.sportStore.updateEventSelection({
                selectedMarket: data.area.markets[0],
                areaMarkets: data.area.markets,
                selectedAreaId: data.area.id,
              });
            }
          });
        } else {
          if (parsed[0]) {
            this.sportStore.updateEventSelection({
              selectedMarket: parsed[0].area.markets[0],
              areaMarkets: parsed[0].area.markets,
              selectedAreaId: parsed[0].area.id,
            });
          }
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  mapMatchSummaryDataToModel(responseData: any): EventSummaryModel {
    if (!responseData) {
      return;
    }
    const marketData = responseData.AreaMarkets[0];
    const isItPlayerMultiLineType = responseData.GroupingType === 2 && marketData.OddsType.MultilineType === 1;
    const matches: MatchModel[] = [];
    responseData.Items.forEach(item =>
      matches.push(
        this.mapMatchDataToModel(item, responseData.SportID, responseData.SportName, isItPlayerMultiLineType, responseData.GroupingType)
      )
    );

    const markets = [];
    responseData.AreaMarkets.forEach(market => {
      const selections: SelectionModel[] = [];

      market.Markets.forEach(selection => selections.push(this.mapSelectionDataToModel(selection)));

      markets.push(
        new MarketModel({
          id: market.OddsType.OddsTypeID,
          typeId: market.OddsType.IDGroupMarketType,
          name: market.OddsType.OddsTypeName,
          description: market.OddsType.OddsDescription,
          spreadDisplayValue: market.SpecialValueDisplay,
          spreadValue: market.SpecialBetValue,
          selections,
        })
      );
    });

    const correctScoreType = this.appConfig.get('correctScoreAreaIds').filter(data => data === responseData.Area.AreaID).length ? 1 : 0;

    const overUnderType = marketData.SpecialValueDisplay.length > 3 || parseFloat(marketData.SpecialValueDisplay) !== 0 ? 1 : 0;

    return new EventSummaryModel({
      sportId: responseData.SportID,
      sportName: responseData.SportName,
      groupingType: responseData.GroupingType,
      multiLineType: marketData.Markets.length > 3 ? 1 : 0,
      correctScoreType,
      overUnderType,
      area: new AreaModel({
        id: responseData.Area.AreaID,
        name: responseData.Area.AreaName,
        order: responseData.Area.AreaOrder,
        isDefault: true,
        markets,
      }),
      marketSelected: markets[0],
      matches,
    });
  }

  mapLiveMatchSummaryDataToModel(responseData: any): EventSummaryModel {
    if (!responseData || responseData.length === 0) {
      return;
    }
    const matches: MatchModel[] = [];
    responseData.forEach(item => matches.push(this.mapLiveMatchDataToModel(item)));
    const markets = [];
    const selections: SelectionModel[] = [];
    const matchWithTwoOdds = matches.find(match => match.odds && match.odds.length === 2);
    const matchWithThreeOdds = matches.find(match => match.odds && match.odds.length === 3);

    if (matchWithTwoOdds) {
      selections.push(
        new SelectionModel({
          id: 1,
          name: '1',
        })
      );

      selections.push(
        new SelectionModel({
          id: 2,
          name: '2',
        })
      );

      markets.push(
        new MarketModel({
          id: matchWithTwoOdds.odds[0].marketTypeId,
          typeId: matchWithTwoOdds.odds[0].marketTypeId,
          name: '1 2',
          description: '',
          selections,
        })
      );
    } else if (matchWithThreeOdds) {
      selections.push(
        new SelectionModel({
          id: 1,
          name: '1',
        })
      );

      selections.push(
        new SelectionModel({
          id: 2,
          name: 'X',
        })
      );

      selections.push(
        new SelectionModel({
          id: 3,
          name: '2',
        })
      );

      markets.push(
        new MarketModel({
          id: matchWithThreeOdds.odds[0].marketTypeId,
          typeId: matchWithThreeOdds.odds[0].marketTypeId,
          name: '1 X 2',
          description: '',
          selections,
        })
      );
    }
    return new EventSummaryModel({
      sportId: responseData[0].SportId,
      area: new AreaModel({
        id: 1,
        name: 'Main',
        order: 0,
        isDefault: true,
        markets,
      }),
      matches,
    });
  }

  mapSelectionDataToModel(responseData: any): SelectionModel {
    return new SelectionModel({
      id: responseData.OddAttribute.OddTypeID,
      name: responseData.OddAttribute.OddName,
      spreadValue: responseData.OddAttribute.SpecialValue,
      spreadDisplayValue: responseData.OddAttribute.SpecialValueDisplay,
      order: responseData.OddAttribute.Order,
    });
  }

  mapMatchDataToModel(
    responseData: any, // responseData: 1 of AreaMatches.Items
    sportId?: number,
    sportName?: string,
    isItPlayerMultiLineType?: boolean,
    groupingType?: number
  ): MatchModel {
    const odds: OddModel[] = [];
    let correctScoreOdds: CorrectScoreOddsModel;

    responseData.OddsCollection.forEach(oc => {
      odds.push(...this.mapOddDataItemsToModel(responseData, oc, sportId, sportName, groupingType));
    });

    let newMatchModel: MatchModel;

    const teams = responseData.ItemName.split(' - ');

    if (isItPlayerMultiLineType) {
      correctScoreOdds = this.parseOddsForCorrectScore(odds);
      newMatchModel = new MatchModel({
        id: responseData.ItemID,
        date: responseData.ItemDate,
        name: responseData.ItemName,
        homeTeam: teams[0],
        awayTeam: teams.length > 1 ? teams[1] : undefined,
        smartBetCode: responseData.SmartBetCode,
        oddCount: responseData.TotalOdds,
        categoryId: responseData.CategoryId,
        categoryName: responseData.CategoryName,
        tournamentId: responseData.TournamentId,
        tournamentName: responseData.TournamentName,
        externalId: responseData.ExtProvIDItem,
        extParentItemID: responseData.ExtParentItemID,
        extParentTeamID: responseData.ExtParentTeamID,
        extTeamOrder: responseData.ExtTeamOrder,
        odds,
        correctScoreOdds,
        ...(this.sportQuery.selectedIdsAtDepthFour && {
          selectedInView: this.sportQuery.selectedIdsAtDepthFour.includes(responseData.ItemID),
        }),
      });
    } else {
      newMatchModel = new MatchModel({
        id: responseData.ItemID,
        date: responseData.ItemDate,
        name: responseData.ItemName,
        homeTeam: teams[0],
        awayTeam: teams.length > 1 ? teams[1] : undefined,
        smartBetCode: responseData.SmartBetCode,
        oddCount: responseData.TotalOdds,
        categoryId: responseData.CategoryId,
        categoryName: responseData.CategoryName,
        tournamentId: responseData.TournamentId,
        tournamentName: responseData.TournamentName,
        externalId: responseData.ExtProvIDItem,
        extParentItemID: responseData.ExtParentItemID,
        extParentTeamID: responseData.ExtParentTeamID,
        extTeamOrder: responseData.ExtTeamOrder,
        odds,
        ...(this.sportQuery.selectedIdsAtDepthFour && {
          selectedInView: this.sportQuery.selectedIdsAtDepthFour.includes(responseData.ItemID),
        }),
      });
    }

    return newMatchModel;
  }

  mapLiveMatchDataToModel(itemData: any): MatchModel {
    const odds: OddModel[] = [];

    itemData.Markets.forEach(market => {
      odds.push(...this.mapLiveOddDataItemsToModel(itemData, market));
    });

    const teamHome = itemData.Teams.find(t => t.ItemOrder === 1);
    const teamAway = itemData.Teams.find(t => t.ItemOrder === 2);

    const newMatchModel = new MatchModel({
      id: itemData.Id,
      date: itemData.Date,
      name: itemData.Name,
      homeTeam: teamHome.Name,
      awayTeam: teamAway.Name,
      categoryId: itemData.CategoryId,
      categoryName: itemData.CategoryName,
      tournamentId: itemData.TournamentId,
      tournamentName: itemData.TournamentName,
      externalId: itemData.ProviderId,
      matchTime: itemData.MatchTime,
      eventStatus: itemData.EventStatus,
      matchStatus: itemData.MatchStatus,
      score: itemData.Score,
      oddCount: itemData.SelectionCount,
      sportId: itemData.SportId,
      odds,
    });

    return newMatchModel;
  }

  mapOddDataItemsToModel(
    responseDataItem: any, // responseDataItem: 1 of AreaMatches.Items
    responseDataOddsCollection: any, // responseDataOC: 1 of AreaMatches.Items.OddsCollection
    sportId?: number,
    sportName?: string,
    groupingType?: number
  ): OddModel[] {
    const matchOdds: OddModel[] = [];

    responseDataOddsCollection.MatchOdds.forEach(matchOdd => {
      matchOdds.push(
        new OddModel({
          id: matchOdd.MatchOddsID,
          value: matchOdd.Outcome ? matchOdd.Outcome.OddOutcome : undefined,
          // unboostedValue: matchOdd.Outcome ? Math.round(Math.random()) * (Math.random() * 10) : undefined,
          unboostedValue: matchOdd.Outcome ? matchOdd.Outcome.UnboostedOddValue : undefined,
          spreadValue:
            matchOdd.OddAttribute.SpecialValueDisplay === '0'
              ? responseDataOddsCollection.SpecialBetValue
              : matchOdd.OddAttribute.SpecialValueDisplay,
          sportId,
          sportName,
          categoryId: responseDataItem.CategoryId,
          categoryName: responseDataItem.CategoryName,
          tournamentId: responseDataItem.TournamentId,
          tournamentName: responseDataItem.TournamentName,
          matchId: responseDataItem.ItemID,
          matchName: responseDataItem.ItemName,
          matchDate: responseDataItem.ItemDate,
          marketId: responseDataOddsCollection.OddCollectionID,
          marketTypeId: responseDataOddsCollection.OddsType.IDGroupMarketType,
          marketName: responseDataOddsCollection.OddsType.OddsTypeName,
          smartCode: responseDataItem.SmartBetCode,
          eventCategory: responseDataItem.EventCategory,
          combinability: responseDataOddsCollection.Combinability,
          selectionId: matchOdd.OddAttribute.OddTypeID,
          selectionName: matchOdd.OddAttribute.OddName,
          incompatibleEvents: responseDataItem.IncompatibleEvents,
          selected: this.couponService.isOddInCoupon(matchOdd.MatchOddsID),
          enabled: true,
          groupingType,
        })
      );
    });

    return matchOdds;
  }

  mapLiveOddDataItemsToModel(itemData: any, market: any): OddModel[] {
    const matchOdds: OddModel[] = [];

    market.Selections.forEach(selection => {
      matchOdds.push(
        new OddModel({
          id: selection.Id,
          value: selection.Odds ? selection.Odds[0].Value : undefined,
          spreadValue: market.SpecialValue,
          sportId: itemData.SportId,
          sportName: itemData.SportName,
          categoryId: itemData.CategoryId,
          categoryName: itemData.CategoryName,
          tournamentId: itemData.TournamentId,
          tournamentName: itemData.TournamentName,
          matchId: itemData.Id,
          matchName: itemData.Name,
          matchDate: itemData.Date,
          marketId: market.Id,
          marketTypeId: market.TypeId,
          marketName: market.Name,
          selectionId: selection.TypeId,
          selectionName: selection.Name,
          selected: this.couponService.isOddInCoupon(selection.Id),
          enabled: selection.Odds ? Boolean(selection.Odds[0].Status) : false,
          eventCategory: 'L',
        })
      );
    });

    return matchOdds;
  }

  parseOddsForCorrectScore(odds: OddModel[]): CorrectScoreOddsModel {
    const homeToWin = [];
    const awayToWin = [];
    const draw = [];
    const others = [];
    odds.forEach((odd: OddModel) => {
      if (odd.selectionName === 'Others') {
        others.push(odd);
      } else {
        if (odd.selectionName.length < 4) {
          const selectionName = odd.selectionName.split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          }
        } else {
          const selections = odd.selectionName.split('/');
          const selectionName = selections[selections.length - 1].split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          } else if (selectionName[0] === 'Other') {
            others.push(odd);
          }
        }
      }
    });

    const homeLength = homeToWin.length;
    const awayLength = awayToWin.length;
    let difference;
    if (homeLength > awayLength) {
      difference = homeLength - awayLength;
      for (let i = 0; i < difference; i++) {
        awayToWin.push({});
      }
      const differenceForDraw = homeLength - draw.length - 1; // -1 for the 'Other' odd
      for (let i = 0; i < differenceForDraw; i++) {
        draw.push({});
      }
    } else {
      difference = awayLength - homeLength;
      for (let i = 0; i < difference; i++) {
        homeToWin.push({});
      }
      const differenceForDraw = homeLength - draw.length - 1; // -1 for the 'Other' odd
      for (let i = 0; i < differenceForDraw; i++) {
        draw.push({});
      }
    }

    const newCorrectScoreModel = new CorrectScoreOddsModel({
      homeToWin,
      awayToWin,
      draw,
      others,
    });

    return newCorrectScoreModel;
  }

  groupBySpreadValue(match: MatchModel): [OddModel, OddModel][] {
    const key = 'spreadValue';
    const oddsGroupedBySpreadValue = [];
    const flags = [];
    const uniqueSpreadValues = [];

    for (const odd of match.odds) {
      if (flags[odd[key]]) {
        continue;
      }
      flags[odd[key]] = true;
      uniqueSpreadValues.push(odd[key]);
    }

    uniqueSpreadValues.forEach(spreadValue => {
      const spreadGroup = [];
      match.odds.forEach(odd => {
        if (odd[key] === spreadValue && (!this.sportQuery.selectedMarket.id || odd.marketTypeId === this.sportQuery.selectedMarket.id)) {
          spreadGroup.push(odd);
        }
      });
      oddsGroupedBySpreadValue.push(spreadGroup);
    });

    return oddsGroupedBySpreadValue;
  }

  getSportsList(scheduleTimeFrame: number = 4, language: string = 'en'): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    let extendUrl: string = '';

    if (this.appConfig.get('sports').enableCustomMenu) {
      extendUrl = 'custommenu/';
    }

    return forkJoin([
      this.apiService.get<any>(
        APIType.SportsbookFeed,
        `api/feeds/prematch/matches/${extendUrl}${language}/${scheduleTimeFrame}`,
        apiSettings
      ),
      this.apiService.get(APIType.SportsbookFeed, `api/feeds/prematch/outrights/${language}/${scheduleTimeFrame}`),
    ]).pipe(
      map(responseData => {
        if (!responseData) {
          return false;
        }

        const sportsListData: SportModel[] = [];
        const specialSports: SportModel[] = [];
        const goalscorerSport: SportModel[] = [];
        const oddsBoostSport: SportModel[] = [];
        const competitionsAZ: FlattenedSportModel[] = [];
        const allCompetitionByCountry: SportModel[] = [];

        responseData[0].Sports.forEach(sport => {
          if (sport.MenuUniqueID.indexOf('_') === -1) {
            sportsListData.push(this.parsePrematchMatch(sport));

            competitionsAZ.push(this.parsePrematchMatchFlattened(sport));
            allCompetitionByCountry.push(this.parsePrematchMatch(sport));
          } else {
            switch (sport.MenuUniqueID) {
              case '2_0':
                goalscorerSport.push(this.parsePrematchMatch(sport));
                break;
              case '3_0':
                oddsBoostSport.push(this.parsePrematchMatch(sport));
                break;
              default:
                specialSports.push(this.parsePrematchMatch(sport));
                break;
            }
          }
        });

        const outrights = this.parsePrematchMatches(responseData[1]);

        this.sportStore.updateSportsList(sportsListData);

        this.updateEventSelection({ specialSports, goalscorerSport, competitionsAZ, allCompetitionByCountry, outrights, oddsBoostSport });
        this.resolverService.buildCache(
          this.sportQuery.allCompetitionByCountry,
          this.sportQuery.outrights,
          this.sportQuery.specialSports,
          this.sportQuery.goalscorerSport,
          this.sportQuery.oddsBoostSport
        );

        return sportsListData.concat(specialSports);
      })
    );
  }

  parsePrematchMatches(data: any): SportModel[] {
    const retVal: SportModel[] = [];
    data.Sports.forEach(sport => {
      const newSport: SportModel = {
        id: sport.SportID,
        menuUniqueId: sport.MenuUniqueID,
        name: sport.SportName,
        groupingType: sport.GroupingType,
        oddCount: sport.NoOfOdds,
        order: sport.Order,
        categories: [],
      };

      sport.Category.forEach(category => {
        const newCategory = new CategoryModel({
          id: category.ItemID,
          name: category.ItemName,
          oddCount: category.NoOfOdds,
          sportId: sport.SportID,
          menuUniqueId: sport.MenuUniqueID,
          tournaments: [],
        });

        category.Tournaments.forEach(tournament => {
          const newTournament = new TournamentModel({
            eventCount: tournament.ItemEventCount,
            id: tournament.ItemID,
            name: tournament.ItemName,
            oddCount: tournament.NoOfOdds,
            initRegionId: tournament.InitRegionID,
            initAreaId: tournament.InitAreaID,
          });
          newCategory.tournaments.push(newTournament);
        });
        newSport.categories.push(newCategory);
      });

      retVal.push(newSport);
    });

    return retVal;
  }

  parsePrematchMatch(data: any): SportModel {
    const retVal: SportModel = {
      id: data.SportID,
      menuUniqueId: data.MenuUniqueID,
      name: data.SportName,
      groupingType: data.GroupingType,
      oddCount: data.NoOfOdds,
      order: data.Order,
      categories: [],
    };

    data.Category.forEach(category => {
      const newCategory = new CategoryModel({
        id: category.ItemID,
        name: category.ItemName,
        oddCount: category.NoOfOdds,
        sportId: data.SportID,
        menuUniqueId: data.MenuUniqueID,
        tournaments: [],
      });

      category.Tournaments.forEach(tournament => {
        const newTournament = new TournamentModel({
          eventCount: tournament.ItemEventCount,
          id: tournament.ItemID,
          name: tournament.ItemName,
          oddCount: tournament.NoOfOdds,
          initRegionId: tournament.InitRegionID,
          initAreaId: tournament.InitAreaID,
        });
        newCategory.tournaments.push(newTournament);
      });
      retVal.categories.push(newCategory);
    });

    return retVal;
  }

  parsePrematchMatchFlattened(data: any): FlattenedSportModel {
    const retVal: FlattenedSportModel = {
      name: data.SportName,
      id: data.SportID,
      order: data.Order,
      oddCount: data.NoOfOdds,
      tournaments: [],
    };

    data.Category.forEach(category => {
      category.Tournaments.forEach(tournament => {
        const newTournament = new TournamentModel({
          eventCount: tournament.ItemEventCount,
          id: tournament.ItemID,
          name: tournament.ItemName,
          oddCount: tournament.NoOfOdds,
          initRegionId: tournament.InitRegionID,
          initAreaId: tournament.InitAreaID,
        });
        retVal.tournaments.push(newTournament);
      });
    });

    // Sorting tournaments list by oddCount
    const reorderedTournaments = [...retVal.tournaments].sort((a, b) => b.oddCount - a.oddCount);
    retVal.tournaments = reorderedTournaments;
    const newFlattenedSport = new FlattenedSportModel(retVal);

    return newFlattenedSport;
  }

  getSportQuicklinks(sportId: number): Observable<any> {
    return this.apiService.get(APIType.CMS, `Sports/GetSportQuicklinks?sportID=${sportId}`).pipe(
      map(response => {
        const quicklinks: SportQuicklinks = {
          sportId,
          quicklinks: [],
        };

        response.forEach(sportQuicklinks => {
          const quicklink: SportQuicklink = {
            name: sportQuicklinks.quicklinkType === 0 ? presetTypeMap[sportQuicklinks.presetType] : undefined,
            type: sportQuicklinks.quicklinkType === 0 ? QuicklinkType.PresetCollection : QuicklinkType.CustomLink,
            id: sportQuicklinks.presetType,
            linkText: sportQuicklinks.linkText,
          };
          if (sportQuicklinks.quicklinkType === 1) {
            quicklink.linkUrl = sportQuicklinks.linkUrl;
          }
          const newQuicklink = new SportQuicklink(quicklink);
          quicklinks.quicklinks.push(newQuicklink);
        });

        const newQuicklinks = new SportQuicklinks(quicklinks);
        this.sportStore.updateEventSelection({
          eventSelectionQuicklinks: newQuicklinks,
          quickLinksState: QuicklinksState.Success,
        });

        this.preSelectQuicklink(newQuicklinks);
      })
    );
  }

  updateEventSelection(eventSelection: Partial<EventSelectionState>): void {
    this.sportStore.updateEventSelection(eventSelection);
  }

  updateSelectedPrematch(selectedPrematch: EventSummaryModel[]): void {
    this.sportStore.updateSelectedPrematch(selectedPrematch);
  }

  getMostPopularEvents(language: string, sportId: number, numberOfEvents: number, date: Date = new Date()): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    const numberOfTournaments = this.appConfig.get('sports').topCompetitionsLeagueCount;

    return this.apiService
      .get(
        APIType.SportsbookFeed,
        `api/feeds/prematch/mostpopularsports/${language}/${sportId}/${numberOfTournaments}/${numberOfEvents}/${format(
          new Date(date),
          'yyyy-MM-dd'
        )}`,
        apiSettings
      )
      .pipe(
        tap((responseData: any) => {
          if (!responseData || !responseData.length) {
            this.sportStore.updateEventSelection({ topCompetitions: [] });
            return;
          }
          const topCompetitions = this.mapMatchSummaryDataToModel(responseData[0].AreaMatches[0]);

          const tournaments = topCompetitions.matches.reduce(
            (acc, x) => acc.concat(acc.find(y => trim(y.tournamentName) === trim(x.tournamentName)) ? [] : [x]),
            []
          );

          this.sportStore.updateEventSelection({ topCompetitions: tournaments });
        })
      );
  }

  toggleCheckbox(prematchIndex: number, matchIndex: number): void {
    const prematchUpdate: EventSummaryModel = { ...this.sportQuery.selectedPrematch[prematchIndex] };
    const matchUpdate = { ...prematchUpdate.matches[matchIndex] };
    matchUpdate.selectedInView = !matchUpdate.selectedInView;
    const matchesUpdate = [...prematchUpdate.matches];
    matchesUpdate[matchIndex] = new MatchModel(matchUpdate);
    prematchUpdate.matches = matchesUpdate;

    const newPreMatches = [...this.sportQuery.selectedPrematch];
    newPreMatches[prematchIndex] = prematchUpdate;
    this.updateSelectedPrematch(newPreMatches);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.isPlayerAreaSub$.unsubscribe();
  }

  private preSelectQuicklink(newQuicklinks: SportQuicklinks): void {
    const isSpecialSport = this.sportQuery.selectedSport.menuUniqueId
      ? this.sportQuery.selectedSport.menuUniqueId.indexOf('_') > -1
      : false;

    if (isSpecialSport) {
      switch (this.sportQuery.selectedSport.menuUniqueId) {
        case '2_0':
          // Goal Scorer
          this.sportStore.updateEventSelection({
            eventSelectionQuicklinks: newQuicklinks,
            quickLinksState: QuicklinksState.Success,
            selectedQuicklink: newQuicklinks.quicklinks.find(link => link.id === 7),
          });
          break;
        case '3_0':
          // Odds Boost
          this.sportStore.updateEventSelection({
            eventSelectionQuicklinks: newQuicklinks,
            quickLinksState: QuicklinksState.Success,
            selectedQuicklink: newQuicklinks.quicklinks.find(link => link.id === 8),
          });
          break;
        default:
          // Super Specials
          this.sportStore.updateEventSelection({
            eventSelectionQuicklinks: newQuicklinks,
            quickLinksState: QuicklinksState.Success,
            selectedQuicklink: newQuicklinks.quicklinks.find(link => link.id === 6),
          });
          break;
      }
    } else {
      let initiallySelectedQuicklink: SportQuicklink;
      if (this.applicationQuery.activeUrl[2] === 'leagues' && this.applicationQuery.activeUrl[3] === 'outright') {
        initiallySelectedQuicklink = newQuicklinks.quicklinks.find(link => link.id === 5);
      } else if (this.applicationQuery.activeUrl[2] === 'leagues' && this.applicationQuery.activeUrl[3] === 'prematch') {
        initiallySelectedQuicklink = newQuicklinks.quicklinks.find(link => link.id === 4);
      } else {
        newQuicklinks.quicklinks.every(quicklink => {
          if (quicklink.type === QuicklinkType.PresetCollection) {
            initiallySelectedQuicklink = quicklink;
            return false;
          }
          return true;
        });

        // Check if initiallySelectedQuicklink is actually available
        this.sportQuery.eventSelectionQuicklinksIsVisible$
          .pipe(
            filter(
              eventSelectionQuicklinksIsVisible => !!eventSelectionQuicklinksIsVisible && eventSelectionQuicklinksIsVisible.length > 0
            ),
            map(eventSelectionQuicklinksIsVisible => eventSelectionQuicklinksIsVisible.filter(quicklink => quicklink.visible)),
            tap(visibleQuicklinks => {
              if (visibleQuicklinks.findIndex(quicklink => quicklink.id === initiallySelectedQuicklink.id && quicklink.visible) < 0) {
                // Preset quicklink is not available, so set to the first available one instead
                initiallySelectedQuicklink = visibleQuicklinks[0];
              }

              this.sportStore.updateEventSelection({
                selectedQuicklink: initiallySelectedQuicklink,
              });
            })
          )
          .subscribe();
      }
    }
  }
}
