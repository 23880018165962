import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { APIType } from 'src/app/shared/models/api.model';
import { VirtualsLatestJackpotWinnersInfo, VirtualsMenuGame, VirtualsMenuSection } from 'src/app/shared/models/virtuals.model';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsService implements OnDestroy {
  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly virtualsStore: VirtualsStore
  ) {}

  getVirtualsGameFrameUrl(clientType: string = 'web'): Observable<any> {
    return this.apiService.get(APIType.Platform, `/api/GamingVendors/GlobalBet/GameFrameUrl?globalBetClientType=${clientType}`);
  }

  getVirtualsMenuSections(): Observable<void> {
    const language: string = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetVirtualsMenuSections?language=${language}`).pipe(
      map(response => {
        const menuSections = this.parseMenuSections(response);
        this.virtualsStore.updateMenuSections(menuSections);
      })
    );
  }

  getVirtualsLatestJackpotWinnerSection(): Observable<VirtualsLatestJackpotWinnersInfo> {
    const language: string = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetVirtualsLatestJackpotWinnerSection?language=${language}`);
  }

  getGroupEntityId(userId: number): Observable<any> {
    return this.apiService.get(APIType.Platform, `/api/GamingVendors/GoldenRace/GroupEntityID?userID=${userId}`).pipe(
      map(response => {
        if (response && response.Result) {
          this.virtualsStore.updateGroupEntityId(response.Result);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private parseMenuSections(menuSectionData: any): VirtualsMenuSection[] {
    const menuSections: VirtualsMenuSection[] = [];

    if (menuSectionData && menuSectionData.length) {
      menuSectionData.forEach(menuSection => {
        const sectionGames: VirtualsMenuGame[] = [];
        if (menuSection.sectionGames && menuSection.sectionGames.length) {
          menuSection.sectionGames.forEach(sectionGame => {
            sectionGames.push(
              new VirtualsMenuGame({
                code: sectionGame.game,
                title: sectionGame.gameTitle,
                thumbnailUrl: sectionGame.thumbnailUrl,
                isNew: sectionGame.isNew,
              })
            );
          });

          let iconPath = 'images/virtuals-icons/';
          iconPath += menuSection.icon ? menuSection.icon : 'virtuals-default';

          menuSections.push(
            new VirtualsMenuSection({
              name: menuSection.sectionName,
              icon: iconPath,
              games: sectionGames,
            })
          );
        }
      });
    }

    return menuSections;
  }
}
