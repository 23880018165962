import { Injectable } from '@angular/core';
import MD5 from 'crypto-js/md5';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { DataLayerEvent } from 'src/app/shared/models/datalayer.model';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(private readonly appConfig: AppConfigService) {}

  createDataLayerEvent(event: DataLayerEvent): void {
    if (this.appConfig.get('enableDataLayer')) {
      const global = window as any;
      global.dataLayer = global.dataLayer || [];
      global.dataLayer.push(event);
    }
  }

  createOptimoveEvent(event: string, key: string, value: string): void {
    if (this.appConfig.get('enableDataLayer')) {
      const global = window as any;
      global.dataLayer = global.dataLayer || [];
      const object = {
        event: event,
      };
      object[key] = value;
      global.dataLayer.push(object);
    }
  }

  toMD5Hash(data: any): string {
    return MD5(data).toString();
  }

  /**
   * Get the Site UX value to be used in datalayer events
   * as per UP-186
   */
  dataLayerSiteUX(): string {
    const devREG = new RegExp('(.azurewebsites.net)|(localhost)');

    return devREG.exec(window.location.hostname) ? 'DEV' : 'M';
  }
}
