import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { EvaluationQuery } from 'src/app/core/state/evaluation/evaluation.query';
import { EvaluationStore } from 'src/app/core/state/evaluation/evaluation.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { EvaluationStatus } from 'src/app/shared/models/evaluation.model';
import { APIService } from './api.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  refreshInterval = undefined;

  private readonly operatorReasons: any = {
    0: $localize`None`,
    1: $localize`ARB/Exchange`,
    2: $localize`Late bet/Event closed`,
    3: $localize`Unavailable market price`,
    4: $localize`Section already chosen`,
    5: $localize`Sharp/Top price`,
    6: $localize`Odds changing`,
    7: $localize`Loss limit exceeded`,
    8: $localize`Market suspended`,
  };
  private readonly refreshTimer = 2000;

  constructor(
    private readonly apiService: APIService,
    private readonly notificationService: NotificationService,
    private readonly accountQuery: AccountQuery,
    private readonly evaluationStore: EvaluationStore,
    private readonly evaluationQuery: EvaluationQuery,

    private readonly dataLayerService: DataLayerService
  ) {}

  runEvaluation(): Observable<void> {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    const evaluationList = this.evaluationQuery.evaluationList();

    const apiCalls: Observable<any>[] = [];
    evaluationList.forEach(evaluation => {
      if (evaluation.status !== EvaluationStatus.Pending && evaluation.status !== EvaluationStatus.AwaitingReply) {
        return;
      }

      const apiSettings: APISettings = new APISettings({ inBehalfOf: evaluation.coupon.UserId });
      apiCalls.push(
        this.apiService.get<any>(APIType.Sportsbook, `api/coupons/pending/byCode/${evaluation.coupon.CouponCode}`, apiSettings)
      );
    });

    return forkJoin(apiCalls).pipe(
      map(responseData => {
        responseData.forEach((data: any) => {
          if (!data) {
            return;
          }

          // Get the response JSON
          const evalStatusId = data.UpdatedBetCoupon.EvalCouponStatusId;
          let status = EvaluationStatus.Pending;

          if (evalStatusId === 1) {
            // coupon removed from evaluation, operator or client agreed
            status = EvaluationStatus.Accepted;
          } else if (evalStatusId === 3 || evalStatusId === 7) {
            // coupon removed from evaluation, operator or client declined
            status = EvaluationStatus.Declined;
          } else if (evalStatusId === 8 || evalStatusId === 9 || evalStatusId === 10) {
            // operator changed the coupon
            status = EvaluationStatus.AwaitingReply;
          }

          const currentEvaluation = evaluationList.find(
            i => data.UpdatedBetCoupon.CouponCode !== undefined && i.coupon.CouponCode === data.UpdatedBetCoupon.CouponCode
          );

          if (status === EvaluationStatus.Pending) {
            if (!currentEvaluation.coupon.UpdatedBetCoupon) {
              this.evaluationStore.updateUpdatedBetCoupon(currentEvaluation.coupon.CouponCode, data.UpdatedBetCoupon);
            }
            return;
          }

          if (currentEvaluation) {
            if (status === EvaluationStatus.Accepted) {
              if (currentEvaluation.status === EvaluationStatus.AwaitingReply) {
                // previously was in an awaitingReply state
                this.removeFromAwaitingReplyList(currentEvaluation.coupon.CouponCode);
              }
              this.showAcceptedMessage(data.UpdatedBetCoupon);
              this.dataLayerService.createDataLayerEvent({ event: 'btk.betPlaced', betPlaced: data.UpdatedBetCoupon.StakeGross });

              // remove from the evaluation list
              this.evaluationStore.removeFromEvaluation(currentEvaluation.coupon.CouponCode);
            } else if (status === EvaluationStatus.Declined) {
              if (currentEvaluation.status === EvaluationStatus.AwaitingReply) {
                // previously was in an awaitingReply state
                this.removeFromAwaitingReplyList(currentEvaluation.coupon.CouponCode);
              }
              this.showDeclinedMessage(data.UpdatedBetCoupon);

              // remove from the evaluation list
              this.evaluationStore.removeFromEvaluation(currentEvaluation.coupon.CouponCode);
            } else if (status === EvaluationStatus.AwaitingReply) {
              // add coupon to awaiting reply list
              this.addToAwaitingReplyList(data);
            }

            this.evaluationStore.updateCouponStatus(currentEvaluation.coupon.CouponCode, status);
          }
        });

        if (this.accountQuery.isAuthenticated) {
          this.refreshInterval = window.setInterval(() => {
            this.runEvaluation().subscribe();
          }, this.refreshTimer);
        }
      })
    );
  }

  getPendingCoupons(): Observable<void> {
    return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/pending`).pipe(
      map(responseData => {
        if (!responseData || !responseData.length) {
          // loggingService.logError('Unable to get pending evaluation coupons: ' + JSON.stringify(response.error));
          this.evaluationStore.clearEvaluationList();
          return;
        }

        responseData.forEach(coupon => {
          this.evaluationStore.addToEvaluation(coupon);
        });
        return;
      })
    );
  }

  addToEvaluation(coupon: any): void {
    this.evaluationStore.addToEvaluation(coupon);
  }

  parseCoupon(data: any): any {
    const couponData: any = cloneDeep(data.UpdatedBetCoupon);
    couponData.ConfirmedStake = data.ConfirmedStake;

    return couponData;
  }

  addToAwaitingReplyList(data: any): void {
    const parsedCoupon = this.parseCoupon(data);
    this.evaluationStore.updateAwaitingReplyList(parsedCoupon);
  }

  removeFromAwaitingReplyList(couponCode: string): void {
    this.evaluationStore.removeAwaitingReply(couponCode);
  }

  sendEvaluationReply(couponCode: string, accepted: boolean, inBehalfOf: string): Observable<boolean> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: inBehalfOf });
    return this.apiService.put<any>(APIType.Sportsbook, `api/coupons/pending/byCode/accept/${couponCode}/${accepted}`, apiSettings).pipe(
      map(responseData => {
        if (!responseData) {
          // loggingService.logError('Unable to send evaluation reply: ' + JSON.stringify(response.error));
          return false;
        }

        const replyAccepted = responseData.ResponseStatus === 1;
        return replyAccepted;
      })
    );
  }

  showAcceptedMessage(couponData: any): void {
    let couponApproved = $localize`Bet Slip approved by operator: ${couponData.CouponCode}`;
    if (couponData.OfferResponseByUser) {
      couponApproved = $localize`Bet Slip approved by client: ${couponData.CouponCode}`;
    }

    this.notificationService.showSuccessNotification(couponApproved);
  }

  showDeclinedMessage(couponData: any): void {
    let couponRejected = $localize`Bet Slip rejected by operator: ${couponData.CouponCode}`;
    if (couponData.OfferResponseByUser) {
      couponRejected = $localize`Bet Slip rejected by operator: ${couponData.CouponCode}`;
    }

    if (couponData.IDOperatorReason !== 0) {
      const reason = $localize`Reason`;
      const operatorReason = this.getOperatorReason(couponData.IDOperatorReason);

      couponRejected = `${couponRejected} ${reason}: ${operatorReason}`;
    }

    this.notificationService.showErrorNotification(couponRejected);
  }

  evaluationReplyClicked(couponCode: string, accepted: boolean, userId: string): void {
    // register scope level event handler for message buttons
    this.removeFromAwaitingReplyList(couponCode);

    this.sendEvaluationReply(couponCode, accepted, userId).subscribe(replyAccepted => {
      if (accepted && !replyAccepted) {
        // coupon not saved
        this.notificationService.showErrorNotification($localize`The coupon has been rejected automatically.`);
      }
    });
  }

  checkEvaluation(): void {
    this.accountQuery.isAuthenticated$.subscribe(auth => {
      if (auth) {
        this.getPendingCoupons().subscribe(() => {
          this.runEvaluation().subscribe();
        });
      } else {
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
        }
      }
    });
  }

  getOperatorReason(reasonCode: number): string {
    return this.operatorReasons[reasonCode];
  }
}
