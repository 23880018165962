import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeOut } from 'src/app/shared/animations';

@Component({
  selector: 'app-nav-bar-betslip-selections',
  templateUrl: './nav-bar-betslip-selections.component.html',
  styleUrls: ['./nav-bar-betslip-selections.component.scss'],
  animations: [fadeOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarBetslipSelectionsComponent {
  @Input() text: string;
  @Input() oddValue: string;
}
