import { HorizontalConnectionPos, Overlay, OverlayConfig, OverlayRef, VerticalConnectionPos } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import { OverlayDropdownOption } from 'src/app/shared/components/overlay-dropdown/overlay-dropdown-option';

@Component({
  selector: 'app-overlay-dropdown',
  templateUrl: './overlay-dropdown.component.html',
  styleUrls: ['./overlay-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
})
export class OverlayDropdownComponent<T> implements OnInit, OnDestroy {
  @Input() options: OverlayDropdownOption<T>[];
  @Input() selectedItem: OverlayDropdownOption<T>;
  @Input() overBackdropSelector?: string;
  @Input() buttonTemplate?: TemplateRef<any>;
  @Input() showNewTags: T[];
  @Output() readonly selectionChange = new EventEmitter<T>();

  @ViewChild('dropdownOptions') dropdownOptionsTemplate: TemplateRef<any>;
  dropdownRef: OverlayRef;

  originX: HorizontalConnectionPos = 'start';
  originY: VerticalConnectionPos = 'bottom';
  overlayX: HorizontalConnectionPos = 'start';
  overlayY: VerticalConnectionPos = 'top';

  isOpen$: Observable<boolean>;
  buttonContext = {};

  private readonly opened$ = new Subject();
  private readonly disposed$ = new Subject();
  private readonly backdropClass = 'dropdown-overlay';
  private overBackdropListeners = [];

  constructor(
    private readonly el: ElementRef,
    private readonly overlay: Overlay,
    private readonly vcr: ViewContainerRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.isOpen$ = merge(this.opened$.pipe(mapTo(true)), this.disposed$.pipe(mapTo(false)));
    this.buttonContext = { selectedItem: this.selectedItem, isOpen$: this.isOpen$ };
  }

  ngOnDestroy(): void {
    this.disposeDropdown();
  }

  openDropdown(): void {
    if (this.dropdownRef) {
      this.cleanUp();
      return;
    }

    const overlayConfig = this.createOverlayConfig();

    this.dropdownRef = this.overlay.create(overlayConfig);
    this.dropdownRef
      .backdropClick()
      .pipe(takeUntil(this.disposed$))
      .subscribe(_ => {
        this.cleanUp();
      });

    const dropdownOptions = new TemplatePortal(this.dropdownOptionsTemplate, this.vcr);

    this.dropdownRef.attach(dropdownOptions);
    this.opened$.next();
    this.addElementsOverBackdrop();
  }

  optionClicked(value: T): void {
    this.selectionChange.emit(value);
    this.cleanUp();
  }

  // TODO: this is quite error prone
  isOptionSelected(option: OverlayDropdownOption<T>): boolean {
    return this.selectedItem.displayText === option.displayText;
  }

  disposeDropdown(): void {
    if (!this.dropdownRef) {
      return;
    }

    this.removeListeners();
    this.dropdownRef.dispose();
    this.dropdownRef = undefined;
    this.disposed$.next();
  }

  cleanUp(): void {
    this.disposeDropdown();
    this.removeElementsOverBackdrop();
  }

  showIsNewTag(item: T): boolean {
    return this.showNewTags && this.showNewTags.includes(item);
  }

  private createOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.el)
      .withPositions([
        {
          originX: this.originX,
          originY: this.originY,
          overlayX: this.overlayX,
          overlayY: this.overlayY,
        },
      ])
      .withPush(false);

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: this.backdropClass,
      positionStrategy,
    });

    return overlayConfig;
  }

  private addElementsOverBackdrop(): void {
    if (!this.overBackdropSelector) {
      return;
    }

    const elements = document.querySelectorAll(this.overBackdropSelector);
    if (elements) {
      elements.forEach(el => {
        if (el === this.el.nativeElement) {
          return;
        }

        this.renderer.setStyle(el, 'z-index', 1001);
        const disposeFn = () => {
          this.disposeDropdown();
        };
        this.overBackdropListeners.push(this.renderer.listen(el, 'click', disposeFn));
      });
    }
  }

  private removeListeners(): void {
    if (!this.overBackdropSelector) {
      return;
    }

    if (this.overBackdropListeners.length > 0) {
      this.overBackdropListeners.forEach(unsubscribe => unsubscribe());
      this.overBackdropListeners = [];
    }
  }

  private removeElementsOverBackdrop(): void {
    if (!this.overBackdropSelector) {
      return;
    }

    const elements = document.querySelectorAll(this.overBackdropSelector);
    if (elements) {
      elements.forEach(el => {
        this.renderer.removeStyle(el, 'z-index');
      });
    }
  }
}
