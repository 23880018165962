export const environment = {
  production: true,
  appConfigPath: 'assets/configs/app.prod.conf',
  // appConfigCacheBuster is used to force cache busting of the config file. When needed generate a new value from https://randomkeygen.com/
  appConfigCacheBuster: 'yzCR8Xg6Lp',
  // brandMap is used to enable the isBrand() functionality inside application.query.ts. Must be kept up to date with new brands
  brandMap: {
    nigeria: 1901,
    ethiopia: 1902,
    kenya: 1903,
    ghana: 1904,
  },
  // The below default settings will be used if a value is not defined in the brand's config file OR if the config file is not available
  appConfigDefaults: {
    siteVersion: '1.0.0',
    brandId: 1901,
    f2pBrandId: 1999,
    activitySource: 'mobileplus',
    appActivitySource: 'mobileandroid',
    apiBaseUrl: {
      cms: 'https://cmsapi.betking.com/api/mobile',
      guardian: 'https://auth-api-betagy.betagy.services',
      f2pGuardian: '',
      platform: 'https://website-api-betking.betagy.services',
      website: 'https://frontendservices-pldev1.betagy.co',
      sportsbook: 'https://sportsapi.betagy.services',
      sportsbookFeed: 'https://sb-btk-sportapi-cdn-micro-prod.azureedge.net',
      betRadarStatistics: 'https://s5.sir.sportradar.com/betking',
      betRadarResults: 'https://ls.fn.sportradar.com/betking',
      betRadarTeamLogo: 'https://img.sportradar.com/ls',
      betRadarWidgets: 'https://widgets.sir.sportradar.com/8291e456ef20ffd848b311f769db64c9/widgetloader',
      virtuals: 'https://virtualsapi.betagy.services',
      virtualsFeed: 'https://sb-btk-virtualsapi-cdn-micro-prod.azureedge.net',
      virtualsWebSocket: 'wss://api-virtuals-staging.betagy.services/WebSockets/MobileNotifier.ashx',
      jackpotBets: 'https://lottery-api-jpbdev1.betagy.co',
      jackpotBetsCms: 'https://betking-cms-aks-prod.kingmakers.tech',
      superPicksValidationDomain: 'https://frontendservices.f2p.services',
    },
    account: {
      accountNumberValidationRegex: '^[0-9]{10}$',
      depositDelimiters: [500, 1000, 5000, 10000, 50000],
      insufficientBalanceThreshold: 500,
      withdrawalsDelimiters: [5000, 7000, 10000, 50000, 100000],
      enableWithdrawalBankProfile: true,
      bankProfileBlackList: ['master', 'superAgent', 'cashier', 'subAccountPlayer'],
      accountTypes: {
        master: 'J_MASTER',
        superAgent: 'J_SUPER_AG',
        shopOwner: 'J_SHOP_OWN',
        cashier: 'J_CASHIER',
        subAccountPlayer: 'PLYSUB',
      },
      depositRedirect: '',
      profileEditability: 'Off', // Can be 'Off', 'One-Time', 'Always'
      mobilePhoneEditability: false,
      nameSurnameEditability: false,
      loginPrefix: '',
      loginType: 'username', // Can be 'Username', 'UsernameOrMobile', 'Mobile'
      enableWinNotificationSetting: false,
      checkUserDataUponWithdraw: false,
      enableMessagingReplies: false,
      transactionListDefaultRangeInDays: 7,
      enableDepositDefaultRedirection: false,
      enableWithdrawalDefaultRedirection: true,
      mpesaExpressDeposit: {
        pollInterval: 5000,
        pollCountLimit: 6,
      },
      mobileVerificationCampaign: false,
      paymentsType: 'regular', // Can be 'regular' or 'userJourney'
      depositIncrementAmountValues: [10, 20, 50, 100, 1000],
      withdrawalIncrementAmountValues: [10, 20, 50, 100, 1000],
      paystackDeposit: {
        publicKey: '',
        momoPollInterval: 5000,
        momoPollTimeout: 180000,
        cardRepeatDepositPollInterval: 1000,
        cardRepeatDepositPollAttempts: 5,
      },
      paymentChannel: 'card',
      enableAutoWithdrawalMaximumWithdrawalsAllowed: false,
      kycVerificationFormUrl: '',
      enableWithdrawalNudge: false,
    },
    appInsights: {
      enabled: true,
      instrumentationKey: '',
      androidInstrumentationKey: '',
      samplingPercentage: 0,
    },
    desktopUrl: '#',
    otp: {
      enableOTPbySms: false,
      otpCodeLength: 6,
      otpResendTimer: 60,
      otpAllowResendWithoutTimer: false,
      enableMobilePhoneVerification: false,
      allowUnconfirmedUsers: false,
      skipRegistrationOTP: false,
    },
    sports: {
      widgetDefaults: {
        landingPage: [
          {
            type: 'liveBetting',
            name: 'Live Now',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 6000,
            sortOrder: 1,
          },
          {
            type: 'mostPopular',
            name: 'Popular',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 60000,
            sortOrder: 2,
          },
          {
            type: 'upcomingEvents',
            name: 'Upcoming',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 60000,
            sortOrder: 3,
          },
          {
            type: 'oddsBoost',
            name: 'Boosted',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 60000,
            sortOrder: 3,
          },
        ],
      },
      lastMinute: {
        count: 10,
        sportIds: [1, 2, 3, 4, 5, 16],
      },
      live: {
        betRadarWidgetHideIds: [3, 13, 16, 19, 20, 21, 22, 24, 29, 31, 32, 34, 109, 111, 112],
        newAreaIds: [],
        extraScoresWhiteList: [5, 19, 20, 23],
        marketGroupingDetailsDefaults: [
          {
            sportID: 0,
            marketsCount: 10,
          },
        ],
      },
      statistics: {
        type: 2,
        enabled: true,
        maximumRows: 5,
      },
      coupon: {
        fixedStakes: [100, 200, 500, 1000, 2000],
        showStakeReduction: false,
        oddChangesTimer: 60000,
        liveOddChangesTimer: 30000,
        canBookWhenLoggedIn: false,
        defaultCurrency: 'NGN',
        stakeTaxPercentageToShow: '',
        showBookedBetRetailMessage: true,
        sendBookedCouponCode: false,
        winningsInfoBubble: true,
      },
      cashout: {
        blackListedUserTypes: ['J_CASHIER'],
        enabled: true,
        unavailableCodes: [200, 113],
      },
      topCompetitionsLeagueCount: 10,
      allowSameMatchSelections: true,
      allowCombinationBets: true,
      enableCustomMenu: true,
      accumulatorBonus: {
        minOddValue: 1.2,
        showProgressionBar: true,
        whiteList: ['', 'sports', 'live', 'last-minute', 'odds-less-than', 'todays-events', 'search'],
      },
      myBets: {
        couponCount: 20,
        pollLiveDetails: false,
        pollLiveDetailsInterval: 6000,
      },
      bookedBets: {
        enabled: true,
      },
    },
    jackpotBets: {
      enabled: false,
      userWhiteListEnabled: false,
      playSourceHeader: 1,
      myBets: {
        couponCount: 20,
      },
      bookedBets: {
        enabled: false,
      },
    },
    messages: {
      defaultPageAmount: 10,
    },
    registration: {
      defaultCountry: 'NGA',
      disableInternationalNumbers: 'true',
      mobileRegex: '^0?[0-9]{10}$',
      deadlineDate: '',
      successRedirectUrl: '/help/getting-started',
      privacyPolicyUrl: '/help/terms-and-conditions',
      termsAndConditionsUrl: '',
      registrationRedirect: '',
      registrationType: 'normal', // can be 'normal', 'quick', 'split', 'userJourney'
      showDNDNotice: true,
      tutorialButtonHighlight: false,
      promoCodeToggleVisibility: false,
    },
    // The applicationServiceMethod means that the menu item will call a method in the application service
    // If you adding a new applicationServiceMethod calls for an item, you need to declare the given method in the application service
    menuDefaults: {
      landingPage: [
        {
          title: 'Soccer',
          icon: 'sport',
          linkURL: '/sports/soccer',
        },
        {
          title: 'Live',
          icon: 'live',
          linkURL: '/live',
        },
        {
          title: 'Today',
          icon: 'today',
          linkURL: '/sports/todays-events',
        },
        {
          title: 'Virtual League',
          icon: 'league',
          linkURL: '/virtual/league',
        },
        {
          title: 'Virtual Offering',
          icon: 'virtual',
          linkURL: '/virtual',
        },
        {
          title: 'Book a Bet',
          icon: 'book-a-bet',
          linkURL: '/book-bet',
        },
        {
          title: 'Odds Less Than',
          icon: 'odds-less-than',
          linkURL: '/odds-less-than',
        },
        {
          title: 'Search',
          icon: 'search',
          linkURL: '/search',
        },
        {
          title: 'Last Minute',
          icon: 'last-minute',
          linkURL: '/last-minute',
        },
        {
          title: 'Quick Bet',
          icon: 'quick-bet',
          linkURL: '/quick-bet',
        },
        {
          title: 'Coupon Check',
          icon: 'coupon-check',
          linkURL: '/coupon-check',
        },
        {
          title: 'News',
          icon: 'news',
          linkURL: 'http://news.betking.com/',
        },
        {
          title: 'Live Chat',
          icon: 'live-chat',
          functionName: 'loadLiveChat',
        },
        {
          title: 'Help',
          icon: 'help',
          linkURL: '/help',
        },
      ],
      sidebarQuicklinks: [],
    },
    correctScoreAreaIds: [537, 1104, 1103, 567, 584, 632, 959],
    live: {
      pollingTimer: 6000,
      useServerSideCoupon: true,
    },
    selectAllMaxItemCount: 50,
    liveChat: {
      liveChatIntegration: 'sfk',
      sfk: {
        buttonId: '5730C000000002C',
        deploymentId: '5720C000000002H',
        organizationId: '00D0C0000000XV5',
      },
    },
    logging: {
      enabled: true,
      minLogLevel: 'Warning',
      endpoints: ['AppInsights'],
    },
    cmsCacheTTL: 1800,
    cookieLastClick: true,
    cookieFirstClick: false,
    affiliateCookieExpiry: 30,
    fallbackTimer: 10,
    enableDataLayer: true,
    enableSlideUps: false,
    enableQuickCouponForPrematch: false,
    enableQuickCouponForLive: true,
    maximumNumberOfToasts: 5,
    canonicalUrl: '',
    nativeApp: {
      appUrl: '',
      appDownloadUrl: '',
      daysOfMaxSessionLength: 7,
    },
    languages: [
      {
        language: 'en',
        languageDescription: 'English',
        locale: 'en-us',
      },
    ],
    social: {
      facebook: 'https://www.facebook.com/Betking-979151908905922',
      twitter: 'https://twitter.com/BetKingNG',
      instagram: 'https://www.instagram.com/betkingng',
      youtube: 'https://www.youtube.com/channel/UCH9QkG2k4oh_kU39IUXjomg',
    },
    registrationRedirect: '',
    footer: {
      showPaymentMethodImage: true,
      leagueOneSponsor: undefined,
      leagueTwoSponsor: undefined,
      showAwards: false,
    },
    virtuals: {
      enabled: true,
      fixedStakes: [20, 100, 200, 500, 1000],
      winningsInfoBubble: true,
      accumulatorBonus: {
        minOddValue: 1.2,
        showProgressionBar: true,
        whiteList: ['', 'sports', 'live', 'last-minute', 'odds-less-than', 'todays-events', 'search'],
      },
      config: {
        assetsUrl: '/assets/mvs/',
        mountingPoint: '/virtual/league',
        defaultLeagueId: 2,
        gameTime: 90,
        playoutTimeMs: 30000,
        countDownHeartBeatMs: 5000,
        minutesBetweenWeeks: 3,
        inPlayResultsDurationMs: 3000,
        recentBetsStorageKey: 'mvsRecentBets',
        disabledLeaguesForSubAccountPlayers: ['kings-bundliga', 13130],
        categories: {
          '2': {
            url: 'kings-league',
          },
          '3': {
            url: 'kings-liga',
            theme: {
              themeColor: '#ffbd00',
              textColor: '#000',
              counterBgColor: '#000',
              counterTextColor: '#fff',
            },
          },
          '4': {
            url: 'kings-italiano',
            theme: {
              themeColor: '#0266ae',
            },
          },
          '13110': {
            url: 'kings-italiano',
            theme: {
              themeColor: '#0266ae',
            },
          },
          '14102': {
            url: 'kings-euro',
            theme: 'champions',
          },
        },
      },
      globalBet: {
        enabled: false,
        enabledGames: [],
      },
      goldenRace: {
        enabled: false,
        enabledGames: [],
        disabledGamesForSubAccountPlayers: ['keno', 'spin-2-win', 'dog-racing'],
        launcherUrl: '',
        phk: '',
        iframeDisplayTimeout: 2000,
      },
      showLoginDialog: false,
      showBetslipLoginDialog: true,
      jackpot: {
        jackpotWonTextDuration: 3500,
        jackpotDropDuration: 2500,
        jackpotDropValueTransition: 1000,
        jackpotIncrementValueTransition: 2000,
        jackpotApiCallInterval: 60000,
        updatesPerSec: 0.01,
        jackpotsRotationInterval: 180000,
        enableJackpotCategories: [],
        dukeJackpotId: 5,
        kingmakerJackpotId: 6,
        jackpotShortName: 'Jackpot',
        jackpotLongName: 'Jackpot',
        jackpotPluralName: 'Jackpots',
      },
      enableWebSocketConnection: false,
      showExcludingTaxLabels: false,
      cashout: {
        enabled: false,
      },
      myBets: {
        couponCount: 20,
        pollLiveDetails: false,
        pollLiveDetailsInterval: 6000,
      },
      bookedBets: {
        enabled: false,
      },
    },
    geoAccessControl: {
      accessBlockingEnabled: false,
      tldRedirectionEnabled: false,
      siteCountryCode: '',
    },
    geolocationRedirect: {}, // TODO: Phase this out in favour of the newer 'geoAccessControl'
    accessDownloadPageEnabled: false,
  },
};
