import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SportQuery } from 'src/app/core/state/sport/sport.query';

@Component({
  selector: 'app-proceed-bars-container',
  templateUrl: './proceed-bars-container.component.html',
  styleUrls: ['./proceed-bars-container.component.scss'],
})
export class ProceedBarsContainerComponent implements OnInit, OnDestroy {
  readonly showSpecialsDepthOneProceedBar$ = new BehaviorSubject(false);
  readonly showDepthTwoProceedBar$ = new BehaviorSubject(false);

  private readonly destroy$ = new Subject<boolean>();

  constructor(readonly sportQuery: SportQuery) {}

  ngOnInit(): void {
    combineLatest([
      this.sportQuery.selectedQuicklink$,
      this.sportQuery.specialSportsSelectedElements$,
      this.sportQuery.goalscorerSportSelectedElements$,
      this.sportQuery.oddsBoostSportSelectedElements$,
    ])
      .pipe(
        tap(([selectedQuicklink, specialSportsSelectedElements, goalscorerSportSelectedElements, oddsBoostSportSelectedElements]) => {
          this.showSpecialsDepthOneProceedBar$.next(
            // Super Specials
            (!!selectedQuicklink &&
              selectedQuicklink.id === 6 &&
              !!specialSportsSelectedElements &&
              specialSportsSelectedElements.length > 0) ||
              // Goal Scorer
              (!!selectedQuicklink &&
                selectedQuicklink.id === 7 &&
                !!goalscorerSportSelectedElements &&
                goalscorerSportSelectedElements.length > 0) ||
              // Odds Boost
              (!!selectedQuicklink &&
                selectedQuicklink.id === 8 &&
                !!oddsBoostSportSelectedElements &&
                oddsBoostSportSelectedElements.length > 0)
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    combineLatest([
      this.sportQuery.allCompetitionByCountrySelectedElementsDepth2$,
      this.sportQuery.outrightsSelectedElementsDepth2$,
      this.sportQuery.specialSportsSelectedElementsDepth2$,
      this.sportQuery.goalscorerSportSelectedElementsDepth2$,
      this.sportQuery.oddsBoostSelectedElementsDepth2$,
    ])
      .pipe(
        tap(
          ([
            allCompetitionByCountrySelectedElementsDepth2,
            outrightsSelectedElementsDepth2,
            specialSportsSelectedElementsDepth2,
            goalscorerSportSelectedElementsDepth2,
            oddsBoostSelectedElementsDepth2,
          ]) => {
            this.showDepthTwoProceedBar$.next(
              (!!allCompetitionByCountrySelectedElementsDepth2 && allCompetitionByCountrySelectedElementsDepth2.length > 0) ||
                (!!outrightsSelectedElementsDepth2 && outrightsSelectedElementsDepth2.length > 0) ||
                (!!specialSportsSelectedElementsDepth2 && specialSportsSelectedElementsDepth2.length > 0) ||
                (!!goalscorerSportSelectedElementsDepth2 && goalscorerSportSelectedElementsDepth2.length > 0) ||
                (!!oddsBoostSelectedElementsDepth2 && oddsBoostSelectedElementsDepth2.length > 0)
            );
          }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
