export class VirtualsMenuGame {
  code: string;
  title: string;
  thumbnailUrl: string;
  isNew: boolean;

  constructor(init: VirtualsMenuGame) {
    Object.assign(this, init);
  }
}

export class VirtualsMenuSection {
  name: string;
  icon: string;
  games: VirtualsMenuGame[] = [];

  constructor(init: Partial<VirtualsMenuSection>) {
    Object.assign(this, init);
  }
}

export class VirtualsGoldenRaceGame {
  id: number;
  code: string;
  title: string;

  constructor(init: VirtualsGoldenRaceGame) {
    Object.assign(this, init);
  }
}

export class VirtualsLatestJackpotWinnersInfo {
  infoTitle: string;
  infoDescription: string;
  currentJackpotsSectionTitle: string;

  constructor(init: VirtualsLatestJackpotWinnersInfo) {
    Object.assign(this, init);
  }
}

export interface VirtualsState {
  menuSections: VirtualsMenuSection[];
  groupEntityId: number;
}
