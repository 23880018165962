import { Injectable, OnDestroy } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { format, subDays } from 'date-fns';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { BonusEligibilityModel, BonusInfoModel, BonusModel } from 'src/app/modules/accounts/modules/auth/models/bonus.model';
import {
  AccountMenuLinkModel,
  AccountState,
  AccountStatementFilterModel,
  AccountStatementModel,
  AccountStatementTransferTypeModel,
  AccountUIState,
  BetSearchState,
  ChangePasswordPageContent,
  LoginPageContent,
  TransferCandidateModel,
  UserModel,
  UserType,
  VerifyAccountModel,
} from 'src/app/shared/models/account.model';
import { UserProfilesModel } from 'src/app/shared/models/bank-profile.model';

const DEFAULT_MENUITEMS: AccountMenuLinkModel[] = [
  new AccountMenuLinkModel({
    text: 'Deposit',
    iconFontValue: 'plus-circle',
    link: '/account/deposit',
  }),
  new AccountMenuLinkModel({
    text: 'Bet List',
    iconFontValue: 'search',
    link: '/account/bet-search',
  }),
  new AccountMenuLinkModel({
    text: 'Virtual Bet List',
    iconFontValue: 'v-search',
    link: '/account/virtuals-bet-search',
    isCustomIcon: true,
  }),
  new AccountMenuLinkModel({
    text: 'Withdraw',
    iconFontValue: 'minus-circle',
    link: '/account/withdrawal',
  }),
  new AccountMenuLinkModel({
    text: 'Fund Transfer',
    iconFontValue: 'exchange',
    link: '/account/transfer',
    visibleToTheseUserTypes: [UserType.Master, UserType.ShopOwner, UserType.SuperAgent],
  }),
  new AccountMenuLinkModel({
    text: 'Transaction List',
    iconFontValue: 'id-card-o',
    link: '/account/account-statement',
  }),
  new AccountMenuLinkModel({
    text: 'Bonuses',
    iconFontValue: 'bolt',
    link: '/account/bonus',
  }),
  new AccountMenuLinkModel({
    text: 'Edit Profile',
    iconFontValue: 'pencil-square-o',
    link: '/account/edit-profile',
  }),
  new AccountMenuLinkModel({
    text: 'Bank Profile',
    iconFontValue: 'credit-card',
    link: '/account/bank-profile',
    textIcon: true,
  }),
  new AccountMenuLinkModel({
    text: 'Change Password',
    iconFontValue: 'key',
    link: '/account/password-reset',
  }),
  new AccountMenuLinkModel({
    text: 'Messages',
    iconFontValue: 'envelope-o',
    link: '/message-center',
  }),
];

const DEFAULT_HELPMENUITEMS: AccountMenuLinkModel[] = [
  new AccountMenuLinkModel({
    text: 'Contact Us',
    iconFontValue: 'comments-o',
    link: '/help/contact-us',
  }),
  new AccountMenuLinkModel({
    text: 'Terms & Conditions',
    iconFontValue: 'quote-left',
    link: '/help/terms-and-conditions',
  }),
  new AccountMenuLinkModel({
    text: 'Help & FAQs',
    iconFontValue: 'question',
    link: '/help',
  }),
];

function createInitialState(): AccountState {
  return {
    userData: undefined,
    f2pUserData: undefined,
    winNotificationSetting: undefined,
    verifyAccountState: undefined,
    showUnverifiedTooltip: true,
    multipleUnconfirmedUsers: undefined,
    resetPasswordOption: 'none',
    resetOptionTriggered: false,
    changePhoneNumberOption: 'none',
    phoneOptionTriggered: false,
    qtyAllowedProfiles: undefined,
    qtyEnabledProfiles: undefined,
    sbadIndicatorState: undefined,
    menuItems: DEFAULT_MENUITEMS,
    betSearch: {
      queryParams: undefined,
      isLoading: false,
      betSearchResult: undefined,
    },
    helpMenuItems: DEFAULT_HELPMENUITEMS,
    bonuses: undefined,
    bonusInfo: undefined,
    bonusEligibility: undefined,
    viewingBonus: undefined,
    ui: {
      noStatements: false,
      refreshingBalance: false,
      bonus: {
        isViewingActive: false,
        isViewingPaused: false,
        isViewingInactive: false,
        isViewingPrevious: false,
        isViewingMissed: false,
      },
    },
    accountStatementFilter: undefined,
    transferTypes: [],
    accountStatements: [],
    transferCandidates: [],
    userBankProfile: [],
    generalBanksList: [],
    isAllowedBankAccInfo: true,
    loginPageContent: undefined,
    changePasswordPageContent: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'account' })
export class AccountStore extends Store<AccountState> implements OnDestroy {
  destroy$ = new Subject<boolean>();
  defaultAccountStatementFilter: AccountStatementFilterModel = {
    transferType: '0',
    dateFrom: format(subDays(new Date(), this.appConfigService.get('account').transactionListDefaultRangeInDays), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
    period: '0',
  };

  private readonly USER_DATA_KEY = 'userData';
  private readonly F2P_USER_DATA_KEY = 'f2pUserData';
  private readonly VERIFY_ACCOUNT_DATA_KEY = 'verifyAccountData';
  private readonly USER_BANK_PROFILE_STATE_KEY = 'userBankProfileStateKey';
  private readonly loadingQueue: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private readonly localStorage: LocalStorageService, private readonly appConfigService: AppConfigService) {
    super(createInitialState());

    this.updateAccountStatementFilter(this.defaultAccountStatementFilter);
    this.updateUserData(this.localStorage.retrieve(this.USER_DATA_KEY));
    this.observerUserData();

    this.loadingQueue
      .pipe(
        map(m => {
          this.setLoading(m > 0);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  observerUserData(): void {
    this.localStorage
      .observe(this.USER_DATA_KEY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.updateUserData(value);
      });
  }

  updateGeneralBanksList(generalBanksList: any): void {
    this.update({ generalBanksList });
  }

  updateWinNotificationSetting(winNotificationSetting: boolean): void {
    this.update({ winNotificationSetting });
  }

  updateAccountMenuItems(menuItems: AccountMenuLinkModel[]): void {
    this.update({ menuItems });
  }

  updateAccounteHelpMenuItems(helpMenuItems: AccountMenuLinkModel[]): void {
    this.update({ helpMenuItems });
  }

  updateResetPasswordOption(resetPasswordOption: string): void {
    this.update({ resetPasswordOption });
  }

  updateResetOptionTriggered(resetOptionTriggered: boolean): void {
    this.update({ resetOptionTriggered });
  }

  updateChangePhoneNumberOption(changePhoneNumberOption: string): void {
    this.update({ changePhoneNumberOption });
  }

  updatePhoneOptionTriggered(phoneOptionTriggered: boolean): void {
    this.update({ phoneOptionTriggered });
  }

  updateStoredWallets(userData: UserModel, wallets: any): void {
    this.localStorage.store(this.USER_DATA_KEY, { ...userData, wallets });
  }

  updateAmountOfProfilesAllowed(qtyAllowedProfiles: number): void {
    this.update({ qtyAllowedProfiles });
  }

  updateAmountOfProfilesEnabled(qtyEnabledProfiles: number): void {
    this.update({ qtyEnabledProfiles });
  }

  updateSbadIndicatorState(sbadIndicatorState: boolean): void {
    this.update({ sbadIndicatorState });
  }

  updateBonuses(bonuses: BonusModel[]): void {
    this.update({ bonuses });
  }

  updateVerifyAccountState(verifyAccountState: VerifyAccountModel): void {
    this.update({ verifyAccountState });

    this.localStorage.store(this.VERIFY_ACCOUNT_DATA_KEY, verifyAccountState);
  }

  updateShowUnverifiedTooltip(showUnverifiedTooltip: boolean): void {
    this.update({ showUnverifiedTooltip });
  }

  updateMultipleUnconfirmedUsers(multipleUnconfirmedUsers: boolean): void {
    this.update({ multipleUnconfirmedUsers });
  }

  updateUserData(userData: UserModel): void {
    if (!userData) {
      this.clearUserData();
      return;
    }

    this.update({ userData });
    this.localStorage.store(this.USER_DATA_KEY, userData);
  }

  updateF2PUserData(f2pUserData: UserModel): void {
    if (!f2pUserData) {
      this.clearF2PUserData();
      return;
    }

    this.update({ f2pUserData });
  }

  updatePhoneVerifyType(verifyType: string): void {
    if (this._value().userData) {
      this.update(state => ({
        userData: {
          ...state.userData,
          mobile: {
            ...state.userData.mobile,
            verifyType,
          },
        },
      }));
      this.localStorage.store(this.USER_DATA_KEY, this._value().userData);
    }
  }

  updateUserStatus(userStatus: string): void {
    if (this._value().userData) {
      this.update(state => ({
        userData: {
          ...state.userData,
          userStatus,
        },
      }));
      this.localStorage.store(this.USER_DATA_KEY, this._value().userData);
    }
  }

  updateBonusInfo(bonusInfo: BonusInfoModel[]): void {
    this.update({ bonusInfo });
  }

  updateBonusEligibility(bonusEligibility: BonusEligibilityModel): void {
    this.update({ bonusEligibility });
  }

  updateUI(ui: Partial<AccountState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateBonusUI(bonusUI: Partial<AccountUIState['bonus']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        bonus: {
          ...state.ui.bonus,
          ...bonusUI,
        },
      },
    }));
  }

  updateUIPartial(ui: Partial<AccountUIState>): void {
    this.update(state => ({ ui: { ...state.ui, ...ui } }));
  }

  updateAccountStatementFilter(filter: AccountStatementFilterModel): void {
    this.update({ accountStatementFilter: filter });
  }

  clearUserData(): void {
    this.update({ userData: undefined });
    this.localStorage.clear(this.USER_DATA_KEY);
  }

  clearF2PUserData(): void {
    this.update({ f2pUserData: undefined });
    this.localStorage.clear(this.F2P_USER_DATA_KEY);
  }

  clearSaveBankAccInfo(): void {
    this.update({
      isAllowedBankAccInfo: true,
      sbadIndicatorState: undefined,
    });
  }

  updateBetSearch(betSearch: Partial<BetSearchState>): void {
    this.update(state => ({ ...state, betSearch: { ...state.betSearch, ...betSearch } }));
  }

  updateTransferTypes(transferTypes: AccountStatementTransferTypeModel[]): void {
    this.update({ transferTypes });
  }

  updateAccountStatements(accountStatements: AccountStatementModel[]): void {
    this.update({ accountStatements });
    this.destroy$.complete();
  }

  updateViewingBonus(viewingBonus: BonusModel): void {
    this.update({ viewingBonus });
  }

  updateTransferCandidates(transferCandidates: TransferCandidateModel[]): void {
    this.update({
      transferCandidates: transferCandidates.sort((tc1, tc2) => (tc1.name + tc1.surname).localeCompare(tc2.name + tc2.surname)),
    });
  }

  updateBankProfilesAndState(userBankProfile: UserProfilesModel[], userBankProfileState: any): void {
    this.update({ userBankProfile });
    this.localStorage.store(this.USER_BANK_PROFILE_STATE_KEY, userBankProfileState);
    this.destroy$.complete();
  }

  updateIsAllowedBankAccInfo(isAllowedBankAccInfo: boolean): void {
    this.update({ isAllowedBankAccInfo });
  }

  updateLoginPageContent(loginPageContent: LoginPageContent): void {
    this.update({ loginPageContent });
  }

  updateChangePasswordPageContent(changePasswordPageContent: ChangePasswordPageContent): void {
    this.update({ changePasswordPageContent });
  }

  queueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value + 1);
  }

  dequeueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value - 1);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
