import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { BetCouponGroup, CouponType } from 'clientside-coupon';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponEditService } from 'src/app/core/services/coupon/coupon-edit.service';
import { CouponSelectionService } from 'src/app/core/services/coupon/coupon-selections.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { fadeIn } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { BookedCouponModel, CouponGroupingType, CouponOddsModel, GroupingTabsVisibleModel } from 'src/app/shared/models/coupon.model';
import { brandInfo } from 'src/brand-info';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponComponent implements OnInit, OnDestroy {
  @ViewChild('betInProgress') betInProgress: TemplateRef<any>;

  readonly destroy$: Subject<boolean> = new Subject<boolean>();
  readonly canPlaceBet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly canPostCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly canBookCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly hasOddChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly showLoadingOverlay$ = new BehaviorSubject<boolean>(false);
  readonly viewInitialized$ = new BehaviorSubject(false);

  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  groupingType: typeof CouponGroupingType = CouponGroupingType;
  currentGroupingsTabSelected: CouponGroupingType;
  bookedCouponData: BookedCouponModel = undefined;
  showStakeReduction: boolean = false;
  canBookWhenLoggedIn: boolean = false;
  loadingTimeout: any;
  brandInfo = brandInfo;
  allowCombinationBets = this.appConfigService.get('sports').allowCombinationBets;
  showBookedBetRetailMessage = this.appConfigService.get('sports').coupon.showBookedBetRetailMessage;

  groupingTabsVisible: GroupingTabsVisibleModel = {
    multiple: false,
    split: false,
    singles: false,
    combination: false,
  };
  actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '14px',
    height: '44px',
    borderRadius: '50px',
  };

  oddChangesInterval = undefined;
  enableSlideUps = this.appConfigService.get('enableSlideUps');

  private oddChangesTimer;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationService: ApplicationService,
    readonly couponQuery: CouponQuery,
    readonly appConfigService: AppConfigService,
    private readonly couponService: CouponService,
    private readonly couponStore: CouponStore,
    private readonly notificationService: NotificationService,
    private readonly couponEditService: CouponEditService,
    private readonly couponSelectionService: CouponSelectionService,
    private readonly router: Router,
    private readonly location: Location
  ) {
    this.showStakeReduction = this.appConfigService.get('sports').coupon.showStakeReduction;
    this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
    this.canBookWhenLoggedIn = this.appConfigService.get('sports').coupon.canBookWhenLoggedIn;

    this.accountQuery.isAuthenticated$.pipe(takeUntil(this.destroy$)).subscribe((response: boolean) => {
      this.canPlaceBet$.next(response);
    });
  }

  ngOnInit(): void {
    this.scrollToBottomAfterInitialization();
    // Coupon Init
    this.bookedCouponData = undefined;

    if (this.couponQuery.expiredEvents && this.couponQuery.expiredEvents.expiredEvents.length > 0) {
      this.couponService.showExpiredNotification();

      this.couponService.clearExpiredEvents();
    }

    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(couponData => {
      if (couponData) {
        setTimeout(() => {
          // This had to be done so that the parsing is done in a separate digest cycle
          // if the coupon is edited within the betslip screen
          this.couponSelectionService.parseSelections(couponData.Odds);
        });

        if (couponData.CouponType === CouponType.Single || couponData.AllGroupings === null) {
          return;
        }

        if (
          this.couponQuery.groupingsTabSelected === undefined ||
          this.currentGroupingsTabSelected !== this.couponQuery.groupingsTabSelected
        ) {
          // determine which combination tab to show
          const lastGrouping = couponData.AllGroupings[couponData.AllGroupings.length - 1];
          const singlesGrouping = couponData.AllGroupings.find(g => g.Grouping === 1);

          if (couponData.Groupings.length === 1 && lastGrouping.Selected) {
            if (lastGrouping.Combinations === 1) {
              this.setGroupingTab(CouponGroupingType.Multiple);
            } else if (lastGrouping === singlesGrouping) {
              this.setGroupingTab(CouponGroupingType.Singles);
            } else {
              this.setGroupingTab(CouponGroupingType.Split);
            }
          } else if (couponData.Groupings.length === 1 && singlesGrouping && singlesGrouping.Selected) {
            this.setGroupingTab(CouponGroupingType.Singles);
          } else {
            this.setGroupingTab(CouponGroupingType.Combination);
          }
        }

        // check group tabs visability
        this.groupingTabsVisible.multiple = this.isGroupingButtonVisible(CouponGroupingType.Multiple);
        this.groupingTabsVisible.split = this.isGroupingButtonVisible(CouponGroupingType.Split);
        this.groupingTabsVisible.singles = this.isGroupingButtonVisible(CouponGroupingType.Singles);
        this.groupingTabsVisible.combination = this.isGroupingButtonVisible(CouponGroupingType.Combination);
      }
    });

    this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());

    this.currentGroupingsTabSelected = this.couponQuery.groupingsTabSelected;

    this.couponQuery.groupingsTabSelected$.pipe(takeUntil(this.destroy$)).subscribe(groupingType => {
      if (groupingType !== undefined && groupingType !== this.currentGroupingsTabSelected) {
        this.currentGroupingsTabSelected = groupingType;
        this.updateGroupingsTab(groupingType);
      }
    });

    this.couponQuery.oddChanges$.subscribe(oddChanges => {
      if (oddChanges) {
        this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());
      }
    });

    if (this.couponQuery.oddChanges !== undefined && this.couponQuery.oddChanges.length > 0) {
      this.runCouponOddChanges();
    } else {
      this.stopCouponOddChanges();
    }

    let prevCouponSelectionIds = 0;
    this.couponQuery.couponSelectionIds$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (prevCouponSelectionIds !== state.length) {
        prevCouponSelectionIds = state.length;

        if (
          this.isGroupingButtonVisible(CouponGroupingType.Multiple) &&
          this.couponQuery.groupingsTabSelected === CouponGroupingType.Split
        ) {
          this.couponStore.updateGroupingTab(CouponGroupingType.Multiple);
        } else if (
          this.isGroupingButtonVisible(CouponGroupingType.Split) &&
          this.couponQuery.groupingsTabSelected === CouponGroupingType.Multiple
        ) {
          this.couponStore.updateGroupingTab(CouponGroupingType.Split);
        }
      }
    });
    setTimeout(() => {
      this.viewInitialized$.next(true);
    });
  }

  scrollToBottomAfterInitialization(): void {
    const initializationSub = this.viewInitialized$.subscribe(initialized => {
      if (initialized) {
        const { betslipScrollTop } = this.couponQuery;
        const scrollHeight = betslipScrollTop ? betslipScrollTop : window.document.body.scrollHeight;
        window.scrollTo(0, scrollHeight);
        initializationSub.unsubscribe();
      }
    });
  }

  continueBetting(): void {
    if (this.enableSlideUps) {
      this.applicationService.closeAnySlideUps();
    }

    this.router.navigate(['/']);
  }

  close(): void {
    if (this.enableSlideUps) {
      this.applicationService.closeAnySlideUps();
    }
  }

  goBack(): void {
    this.location.back();
  }

  runCouponOddChanges(): void {
    this.stopCouponOddChanges();

    const couponOdds = this.getCouponOdds();

    if (couponOdds !== undefined) {
      const containsLiveEvents = couponOdds.some(odd => odd.isLive);

      if (containsLiveEvents) {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.liveOddChangesTimer;
      } else {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
      }

      this.couponService.getOddsChanged(couponOdds).subscribe();
    }

    this.oddChangesInterval = window.setInterval(() => {
      this.runCouponOddChanges();
    }, this.oddChangesTimer);
  }

  stopCouponOddChanges(): void {
    if (this.oddChangesInterval) {
      clearInterval(this.oddChangesInterval);
    }
  }

  acceptOddChanges(): void {
    this.couponService.acceptOddChanges();
  }

  getCouponOdds(): CouponOddsModel[] {
    if (this.couponQuery.couponData === undefined) {
      return undefined;
    }

    const couponOdds: CouponOddsModel[] = [];
    this.couponQuery.couponData.Odds.forEach(odd => {
      couponOdds.push({
        isLive: odd.EventCategory.toLowerCase() === 'l',
        matchId: odd.MatchId,
        marketId: odd.MarketId,
        selectionId: odd.SelectionId,
        selectionValue: odd.OddValue,
      });
    });

    return couponOdds;
  }

  updateGroupingsTab(groupingType: CouponGroupingType): void {
    const groupUpdates: BetCouponGroup[] = [];

    this.couponQuery.couponData.AllGroupings.forEach(group => {
      if (!group.Selected) {
        return;
      }

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = false;

      groupUpdates.push(newGrouping);
    });

    if (groupingType === CouponGroupingType.Multiple || groupingType === CouponGroupingType.Split) {
      const lastGroup = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 1];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = lastGroup.Grouping;
      newGrouping.Combinations = lastGroup.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Singles) {
      const singlesGrouping = this.couponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = singlesGrouping.Grouping;
      newGrouping.Combinations = singlesGrouping.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Combination) {
      const group = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 2];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    }

    if (groupUpdates.length > 0) {
      this.updateGroupings(groupUpdates);
    }
  }

  updateGroupings(groupings: BetCouponGroup[]): void {
    this.couponService.updateGroupings(groupings);
  }

  isGroupingButtonVisible(groupingButtonType: CouponGroupingType): boolean {
    if (this.couponQuery.couponData) {
      const lastGrouping = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 1];
      const singlesGrouping = this.couponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      if (groupingButtonType === CouponGroupingType.Multiple) {
        if (lastGrouping.Combinations === 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Split) {
        if (lastGrouping !== singlesGrouping && lastGrouping.Combinations > 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Singles) {
        if (singlesGrouping !== null) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Combination) {
        if (this.couponQuery.couponData.AllGroupings.filter(g => g.Grouping !== 1).length > 1) {
          return true;
        }
      }
    }
    return false;
  }

  groupingTabClicked(groupingType: CouponGroupingType): void {
    if (groupingType !== this.couponQuery.groupingsTabSelected) {
      if (groupingType === CouponGroupingType.Combination) {
        this.couponService.enforceSingleCombination = true;
      }
      this.couponService.clearAllBankers();
      this.setGroupingTab(groupingType);
    }
  }

  setGroupingTab(groupingType: CouponGroupingType): void {
    if (groupingType !== this.couponQuery.groupingsTabSelected) {
      this.couponStore.updateGroupingTab(groupingType);
    }
  }

  updateCouponSetting(key: string, value: any): void {
    this.couponService.updateCouponSetting(key, value);
  }

  clearCouponData(): void {
    this.couponService.clearCouponData();
  }

  postCoupon(): void {
    if (!this.couponService.checkCouponStake()) {
      return;
    }

    if (!this.canPostCoupon$) {
      return;
    }

    if (this.accountQuery.isAuthenticated) {
      this.canPostCoupon$.next(false);

      this.loadingTimeout = setTimeout(() => {
        this.showLoading();
      }, 1000);

      this.couponService
        .validateAndPostCoupon()
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          success => {
            this.canPostCoupon$.next(true);
            this.disposeLoading();
            if (success) {
              this.router.navigate(['coupon/bet-receipt']);
            }
          },
          error => {
            this.canPostCoupon$.next(true);
            this.disposeLoading();
            this.notificationService.showErrorNotification(
              $localize`An error has occurred. Please try again.`,
              $localize`Coupon Not Posted`
            );
          }
        );
    } else {
      this.router.navigate(['/account/login'], { queryParams: { urlAfterLogin: window.location.pathname } });
    }
  }

  bookCoupon(): void {
    if (!this.canBookCoupon$) {
      return;
    }

    this.canBookCoupon$.next(false);
    this.couponService
      .validateAndPostBookCoupon()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          this.canBookCoupon$.next(true);
          if (response) {
            this.bookedCouponData = new BookedCouponModel({
              couponData: cloneDeep(this.couponQuery.couponData),
              bookedCouponCode: response.couponCode,
              bookedCouponDate: response.date,
            });

            this.couponSelectionService.parseSelections(this.bookedCouponData.couponData.Odds);
          }
        },
        error => {
          this.canBookCoupon$.next(true);
          this.notificationService.showErrorNotification($localize`An error has occurred. Please try again.`, $localize`Coupon Not Booked`);
        }
      );
  }

  ngOnDestroy(): void {
    this.couponEditService.clearEditData();
    this.stopCouponOddChanges();
    this.disposeLoading();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private showLoading(): void {
    this.showLoadingOverlay$.next(true);
  }

  private disposeLoading(): void {
    if (this.loadingTimeout) {
      this.showLoadingOverlay$.next(true);
      clearTimeout(this.loadingTimeout);
      this.showLoadingOverlay$.next(false);
    }
  }
}
