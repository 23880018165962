<app-nav-bar-acca-bonus-unacceptable-selections-prompt
  [text]="accumulatorBonusOddsValuePopupText$ | async"
  (closePrompt)="closeOddsValueInfoPopup()"
  *ngIf="applicationQuery.isShowingAccumulatorBonusUnacceptableSelectionsPrompt$ | async"
></app-nav-bar-acca-bonus-unacceptable-selections-prompt>
<div
  class="nav-bar"
  [class.rounded-corners]="applicationQuery.isShowingNavbarBetslipSelections$ | async"
  [ngClass]="{ 'logged-in': accountQuery.isAuthenticated }"
>
  <div class="nav-bar-popups-container">
    <app-nav-bar-betslip-selections
      *ngIf="applicationQuery.isShowingNavbarBetslipSelections$ | async"
      [text]="betslipSelectionsTextValue$ | async"
      [oddValue]="couponSelectionsTotalOdds$ | async | odd"
    ></app-nav-bar-betslip-selections>
    <app-nav-bar-acca-bonus-progression-bar
      (closeOddsValueInfoPopup)="closeOddsValueInfoPopup()"
      [accumulatorBonusList]="accumulatorBonusQuery.bonusList$ | async"
      [accumulatorBonusPercentage]="accumulatorBonusQuery.maxAccumulatorBonus$ | async"
      [accumulatorBonusValidSelectionCount]="accumulatorBonusQuery.accumulatorBonusSelectionCount$ | async"
      [cmsContent]="cmsContent$ | async"
      [lastPercentageItemShown]="accumulatorBonusQuery.lastPercentageItemShown"
      *ngIf="(accaBonusProgressBarElementVisibility$ | async)"
    ></app-nav-bar-acca-bonus-progression-bar>
  </div>
  <app-nav-bar-items></app-nav-bar-items>
</div>
