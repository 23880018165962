import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { oddChange } from 'src/app/shared/animations';
import { OddModel } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'app-live-odd',
  templateUrl: './live-odd.component.html',
  styleUrls: ['./live-odd.component.scss'],
  animations: [oddChange()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveOddComponent implements OnInit, OnDestroy {
  @Input() odd: OddModel;
  @HostBinding('class.single') @Input() single = false;
  selected$ = new BehaviorSubject<boolean>(false);

  private readonly destroy$ = new Subject<any>();

  constructor(private readonly couponQuery: CouponQuery, private readonly couponService: CouponService) {}

  ngOnInit(): void {
    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const selected = this.odd && this.couponService.isOddInCoupon(this.odd.id);
      this.selected$.next(selected);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selectOdd(): void {
    this.selected$.value ? this.couponService.removeOdd(this.odd.id) : this.couponService.addOdd(this.odd);
  }
}
