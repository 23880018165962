export const brandInfo = {
  brandName: '',
  brandUrl: '',
  metaDataDefaults: {
    title: '',
    description: '',
    'og:image': '',
    'og:type': '',
    'og:locale': '',
    'twitter:card': '',
  },
  footerNote: '',
};
