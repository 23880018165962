// CouponStatusID, BetFinalState and EventOutcome represents the same enums at the backend

import { BetLiveDetailsModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { JackpotType } from 'src/app/shared/models/jackpot.model';

export enum CouponStatus {
  Unknown,
  Running,
  Lost,
  Won,
  Cancelled,
  SystemEvaluation,
  Refused,
  UserEvaluation,
  ReOpened,
  Settled,
}

export enum BetFinalState {
  Placed = -1,
  InEvaluation = 0,
  Won = 1,
  PartiallyWon = 2,
  Lost = 3,
  Void = 4,
  Cashout = 5,
  Promotion = 6,
}

export enum EventOutcome {
  Unknown = -3,
  NoResult = -2,
  Void = -1,
  Lost = 0,
  Won = 1,
  Push = 2,
  HalfLost = 3,
  HalfWon = 4,
}

export class CouponDetailsModel {
  betFinalState: number;
  couponCode: string;
  couponDate: string;
  couponStatus: CouponStatus = CouponStatus.Unknown;
  couponType: string;
  couponTypeId: number;
  currencySymbol: string;
  groups: CouponDetailsGroupModel[];
  jackpotId: JackpotType;
  jackpotWinnings: number;
  liveDetails: BetLiveDetailsModel;
  maxBonus: number;
  maxBonusPerc: number;
  maxOdd: number;
  maxPotWin: number;
  maxPotWinNet: number;
  minWithholdingTax: number;
  maxWithholdingTax: number;
  maxWin: number;
  minBonus: number;
  minBonusPerc: number;
  minOdd: number;
  minWin: number;
  minWinNet: number;
  netStakeMaxWin: number;
  netStakeMinWin: number;
  odds: CouponDetailsOddModel[];
  paymentDate: string;
  rePrint: boolean;
  rebetEnabled: boolean = false;
  stake: number;
  stakeNet: number;
  stakeTax: number;
  totalCombinations: number;
  totalOdds: number;
  userId: number;
  userName: string;
  won: number;
  wonTax: number;
  selectionCount: number;

  constructor(init: Partial<CouponDetailsModel>) {
    Object.assign(this, init);
  }

  get isJackpotWinner(): boolean {
    return !!this.jackpotId && !!this.jackpotWinnings;
  }
}

export class CouponDetailsOddModel {
  eventId: number;
  orderId: number;
  championship?: string;
  sportName: string;
  categoryName: string;
  tournamentName: string;
  roundNumber: number;
  leagueNo: number;
  eventCategory: string;
  eventDate: string;
  eventName: string;
  homeTeamName?: string;
  awayTeamName?: string;
  marketOutright?: number;
  isGoalScorer?: boolean;
  isBanker: boolean;
  marketId: string;
  marketName: string;
  selectionName: string;
  oddValue: number;
  unboostedOddValue: number;
  resultStatus: string;
  resultStatusId: number;
  resultHTScore: {
    teamOne: number;
    teamTwo: number;
  };
  resultFTScore: {
    teamOne: number;
    teamTwo: number;
  };
  results: object;

  constructor(init: Partial<CouponDetailsOddModel>) {
    Object.assign(this, init);
  }

  get isBoosted(): boolean {
    return this.unboostedOddValue !== undefined && this.unboostedOddValue !== null && this.unboostedOddValue < this.oddValue;
  }
}

export class OddResultsModel {
  value: number;
  family: string;
  symbol: string;

  constructor(init: Partial<OddResultsModel>) {
    Object.assign(this, init);
  }
}

export class CouponDetailsGroupModel {
  combinations: number;
  grouping: number;
  maxBonus: number;
  maxWin: number;
  minBonus: number;
  minWin: number;
  netStakeMaxWin: number;
  netStakeMinWin: number;
  stake: number;
  stakeNet: number;
  stakeTax: number;

  constructor(init: Partial<CouponDetailsGroupModel>) {
    Object.assign(this, init);
  }
}

export class RecentBetsModel {
  couponCode: string;
  couponStatus: CouponStatus = CouponStatus.Unknown;
  stake: number;
  currencySymbol: string;
  won: number;

  constructor(init: Partial<RecentBetsModel>) {
    Object.assign(this, init);
  }
}

export class CouponDetailsState {
  couponDetails: CouponDetailsModel;
  ui: CouponDetailsUIState;

  constructor(init: Partial<CouponDetailsState>) {
    Object.assign(this, init);
  }
}

export class CouponDetailsUIState {
  isLoading: boolean;
  wrongCouponCode: boolean;
  checkOutValueLoading: boolean;

  constructor(init: Partial<CouponDetailsUIState>) {
    Object.assign(this, init);
  }
}
