import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { OddModel } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'app-odd',
  templateUrl: './odd.component.html',
  styleUrls: ['./odd.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OddComponent implements OnInit, OnDestroy {
  @Input() odd: OddModel | undefined;

  // Inputs for styling
  @Input() multiple = false;
  @Input() first = false;
  @Input() last = false;

  @HostBinding('class.selected') selected: boolean;

  private readonly destroy$ = new Subject<any>();

  constructor(
    private readonly couponService: CouponService,
    private readonly couponQuery: CouponQuery,
    private readonly cdr: ChangeDetectorRef
  ) {}

  @HostBinding('class.no-radius-left')
  get noRadiusLeft(): boolean {
    return this.multiple && !this.first;
  }

  @HostBinding('class.no-radius-right')
  get noRadiusRight(): boolean {
    return this.multiple && !this.last;
  }

  ngOnInit(): void {
    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const selected = this.odd && this.couponService.isOddInCoupon(this.odd.id);
      if (selected !== this.selected) {
        this.selected = selected;
        this.cdr.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click') selectOdd(): void {
    if (!this.odd) {
      return;
    }

    this.selected ? this.couponService.removeOdd(this.odd.id, this.odd.marketId) : this.couponService.addOdd(this.odd);
  }
}
