import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { MyOpenBetsState } from 'src/app/modules/my-bets/models/my-bets-state.model';
import { BetLiveDetailsModel } from 'src/app/modules/my-bets/models/my-bets.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-open-bets', resettable: true })
export class MyOpenBetsStore extends EntityStore<MyOpenBetsState> {
  constructor() {
    super();
  }

  toggleCollapsed(): void {
    this.updateActive(entity => ({
      collapsed: !entity.collapsed,
    }));
  }

  toggleBetInfoCollapsed(): void {
    this.updateActive(entity => ({
      betInfoCollapsed: !entity.betInfoCollapsed,
    }));
  }

  removeByCouponCode(couponCode: string): void {
    this.remove(bet => bet.couponCode === couponCode);
  }

  updateLiveBetsDetails(liveBetsDetails: BetLiveDetailsModel[]): void {
    this.update({ liveBetsDetails });
  }

  clear(): void {
    this.reset();
  }
}
