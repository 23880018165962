<div class="event-container">
  <div class="event-wrapper" [class.no-padding]="!showDetails" [class.show-border]="showBorder" [class.first]="first"
    [class.last]="last">

    <app-event-header-live *ngIf="showDetails && event"
        [event]="event" 
        [sportId]="sportId" 
        [tournamentName]="event.tournamentName">
    </app-event-header-live>

    <div class="event">
      <div class="event-details" [routerLink]="['/live', event.id]">
        <app-event-details-live [event]="event" [showDate]="showDate" *ngIf="showDetails && event">
        </app-event-details-live>
      </div>
      <div class="event-odds" *ngIf="isAvailable">
        <app-live-odds-widget [odds]="event.odds"></app-live-odds-widget>
      </div>
      <div class="event-odds" *ngIf="!isAvailable">
        <div class="not-available" i18n>Selection Not Available</div>
      </div>
    </div>
  </div>
</div>
