import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nav-bar-acca-bonus-unacceptable-selections-prompt',
  templateUrl: './nav-bar-acca-bonus-unacceptable-selections-prompt.component.html',
  styleUrls: ['./nav-bar-acca-bonus-unacceptable-selections-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarAccaBonusUnacceptableSelectionsPromptComponent {
  @Input() text: string;
  @Output() readonly closePrompt = new EventEmitter();

  close(): void {
    this.closePrompt.emit();
  }
}
