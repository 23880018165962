import { Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable } from 'rxjs';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { MyBetsCouponStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import {
  BetDetailsModel,
  BetLiveDetailsModel,
  RecentBetModel,
  VirtualsRecentBetRequestModel,
} from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsProductInterface } from 'src/app/modules/my-bets/services/interfaces/my-bets-product-interface';
import {
  mapCouponStatus,
  mapGetBetDetailsCallResponseToBetDetailsModel,
  mapGetMyBetsCallResponseToRecentBetModel,
} from 'src/app/modules/my-bets/services/my-bets-api-helpers';
import { APIType } from 'src/app/shared/models/api.model';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Injectable({
  providedIn: 'root',
})
export class VirtualsMyBetsService implements MyBetsProductInterface {
  private readonly _VIRTUALS_APP_CONFIG = this.appConfigService.get('sports');

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly languageService: LanguageService,
    private readonly loggerService: LoggerService
  ) {}

  getMyBetsCall(couponStatus: MyBetsCouponStatus): Observable<any> {
    return this.apiService.post<any>(APIType.VirtualsSportsbook, 'api/virtuals/coupons/couponsListByPostNoDate', {
      couponStatus: mapCouponStatus(couponStatus),
      includUsers: true,
      // Get one more to see if there are more than the configured maximum
      pageSize: (this._VIRTUALS_APP_CONFIG.myBets.couponCount as number) + 1,
      lang: this.languageService.selectedLanguage ? this.languageService.selectedLanguage.language : 'en',
    } as VirtualsRecentBetRequestModel);
  }

  getLiveDataCall(): Observable<BetLiveDetailsModel[]> {
    this.loggerService.logEvent(
      'Live events for MyBets Virtuals not available',
      'VIRTUALS',
      SeverityLevel.Warning,
      LogTypeModel.Application
    );
    return null;
  }

  getBetDetails(bet: RecentBetModel): Observable<any> {
    return this.apiService.get<any>(APIType.VirtualsSportsbook, `api/virtuals/coupons/byCode/${bet.couponCode}`);
  }

  parseGetMyBetsCallResponse = (response: any, isOpen: boolean = true): RecentBetModel[] =>
    mapGetMyBetsCallResponseToRecentBetModel(response, isOpen);

  parseBetDetailsResponse = (response: any): BetDetailsModel => mapGetBetDetailsCallResponseToBetDetailsModel(response);

  retrieveCashoutStatusesForOpenBets(bets: RecentBetModel[]): void {
    this.loggerService.logEvent('Cashout not available', 'VIRTUALS', SeverityLevel.Warning, LogTypeModel.Application);
  }
}
