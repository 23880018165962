import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { skip, takeUntil, tap } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { fadeIn } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { BetsTab } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { getProductList, ProductType } from 'src/app/shared/models/product.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'my-bets',
  templateUrl: './my-bets.component.html',
  styleUrls: ['./my-bets.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyBetsComponent implements OnInit, OnDestroy {
  buttonType: typeof ButtonType = ButtonType;
  betsTab: typeof BetsTab = BetsTab;
  private _productOnLoad = ProductType.SportsBook;

  readonly betsTabSelected$ = this.myBetsQuery.selectedBetsTab$;
  readonly destroy$: Subject<boolean> = new Subject<boolean>();
  readonly productList$ = new BehaviorSubject<string[]>([]);
  readonly productTabSelected$ = this.myBetsQuery.selectedProductTab$;
  readonly isFirstLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly location: Location,
    private readonly myBetsService: MyBetsService,
    private readonly appConfigService: AppConfigService,
    private readonly activatedRoute: ActivatedRoute,
    readonly accountQuery: AccountQuery,
    readonly myBetsQuery: MyBetsQuery,
    readonly myBetsStore: MyBetsStore
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      let tabSelected: ProductType = this.myBetsQuery.selectedProductTab;

      if (data) {
        switch (data.product) {
          case ProductType.Virtuals:
            if (this.appConfigService.get('virtuals').enabled) {
              tabSelected = ProductType.Virtuals;
            }
            break;
          case ProductType.JackpotBets:
            if (this.accountQuery.isJackpotBetsEnabled && this.appConfigService.get('jackpotBets').enabled) {
              tabSelected = ProductType.JackpotBets;
            }
            break;
          default:
            tabSelected = ProductType.SportsBook;
            break;
        }
      }
      this.changeProductTab(tabSelected);

      // Store the product on load so that on destroy this gets reverted
      this._productOnLoad = tabSelected;
    });

    this.dataRetrievalSubscriptions();

    this.myBetsQuery.isBookedBetEnabled$
      .pipe(
        tap(isBookedBetEnabled => {
          if (this.myBetsQuery.selectedBetsTab === BetsTab.Booked && !isBookedBetEnabled) {
            // Default to Open tab if Booked tab is currently selected whilst switching to product which does not support it
            this.changeBetsTab(BetsTab.Open);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.myBetsService.clearCashouts();
    this.myBetsService.clearSettledBets();
    this.myBetsService.clearExpandedBets();

    this.changeProductTab(this._productOnLoad);

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  goBack(): void {
    this.location.back();
  }

  changeBetsTab(tab: BetsTab): void {
    if (this.myBetsQuery.isBookedBetEnabled || tab !== BetsTab.Booked) {
      this.myBetsStore.changeBetsTab(tab);
    }
  }

  changeProductTab(tab: ProductType): void {
    this.myBetsStore.changeProductTab(tab);
  }

  private dataRetrievalSubscriptions(): void {
    const dataRetrievalOnTrigger = (selectedTab: BetsTab) => {
      if (selectedTab === BetsTab.Open) {
        this.myBetsService.getOpenBets();
      } else if (selectedTab === BetsTab.Settled) {
        this.myBetsService.getSettledBets();
      }
    };

    this.productTabSelected$
      .pipe(
        skip(1), // Skip first event as it wil be handled separately
        tap(() => {
          dataRetrievalOnTrigger(this.myBetsQuery.selectedBetsTab);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.betsTabSelected$
      .pipe(
        tap(tab => {
          dataRetrievalOnTrigger(tab);

          if (tab === BetsTab.Booked) {
            // Clear other tabs' data
            this.myBetsService.clearOpenBets();
            this.myBetsService.clearSettledBets();
          }

          // Hide Virtuals or JackpotBets Product if:
          // - in Booked Bets if it is disabled
          // - globally disabled
          this.productList$.next(
            getProductList().filter(prod => {
              const configCheck = (product: 'virtuals' | 'jackpotBets') =>
                (tab !== BetsTab.Booked && this.appConfigService.get(product).enabled) ||
                (tab === BetsTab.Booked &&
                  this.appConfigService.get(product).enabled &&
                  this.appConfigService.get(product).bookedBets.enabled);

              switch (prod) {
                case ProductType.Virtuals:
                  return configCheck('virtuals');
                case ProductType.JackpotBets:
                  return this.accountQuery.isJackpotBetsEnabled && configCheck('jackpotBets');
                default:
                  return true;
              }
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
