import { NgModule } from '@angular/core';
import { BookBetModule } from 'src/app/modules/book-bet/book-bet.module';
import { NoBetsComponent } from 'src/app/modules/my-bets/components/no-bets/no-bets.component';
import { RecentBetWrapperComponent } from 'src/app/modules/my-bets/components/recent-bet/recent-bet-wrapper/recent-bet-wrapper.component';
import { RecentBetComponent } from 'src/app/modules/my-bets/components/recent-bet/recent-bet.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookedBetsComponent } from './components/booked-bets/booked-bets.component';
import { MyBetsPageWrapperComponent } from './components/my-bets-page-wrapper/my-bets-page-wrapper.component';
import { RecentBetsComponent } from './components/recent-bets/recent-bets.component';
import { MyBetsRoutingModule } from './my-bets-routing.module';
import { MyBetsComponent } from './my-bets.component';

const COMPONENTS = [MyBetsComponent, RecentBetsComponent, BookedBetsComponent, NoBetsComponent, MyBetsPageWrapperComponent];

const EXPORTS = [RecentBetComponent, RecentBetWrapperComponent];

@NgModule({
  imports: [SharedModule, MyBetsRoutingModule, BookBetModule],
  declarations: [...COMPONENTS, ...EXPORTS],
  exports: [...EXPORTS],
})
export class MyBetsModule {}
