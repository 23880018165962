<div class="sections-container" *ngIf="(filteredGameCategories$ | async) && (filteredGameCategories$ | async).length">

  <app-card [titleHeaderText]="menuSection?.name" i18n-titleHeaderText [collapsible]="false"
    class="section virtuals-app-card" *ngFor="let menuSection of filteredGameCategories$ | async">
    <div class="games-container" body *ngIf="menuSection">
      <div [ngClass]="'games-wrapper games-wrapper-' + menuSection?.games.length">
        <div *ngFor="let game of menuSection?.games" class="game-wrapper">
          <div class="game" [ngClass]="'game-' + game.code" (click)="openGame(game)">
            <div class="game-thumbnail">
              <img [src]="game.thumbnailUrl" [class.disabled]="isGameDisabled(game)">
            </div>
            <span *ngIf="game.isNew" class="ribbon" i18n>NEW!</span>
          </div>
        </div>
      </div>
    </div>
  </app-card>

</div>

<div class="no-games-message"
  *ngIf="(virtualsQuery.menuSections$ | async) !== undefined && (virtualsQuery.menuSections$ | async).length === 0">
  <span i18n>No virtual games available</span>
</div>

<!-- TODO: This component should be replaced with <app-login-dialog> once the code of branch epic/VIR-7006 is merged -->
<!-- <app-virtuals-login-dialog *ngIf="loginDialogGame$ | async" [gameRoute]="['/virtual', (loginDialogGame$ | async).code]">
</app-virtuals-login-dialog> -->
