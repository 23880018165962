<div class="overlay-dropdown" (click)="openDropdown()">
  <ng-container *ngTemplateOutlet="buttonTemplate || defaultButton; context buttonContext">
  </ng-container>
</div>

<ng-template #defaultButton>
  <div class="selected-item" [ngClass]="{'opened over-backdrop': isOpen$ | async}">
    <i class="fa fa-sort" aria-hidden="true"></i>
    <span class="ellipsis">
      {{ selectedItem?.displayText }}
    </span>
    <i class="fa fa-caret-down" aria-hidden="true"></i>
  </div>
</ng-template>

<ng-template #dropdownOptions>
  <div class="overlay-dropdown-options">
    <div class="option" *ngFor="let option of options" (click)="optionClicked(option.value)"
      [class.selected]="isOptionSelected(option)">
      <span>{{option.displayText}}</span>
      <div class="ribbon-container" *ngIf="showIsNewTag(option.value)">
        <div class="ribbon" i18n>NEW!</div>
      </div>
    </div>
  </div>
</ng-template>
