<div class="selection-container">
  <!-- Expired Events -->
  <div class="selection-content expired-events"
    *ngIf="(couponQuery.expiredEvents$ | async) && (couponQuery.expiredEvents$ | async).expiredEvents.length > 0">
    <div class="tournament-name">
      <span i18n>Expired Events</span>
      <span class="clear-expired material-icons" (click)="clearExpiredEvents()">{{'delete'}}</span>
    </div>

    <div class="event-content"
      *ngFor="let match of (couponQuery.expiredEvents$ | async).expiredEvents; trackBy: eventTrackBy">
      <div class="event-container">
        <div class="event-details-container one-market">
          <div class="event-details">
            <span class="live" *ngIf="match.eventCategory === 'L'">
              <span class="material-icons">{{'schedule'}}</span>
              <span class="label" i18n>Live</span>
            </span>
            <div class="match-name">{{ match.eventName }}</div>
          </div>
        </div>
        <div class="market-odds-container">
          <div class="markets one-market one-selection" [class.no-edit]="bookedCoupon || match.eventCategory === 'L'">
            <div class="smart-code" *ngIf="match.eventCategory !== 'L'">{{ match.smartBetCode }}</div>
            <div class="details-container">
              <div class="market-details">
                <div class="edit-odd" *ngIf="!bookedCoupon && !(match.eventCategory === 'L')">
                  <i class="fa fa-pencil"></i>
                </div>
                <div class="market-name">
                  <span>{{ match.marketName }}:</span>
                </div>
              </div>
              <div class="selection-details">
                <div class="selection-details-content">
                  <div class="odd-details-row">
                    <div class="odd-details">
                      <div class="market-selection">
                        <span>{{ parseSelectionName(match.selectionName, match.eventName) }}</span>
                      </div>
                      <ng-container *ngIf="(match.eventCategory === 'L' && match.isLocked) else oddValue">
                        <div class="suspended" i18n>Suspended</div>
                      </ng-container>
                      <ng-template #oddValue>
                        <div class="odd-value">{{ match.oddValue | number: '1.2-2' }}</div>
                      </ng-template>
                    </div>
                    <span class="close-odd material-icons" *ngIf="!bookedCoupon">{{'delete'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Non-Expired Events -->
  <ng-container *ngFor="let selection of couponQuery.selections$ | async; trackBy: selectionTrackBy">
    <ng-container *ngFor="let category of selection.categories">
      <div class="selection-content" *ngFor="let tournament of category.tournaments; trackBy: tournamentTrackBy">
        <div class="tournament-name">
          <span class="sport-icon icon-{{ selection.id }}"></span>
          <span>{{ category.name }} - {{ tournament.name }}</span>
        </div>
        <div class="event-content" *ngFor="let match of tournament.matches; trackBy: matchTrackBy">
          <div class="banker-container"
            *ngIf="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination">
            <button class="banker banker-icon" *ngIf="match.allowFixed" [disabled]="match.isBankerDisabled"
              [class.selected]="match.fixed" (click)="updateOddBankerStatus(match.odds[0].id, !match.fixed)"></button>

            <span *ngIf="!match.allowFixed" class="banker locked material-icons">{{'lock'}}</span>
          </div>
          <div class="event-container">
            <div class="event-details-container" [class.one-market]="match.odds.length === 1">
              <div class="event-details">
                <span class="live" *ngIf="match.eventCategory === 'L'">
                  <span class="material-icons">{{'schedule'}}</span>
                  <span class="label" i18n>Live</span>
                </span>
                <ng-container *ngIf="(match.groupingType === 2 || match.hasExpiredEvents); else nameContent">
                  <div class="match-name"> {{ match.name }} </div>
                </ng-container>
                <ng-template #nameContent>
                  <div class="match-name link" (click)="goToPage($event, match)">
                    {{ match.name }}
                  </div>
                </ng-template>
              </div>
              <div class="smart-code" *ngIf="match.odds.length > 1 && match.eventCategory !== 'L'">{{ match.smartBetCode
              }}</div>
            </div>
            <div class="market-odds-container">
              <ng-container
                *ngFor="let marketOddMap of couponQuery.selectionMarketMatches$ | async; trackBy: indexTrackBy;">
                <ng-container *ngFor="let matchId of marketOddMap.matchIds">
                  <ng-container *ngIf="matchId === match.id">
                    <ng-container *ngFor="let market of tournament.regions[0].areas[0].markets; trackBy: indexTrackBy;">
                      <div class="markets"
                        [class.no-edit]="bookedCoupon || match.eventCategory === 'L'"
                        [class.one-market]="match.odds.length === 1"
                        [class.one-selection]="selectionCount(market.id, match.odds)"
                        *ngIf="marketOddMap.marketId === market.id">
                        <div class="smart-code" *ngIf="match.odds.length === 1 && match.eventCategory !== 'L'">
                          {{ match.smartBetCode }}
                        </div>
                        <div class="details-container">
                          <div class="market-details">
                            <div class="edit-odd" *ngIf="!bookedCoupon && !(match.eventCategory === 'L')"
                              (click)="editOdd(match.smartBetCode, market.typeId, market.spreadValue)" [class.selected]="
                                (couponQuery.couponforEdit$ | async)?.matchId === match.smartBetCode &&
                                (couponQuery.couponforEdit$ | async)?.marketTypeId === market.typeId
                              ">
                              <i class="fa fa-pencil"></i>
                            </div>
                            <div class="market-name">
                              <span>{{ market.name }}:</span>
                            </div>
                          </div>
                          <div class="selection-details">
                            <ng-container *ngFor="let odd of match.odds; trackBy: oddTrackBy">
                              <div class="selection-details-content" *ngIf="market.id === odd.marketId">
                                <div class="odd-details-row" [ngClass]="hasOddChanges$ | async | oddChanged: odd.id">
                                  <div class="odd-details">
                                    <div class="market-selection">
                                      <span>{{ parseSelectionName(odd.selectionName, match.name) }}</span>
                                    </div>
                                    <ng-container *ngIf="(match.eventCategory === 'L' && odd.isLocked) else oddValue">
                                      <div class="suspended" i18n>Suspended</div>
                                    </ng-container>
                                    <ng-template #oddValue>
                                      <div class="odd-value" [class.boosted]="!!odd.unboostedValue">
                                        <div class="normal-odd-value">
                                          <i *ngIf="!!odd.isBoosted" class="odds-boost-badge"></i>
                                          <span>{{ odd.value | number: '1.2-2' }}</span>
                                        </div>
                                        <div *ngIf="!!odd.isBoosted" class="unboosted-odd-value">
                                          {{ odd.unboostedValue | number: '1.2-2' }}
                                        </div>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <span class="close-odd material-icons" *ngIf="!bookedCoupon"
                                    (click)="removeOdd(odd.id)">{{'delete'}}</span>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="edit-odd-container" [class.expanded]="
                          (couponQuery.couponforEdit$ | async)?.matchId === match.smartBetCode &&
                          (couponQuery.couponforEdit$ | async)?.marketTypeId === market.typeId
                        " [@expandCollapse]="{
                          value:
                            (couponQuery.couponforEdit$ | async)?.matchId === match.smartBetCode &&
                            (couponQuery.couponforEdit$ | async)?.marketTypeId === market.typeId
                              ? 'expanded'
                              : 'collapsed',
                          params: { minHeight: '0px' }
                        }" *ngIf="marketOddMap.marketId === market.id && !bookedCoupon">
                        <ng-container *ngIf="
                            (couponQuery.editCouponData$ | async)?.smartBetCode === match.smartBetCode &&
                            ((couponQuery.editCouponData$ | async)?.odds[0]?.marketTypeId === market.typeId ||
                              (couponQuery.editCouponData$ | async)?.correctScoreOdds?.homeToWin[0]?.marketTypeId === market.typeId)
                          ">
                          <div class="edit-odds">
                            <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.correctScoreOdds !== undefined">
                              <!-- Correct score -->
                              <app-correct-score-event [showDetails]="false"
                                [event]="couponQuery.editCouponData$ | async">
                              </app-correct-score-event>
                            </ng-container>
                            <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.odds.length > 0">
                              <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.odds.length < 4">
                                <!-- Single-line -->
                                <app-events-markets-header
                                  [markets]="couponQuery.editCouponSelectionHeaderData$ | async" [separatorText]="
                                    (couponQuery.editCouponData$ | async)?.odds.length === 1 ? 'Remove selection?' : 'Make new selection?'
                                  " [bgColor]="marketBgColor">
                                </app-events-markets-header>
                                <div class="odds-container">
                                  <div class="single-line">
                                    <app-single-line-event [showDetails]="false" [showBorder]="false"
                                      [event]="couponQuery.editCouponData$ | async">
                                    </app-single-line-event>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.odds.length >= 4">
                                <!-- Multi-line -->
                                <div class="odds-container">
                                  <div class="multi-line">
                                    <span class="multi-line-selection-message" i18n>Make new selection?</span>
                                    <app-multi-line-event [showDetails]="false"
                                      [markets]="couponQuery.editCouponSelectionMarket$ | async"
                                      [event]="couponQuery.editCouponData$ | async">
                                    </app-multi-line-event>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                          <div class="market-closed" *ngIf="
                              (couponQuery.editCouponData$ | async)?.odds.length === 0 &&
                              (couponQuery.editCouponData$ | async)?.correctScoreOdds === undefined
                            ">
                            <div class="outright-message" *ngIf="(couponQuery.editCouponData$ | async).isOutright">
                              <ng-container *ngIf="couponQuery.editCouponData$ | async as data">
                                <span i18n>Outright selections can't be modified in the coupon. Please</span>
                                <a (click)="goToPage($event, data)" i18n>navigate</a>
                                <span i18n>to the outright page to modify your selections.</span>
                              </ng-container>
                            </div>
                            <span *ngIf="(couponQuery.editCouponData$ | async).isOutright === false" i18n>
                              Selections are no longer available
                            </span>
                          </div>
                          <div class="button-container">
                            <app-button buttonType=" normal" i18n-text text="close" (btnClick)="clearEditData()">
                            </app-button>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
