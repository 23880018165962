import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { CouponDetailsStore } from 'src/app/core/state/coupon-details/coupon-details.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { CashoutModel, CashoutSource } from 'src/app/shared/models/cashout.model';
import {
  BetFinalState,
  CouponDetailsGroupModel,
  CouponDetailsModel,
  CouponDetailsOddModel,
  CouponDetailsUIState,
  CouponStatus,
  OddResultsModel,
} from 'src/app/shared/models/coupon-details.model';
import {
  BetLiveDetailsModel,
  EventOddsModel,
  RecentBetModel,
  SportVirtualBetDetailsModel,
  SportVirtualEventModel,
} from 'src/app/modules/my-bets/models/my-bets.model';
import { isVirtualsCoupon } from 'src/app/shared/utils/is-virtuals-coupon';
import { guid } from '@datorama/akita';
import { RecentBetsStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { CouponDetailsQuery } from 'src/app/core/state/coupon-details/coupon-details.query';
import { LanguageService } from 'src/app/core/services/language.service';
import { MyBetsLiveService } from 'src/app/modules/my-bets/services/my-bets-live-service';

@Injectable({
  providedIn: 'root',
})
export class CouponDetailsService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly cashoutService: CashoutService,
    private readonly cashoutStore: CashoutStore,
    private readonly couponDetailsQuery: CouponDetailsQuery,
    private readonly couponDetailsStore: CouponDetailsStore,
    private readonly languageService: LanguageService,
    private readonly myBetsLiveService: MyBetsLiveService
  ) {
    this.handleLivePolling();
  }

  updateCouponDetailsUI(ui: CouponDetailsUIState): void {
    this.couponDetailsStore.updateUI(ui);
  }

  getCouponDetails(couponCode: string, noCheck: boolean = false, language: string = 'en'): Observable<void> {
    this.couponDetailsStore.updateCouponDetailsData(undefined);
    if (!couponCode) {
      return undefined;
    }

    const couponCodeArr = couponCode.split(',');
    const code = couponCodeArr[0];
    let inBehalfOf: string;
    if (couponCodeArr.length > 1) {
      inBehalfOf = couponCodeArr[1];
    }

    const apiSettings = new APISettings();
    const uiUpdate = new CouponDetailsUIState({ isLoading: true });

    this.updateCouponDetailsUI(uiUpdate);

    const isVirtuals = isVirtualsCoupon(code);

    let couponCheckByCodeApi: string;
    if (noCheck) {
      couponCheckByCodeApi = isVirtuals
        ? `api/virtuals/coupons/noCheck/byCode/${code}/language/${language}`
        : `api/coupons/noCheck/allByCode/${code}/language/${language}`;
      apiSettings.noAuthToken = true;
    } else {
      couponCheckByCodeApi = isVirtuals
        ? `api/virtuals/coupons/byCode/${code}` // TODO: language?
        : `api/coupons/allByCodeWithSettlementDate/${code}/language/${language}`;

      if (inBehalfOf) {
        apiSettings.inBehalfOf = inBehalfOf;
      }
    }

    return this.apiService.get<any>(isVirtuals ? APIType.VirtualsSportsbook : APIType.Sportsbook, couponCheckByCodeApi, apiSettings).pipe(
      mergeMap(betDetailsData => {
        if (!betDetailsData) {
          return new Observable<void>();
        }

        if (!betDetailsData.CouponCode) {
          const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: true, isLoading: false });
          this.updateCouponDetailsUI(uiUpdate2);
          return new Observable<void>();
        } else if (betDetailsData.CouponCode) {
          const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: false, isLoading: false });
          this.updateCouponDetailsUI(uiUpdate2);
        }

        let couponStatus: CouponStatus;
        switch (betDetailsData.CouponStatusId) {
          case 1:
            couponStatus = CouponStatus.Running;
            break;
          case 2:
            couponStatus = CouponStatus.Lost;
            break;
          case 3:
            couponStatus = CouponStatus.Won;
            break;
          case 4:
            couponStatus = CouponStatus.Cancelled;
            break;
          case 5:
            couponStatus = CouponStatus.SystemEvaluation;
            break;
          default:
            couponStatus = CouponStatus.Unknown;
            break;
        }

        let rebetEnabled = false;

        for (const odd of betDetailsData.Odds) {
          if (odd.Result === 'Unset') {
            rebetEnabled = true;
            break;
          }
        }

        const couponDetails = new CouponDetailsModel({
          betFinalState: betDetailsData.BetFinalState,
          couponCode: betDetailsData.CouponCode,
          couponDate: betDetailsData.CouponDate,
          couponStatus: couponStatus,
          couponType: betDetailsData.CouponType,
          couponTypeId: betDetailsData.CouponTypeId,
          currencySymbol: betDetailsData.Currency.CurrencySymbol,
          groups: [],
          jackpotId: betDetailsData.JackpotId,
          jackpotWinnings: betDetailsData.JP,
          maxBonus: betDetailsData.MaxBonus,
          maxBonusPerc: betDetailsData.MaxBonusPerc,
          maxOdd: betDetailsData.MaxOdd,
          maxPotWin: parseFloat(betDetailsData.NetStakeMaxWin) + parseFloat(betDetailsData.MaxBonus),
          maxPotWinNet: betDetailsData.MaxWinNet,
          minWithholdingTax: betDetailsData.MinWithholdingTax,
          maxWithholdingTax: betDetailsData.MaxWithholdingTax,
          maxWin: betDetailsData.MaxWin,
          minBonus: betDetailsData.MinBonus,
          minBonusPerc: betDetailsData.MinBonusPerc,
          minOdd: betDetailsData.MinOdd,
          minWin: betDetailsData.MinWin,
          minWinNet: betDetailsData.MinWinNet,
          netStakeMaxWin: betDetailsData.NetStakeMaxWin,
          netStakeMinWin: betDetailsData.NetStakeMinWin,
          odds: [],
          paymentDate: betDetailsData.PaymentDate,
          rePrint: betDetailsData.IsPrinted,
          rebetEnabled,
          stake: betDetailsData.StakeGross,
          stakeNet: betDetailsData.Stake,
          stakeTax: betDetailsData.TurnoverTax,
          totalCombinations: betDetailsData.TotalCombinations,
          totalOdds: betDetailsData.TotalOdds,
          userId: betDetailsData.UserId,
          userName: betDetailsData.UserName,
          won: betDetailsData.Won,
          wonTax: betDetailsData.TotalTaxed,
          selectionCount: betDetailsData.SelectionCount,
        });

        betDetailsData.Odds.forEach(odd => {
          const halftimeScore = odd.Results.filter(oddResults => oddResults.Family === 'HT');
          const fulltimeScore = odd.Results.filter(oddResults => oddResults.Family === 'FT');
          let results = {};
          let batch = [];
          for (const result of odd.Results) {
            batch.push(
              new OddResultsModel({
                family: result.Family,
                symbol: result.Symbol,
                value: result.Value,
              })
            );
            if (batch.length === 2) {
              results[batch[0].family.toLowerCase()] = batch;
              batch = [];
            }
          }

          if (!Object.keys(results).length) {
            results = undefined;
          }

          couponDetails.odds.push(
            new CouponDetailsOddModel({
              championship: odd.Championship,
              eventId: odd.IDEvent,
              orderId: odd.IDOrder,
              sportName: odd.SportName,
              categoryName: odd.CategoryName,
              tournamentName: odd.Championship,
              roundNumber: odd.RoundNumber,
              eventCategory: odd.EventCategory,
              eventDate: odd.EventDate,
              eventName: odd.EventName,
              homeTeamName: odd.HomeTeam,
              awayTeamName: odd.AwayTeam,
              marketOutright: odd.MarketOutright,
              isGoalScorer: odd.IsGoalScorer,
              isBanker: odd.FixedOdd,
              marketId: odd.IDMarketType,
              marketName: odd.MarketName,
              selectionName: odd.SelectionName,
              oddValue: odd.OddValue,
              unboostedOddValue: odd.UnboostedOddValue,
              leagueNo: odd.LeagueNo,
              results,
              resultStatus: odd.Result,
              resultStatusId: odd.Win,
              resultHTScore:
                halftimeScore.length !== 0
                  ? {
                      teamOne: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT1').Value,
                      teamTwo: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT2').Value,
                    }
                  : undefined,
              resultFTScore:
                fulltimeScore.length !== 0
                  ? {
                      teamOne: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT1').Value,
                      teamTwo: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT2').Value,
                    }
                  : undefined,
            })
          );
        });

        betDetailsData.Groupings.forEach(group => {
          couponDetails.groups.push(
            new CouponDetailsGroupModel({
              combinations: group.Combinations,
              grouping: group.Grouping,
              maxBonus: group.MaxBonus,
              maxWin: group.MaxWin,
              minBonus: group.MinBonus,
              minWin: group.MinWin,
              netStakeMaxWin: group.netStakeMaxWin,
              netStakeMinWin: group.NetStakeMinWin,
              stake: group.Stake,
              stakeNet: group.NetStake,
              stakeTax: group.TurnoverTax,
            })
          );
        });
        this.couponDetailsStore.updateCouponDetailsData(couponDetails);

        const uiUpdate2 = new CouponDetailsUIState({ isLoading: false });
        this.updateCouponDetailsUI(uiUpdate2);

        // Cashout is not implemented for virtuals yet
        if (!isVirtuals && couponDetails.betFinalState === BetFinalState.Placed) {
          return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/cashoutvalue_new/${betDetailsData.CouponCode}`).pipe(
            map(cashoutData => {
              if (cashoutData && cashoutData.length > 0) {
                const cashout: CashoutModel = new CashoutModel({
                  betCashout: this.cashoutService.parseBetCashoutResponse(cashoutData[0]),
                  betFinalState: betDetailsData.BetFinalState,
                  cashoutSource: CashoutSource.CouponDetails,
                  couponCode: betDetailsData.CouponCode,
                  userId: betDetailsData.UserId,
                });
                this.cashoutStore.addCashoutData(cashout);
              }
            })
          );
        } else {
          return new Observable<void>();
        }
      })
    );
  }

  clearCashouts(): void {
    this.cashoutStore.remove(entity => entity.cashoutSource === CashoutSource.CouponDetails);
  }

  getCoupon(recentBet: RecentBetModel): CouponDetailsModel {
    return new CouponDetailsModel({
      betFinalState: recentBet.betFinalState,
    });
  }

  mapToRecentBetsModel(details: CouponDetailsModel): RecentBetModel {
    const mapStatuses = (status: number) => {
      switch (status) {
        case -2:
          return RecentBetsStatus.Running;
        case -1:
          return RecentBetsStatus.Cancelled;
        case 0:
          return RecentBetsStatus.Lost;
        case 1:
          return RecentBetsStatus.Won;
        case 4:
          return RecentBetsStatus.PartiallyWon;
        default:
          return -99;
      }
    };

    const groupOddsWithSameEventAndMarket = () => {
      const betEvents: SportVirtualEventModel[] = [];

      const eventsMap = new Map<string, { event: CouponDetailsOddModel; odds: EventOddsModel[] }>();
      details.odds.forEach(odd => {
        const oddDetails: EventOddsModel = {
          marketName: odd.marketName,
          selectionName: odd.selectionName,
          oddStatusId: mapStatuses(odd.resultStatusId),
          isBanker: odd.isBanker,
          oddValue: odd.oddValue,
          unboostedOddValue: odd.unboostedOddValue,
          isBoosted: odd.isBoosted,
        };

        const mapKey = `${odd.eventId}_${odd.marketName}`;

        const foundEventOdds = eventsMap.has(mapKey) ? eventsMap.get(mapKey).odds : [];

        foundEventOdds.push(oddDetails);
        eventsMap.set(mapKey, { event: odd, odds: foundEventOdds });
      });

      eventsMap.forEach(value => {
        const event: SportVirtualEventModel = {
          championship: value.event.championship,
          eventId: value.event.eventId,
          sportId: value.event.sportName,
          eventName: value.event.eventName,
          homeTeamName: value.event.homeTeamName,
          awayTeamName: value.event.awayTeamName,
          marketOutright: value.event.marketOutright,
          isGoalScorer: value.event.isGoalScorer,
          eventDate: new Date(value.event.eventDate),
          eventStatusId: mapStatuses(value.event.resultStatusId),
          isLive: value.event.eventCategory === 'L',
          halfTimeScore: value.event.resultHTScore
            ? `${value.event.resultHTScore.teamOne}-${value.event.resultHTScore.teamTwo}`
            : undefined,
          fullTimeScore: value.event.resultFTScore
            ? `${value.event.resultFTScore.teamOne}-${value.event.resultFTScore.teamTwo}`
            : undefined,
          odds: value.odds,
          result: value.event.resultStatus,
        };

        if (value.odds.length > 1 && value.odds.some(odd => odd.oddStatusId === 1)) {
          // if any odd is won, set the main state as won
          event.eventStatusId = 1;
        }

        betEvents.push(event);
      });
      return betEvents;
    };

    return new RecentBetModel({
      betDetails: {
        totalOdds: details.totalOdds,
        totalCombinations: details.totalCombinations,
        netStakeMinWin: details.netStakeMinWin,
        netStakeMaxWin: details.netStakeMaxWin,
        minWin: details.minWin,
        maxWin: details.maxWin,
        maxWinNet: details.maxPotWinNet,
        minWithholdingTax: details.minWithholdingTax,
        maxWithholdingTax: details.maxWithholdingTax,
        minBonus: details.minBonus,
        maxBonus: details.maxBonus,
        minPercentageBonus: details.minBonusPerc,
        maxPercentageBonus: details.maxBonusPerc,
        turnoverTax: details.stakeTax,
        events: groupOddsWithSameEventAndMarket(),
        won: details.won,
        wonTax: details.wonTax,
        couponType: details.couponType,
        minOdd: details.minOdd,
        maxOdd: details.maxOdd,
      } as SportVirtualBetDetailsModel,
      betFinalState: details.betFinalState,
      collapsed: false,
      betInfoCollapsed: false,
      couponCode: details.couponCode,
      couponDate: details.couponDate,
      couponType: details.couponType,
      couponStatus: details.couponStatus.toString(),
      couponStatusId: details.couponStatus,
      currencySymbol: details.currencySymbol,
      id: guid(),
      jackpotId: details.jackpotId,
      jackpotWinnings: details.jackpotWinnings,
      stakeGross: details.stake,
      totalOdds: details.totalOdds,
      totalCombinations: details.totalCombinations,
      minOdd: details.minOdd,
      maxOdd: details.maxOdd,
      won: details.won,
      maxWinNet: details.maxPotWinNet,
      userId: details.userId.toString(),
      selectionCount: details.selectionCount,
    });
  }

  private readonly handleLivePolling = () => {
    const updateLiveBetsDetailsFunction = liveBetDetails => {
      this.couponDetailsStore.updateCouponDetailsLiveData(liveBetDetails);
    };

    const getLiveDataCallFunction = () => this.getLiveDataCall();

    this.myBetsLiveService.livePolling(
      getLiveDataCallFunction,
      updateLiveBetsDetailsFunction,
      this.couponDetailsQuery.pollLiveDetailsInterval,
      this.couponDetailsQuery.isLiveDetailsPollingEnabled$,
      // If we have live odd, then we have a live bet
      this.couponDetailsQuery.couponDetails$.pipe(
        filter(coupon => !!coupon),
        map(coupon => (coupon.odds.some(odd => odd.eventCategory === 'L') ? 1 : 0))
      ),
      this.destroy$
    );
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getLiveDataCall(): Observable<BetLiveDetailsModel[]> {
    return isVirtualsCoupon(this.couponDetailsQuery.couponDetails.couponCode)
      ? undefined // TODO
      : this.apiService
          .get(
            APIType.SportsbookFeed,
            `api/feeds/live/overview/general/${this.languageService.selectedLanguage.language}`,
            new APISettings({
              noAuthToken: true,
            })
          )
          .pipe(map(data => this.myBetsLiveService.parseSportsLiveEvents(data)));
  }
}
