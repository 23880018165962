import { BonusEligibilityModel, BonusInfoModel, BonusModel } from 'src/app/modules/accounts/modules/auth/models/bonus.model';
import { UserProfilesModel } from 'src/app/shared/models/bank-profile.model';
import { BetSearchModel, BetSearchParams } from './bet-search.model';
import { CurrencyModel } from './currency.model';

export interface AccountState {
  accountStatementFilter: AccountStatementFilterModel;
  accountStatements: AccountStatementModel[];
  betSearch: BetSearchState;
  bonusInfo: BonusInfoModel[];
  bonuses: BonusModel[];
  bonusEligibility: BonusEligibilityModel;
  changePasswordPageContent: ChangePasswordPageContent;
  changePhoneNumberOption: string;
  f2pUserData: UserModel;
  generalBanksList: [];
  helpMenuItems: AccountMenuLinkModel[];
  isAllowedBankAccInfo: boolean;
  loginPageContent: LoginPageContent;
  menuItems: AccountMenuLinkModel[];
  multipleUnconfirmedUsers: boolean;
  phoneOptionTriggered: boolean;
  qtyAllowedProfiles: number;
  qtyEnabledProfiles: number;
  resetOptionTriggered: boolean;
  resetPasswordOption: string;
  sbadIndicatorState: boolean;
  showUnverifiedTooltip: boolean;
  transferCandidates: TransferCandidateModel[];
  transferTypes: AccountStatementTransferTypeModel[];
  ui: AccountUIState;
  userBankProfile: UserProfilesModel[];
  userData: UserModel;
  verifyAccountState: VerifyAccountModel;
  viewingBonus: BonusModel;
  winNotificationSetting: boolean;
}

export class VerifyAccountModel {
  type: VerifyAccountType;
  gracePeriod: boolean;
  daysLeft: number;
  hoursLeft: number;
  mobileNumber: string;
  callback: Function;

  constructor(init: Partial<VerifyAccountModel>) {
    Object.assign(this, init);
  }
}

export enum VerifyAccountType {
  Verified,
  Locked,
  VerifyInProgress,
  AlreadyRegistered,
}

export interface BetSearchState {
  queryParams: BetSearchParams;
  betSearchResult: BetSearchModel[];
  isLoading: boolean;
}

export class AccountUIState {
  refreshingBalance: boolean;
  noStatements: boolean;
  bonus: {
    isViewingActive: boolean;
    isViewingPaused: boolean;
    isViewingInactive: boolean;
    isViewingPrevious: boolean;
    isViewingMissed: boolean;
  };

  constructor(init: Partial<AccountUIState>) {
    Object.assign(this, init);
  }
}

export interface AccountStatementFilterModel {
  transferType: string;
  dateFrom: string;
  dateTo: string;
  period: string;
}

export class AccountStatementModel {
  id: number;
  transactionDate: Date;
  description: string;
  amount: number;
  transactionStatusCode: string;
  rollingBalance: number;
  couponCode: string;

  constructor(init: AccountStatementModel) {
    Object.assign(this, init);
  }
}

export class AccountMenuLinkModel {
  text: string;
  link: string;
  iconFontValue: string;
  textIcon: boolean = false;
  isVisible: boolean = true;
  isLocked: boolean = false;
  visibleToTheseUserTypes: UserType[] = [];
  isCustomIcon?: boolean;

  constructor(init: Partial<AccountMenuLinkModel>) {
    Object.assign(this, init);
  }
}

export class UserModel {
  accessToken: string;
  isVirtualsEnabled: boolean;
  isGoldenRaceEnabled: boolean;
  isJackpotBetsEnabled: boolean;
  address: {
    id: number;
    addressLine: string;
    countryCode: string;
    cityCode: string;
    state: string;
  };
  currency: CurrencyModel;
  dateOfBirth: Date;
  email: string;
  gender: string;
  id: number;
  parentId?: number;
  loginDate: Date;
  marketingConsent: {
    id: number | undefined;
    value: boolean;
  };
  mobile: {
    id: number;
    mobileNumber: string;
    verifyType: string;
    lastStatusChangeDate: Date;
  };
  name: string;
  surname: string;
  username: string;
  userTypeCode: UserType;
  userStatus: string;
  wallets: {
    id: string;
    balance: number;
    name: string;
  }[];

  constructor(init: Partial<UserModel>) {
    Object.assign(this, init);
  }
}

export class LoginResponseModel {
  success: boolean;
  errorMessage: string;
  loginMessages: any;
  linkF2PAccountResponse?: LinkF2PAccountResponseModel;

  constructor(init: Partial<LoginResponseModel>) {
    Object.assign(this, init);
  }
}

export class PasswordResetTokenValidationModel {
  isValid: string;
  tokenOwnerName: string;
  expired?: boolean;

  constructor(init: Partial<PasswordResetTokenValidationModel>) {
    Object.assign(this, init);
  }
}

export class AccountStatementTransferTypeModel {
  id: number;
  description: string;

  constructor(init: AccountStatementTransferTypeModel) {
    Object.assign(this, init);
  }
}

export class TransferCandidateModel {
  userId: string;
  username: string;
  name: string;
  surname: string;
  balance: number;

  constructor(init: Partial<TransferCandidateModel>) {
    Object.assign(this, init);
  }
}

export interface LoginPageContent {
  loginHeader: string;
  loginLinkSectionHeader: string;
  loginLinkSectionMessage: string;
  loginLinkSectionCTAText: string;
  loginLinkSectionCTAURL: string;
  f2PUpsellLoginImage: string;
  f2PUpsellLoginHeader: string;
  f2PUpsellLoginSubtitle: string;
  f2PUpsellSuccessHeader: string;
  f2PUpsellSuccessMessage: string;
  f2PUpsellSuccessCTAText: string;
  f2PUpsellSuccessCTAURL: string;
}

export interface RequestPasswordResetResponse {
  result: {
    resetPasswordMethod: ResetPasswordMethod;
    hasF2PAccount: boolean;
  };
  systemMessage: string;
  responseCode: number;
  responseMessage: string;
}

export interface ChangePasswordPageContent {
  forgotPasswordHeader: string;
  forgotPasswordMessage: string;
  resetPasswordHeader: string;
  resetPasswordMessage: string;
  resetPasswordF2PMessage: string;
  changePasswordSuccessHeader: string;
  changePasswordSuccessMessage: string;
  changePasswordSuccessCTAText: string;
  changePasswordSuccessCTAURL: string;
}

export interface LinkF2PAccountResponseModel {
  success: boolean;
  responseCode: LinkF2PAccountResponseCodes;
}

export enum ProfileEditability {
  Off = 'Off',
  OneTime = 'One-Time',
  Always = 'Always',
}

export enum UserType {
  Master = 'master',
  SuperAgent = 'superAgent',
  ShopOwner = 'shopOwner',
  Cashier = 'cashier',
  User = 'user',
  SubAccountPlayer = 'subAccountPlayer',
}

export enum LoginType {
  Username = 'Username',
  Mobile = 'Mobile',
  UsernameOrMobile = 'UsernameOrMobile',
}

export enum ResetPasswordMethod {
  Email = 1,
  SMS = 2,
  User = 99, // When user clicks on change password in account
}

export enum LinkF2PAccountResponseCodes {
  OK = 'OK',
  F2PMismatchMobileNo = 'F2P_Mismatch_MobileNo',
  F2PMobileAlreadyLinked = 'F2P_Mobile_Already_Linked',
  F2PInvalidToken = 'F2P_Invalid_Token',
  ServerError = 'SERVER_ERROR',
  ApplicationError = 'APPLICATION_ERROR',
}
