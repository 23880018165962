import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoginService } from 'src/app/core/services/account/login.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Injectable({
  providedIn: 'root',
})
export class AutoLoginResolver implements Resolve<any> {
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly loginService: LoginService,
    private readonly loggerService: LoggerService,
    private readonly router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    if (route.queryParams.btk) {
      if (this.accountQuery.isAuthenticated) {
        if (route.queryParams.btk === this.accountQuery.accessToken) {
          if (route.queryParams.redirect) {
            this.router.navigateByUrl(route.queryParams.redirect);
            return of(undefined);
          }
        } else {
          // If already logged in, loginWithToken function will log the user out and re-login with current token
          this.loggerService.logEvent(
            'Logging out current user',
            this.accountQuery.userData.username,
            SeverityLevel.Verbose,
            LogTypeModel.Network
          );
        }
      }
      return this.performAutoLogin(route);
    }
    return of(undefined);
  }

  goToLogin(): void {
    this.router.navigate(['account', 'login']);
  }

  private performAutoLogin(route: ActivatedRouteSnapshot): Observable<void> {
    return this.loginService.loginWithToken(route.queryParams.btk).pipe(
      map((response: boolean) => {
        if (response) {
          this.loggerService.logEvent('AutoLogin performed', route.queryParams.btk, SeverityLevel.Verbose, LogTypeModel.Network);
        } else {
          this.loggerService.logEvent('AutoLogin failed', route.queryParams.btk, SeverityLevel.Information, LogTypeModel.Network);
          this.goToLogin();
        }
      }),
      catchError(err => {
        this.loggerService.logEvent('Error with AutoLogin', route.queryParams.btk, SeverityLevel.Error, LogTypeModel.Network);
        this.goToLogin();
        return of(undefined);
      }),
      finalize(() => {
        if (route.queryParams.redirect) {
          window.location.href = route.queryParams.redirect;
        } else if (typeof URLSearchParams !== 'undefined') {
          // clean URL
          const url = new URL(document.location as any);
          url.searchParams.delete('btk');
          if (url.searchParams.has('bkToken')) {
            url.searchParams.delete('bkToken');
          }
          if (url.searchParams.has('createSIDs')) {
            url.searchParams.delete('createSIDs');
          }
          if (url.searchParams.has('stake')) {
            url.searchParams.delete('stake');
          }
          window.history.replaceState({}, document.title, `${url.origin}${url.search ? `?${url.search}` : ''}`);
        }
      })
    );
  }
}
