import { Injectable } from '@angular/core';
import { isVirtualsCoupon } from 'src/app/shared/utils/is-virtuals-coupon';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { VirtualsCouponService } from 'src/app/core/services/virtuals-coupon/virtuals-coupon.service';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class RebetService {
  private readonly destroy$ = new Subject<boolean>();

  constructor(private readonly couponService: CouponService, private readonly virtualsCouponService: VirtualsCouponService) {}

  performRebet(couponCode: string) {
    return isVirtualsCoupon(couponCode) ? this.virtualsCouponService.rebetCoupon(couponCode) : this.couponService.rebetCoupon(couponCode);
  }
}
