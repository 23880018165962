import { Injectable } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'src/app/core/services/cookie.service';
import { LanguageModel } from 'src/app/shared/models/application.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  enableLanguageSelection$: BehaviorSubject<boolean>;
  selectedLanguage$: BehaviorSubject<LanguageModel>;

  private readonly LANGUAGE_STORAGE_KEY = 'siteLanguage';

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly cookieService: CookieService,
    private readonly sessionStorage: SessionStorageService
  ) {
    this.enableLanguageSelection$ = new BehaviorSubject<boolean>(false);
    this.selectedLanguage$ = new BehaviorSubject<LanguageModel>(undefined);
  }

  get selectedLanguage(): LanguageModel {
    return this.selectedLanguage$.getValue();
  }

  initialize(): void {
    this.enableLanguageSelection$.next(this.getLanguages().length > 1);

    const siteLang = this.getLanguageFromCookie() || this.getLanguageFromSession() || this.getDefaultLanguage();
    this.setLanguage(siteLang);
  }

  getLanguages(): LanguageModel[] {
    const languages = this.appConfigService.get('languages');
    if (languages && Array.isArray(languages)) {
      return languages;
    }
    return [];
  }

  saveLanguageSelection(language: string): void {
    this.cookieService.setCookie(this.LANGUAGE_STORAGE_KEY, language, 365, '/');
    this.sessionStorage.store(this.LANGUAGE_STORAGE_KEY, language);
    this.setLanguage(language);
  }

  private getDefaultLanguage(): string {
    const languages = this.getLanguages();
    if (languages.length > 0) {
      return languages[0].language;
    }
    return 'en';
  }

  private setLanguage(language: string): void {
    const selectedLanguage = this.appConfigService.get('languages').find((l: LanguageModel) => l.language === language);
    if (selectedLanguage) {
      this.selectedLanguage$.next(selectedLanguage);
    }
  }

  private getLanguageFromSession(): string {
    return this.sessionStorage.retrieve(this.LANGUAGE_STORAGE_KEY);
  }

  private getLanguageFromCookie(): string {
    return this.cookieService.getCookie(this.LANGUAGE_STORAGE_KEY);
  }
}
