import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { ProductType } from 'src/app/shared/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  constructor(
    private readonly applicationQuery: ApplicationQuery,
    private readonly applicationStore: ApplicationStore,
    private readonly router: Router
  ) {}

  initialize(): void {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data) {
          if (event.snapshot.data.productType) {
            this.updateActiveProduct(event.snapshot.data.productType);
          }

          if (event.snapshot.data.inCoupon !== undefined) {
            this.updateInCoupon(event.snapshot.data.inCoupon);
          }

          if (event.snapshot.data.inMyBets !== undefined) {
            this.updateInMyBets(event.snapshot.data.inMyBets);
          }

          if (event.snapshot.data.isSportsSection !== undefined) {
            this.updateInSportsSection(event.snapshot.data.isSportsSection);
          }
        }
      }
    });
  }

  updateActiveProduct(productType: ProductType): void {
    if (this.applicationQuery.activeProduct !== productType) {
      this.applicationStore.update({ activeProduct: productType });
    }
  }

  updateInCoupon(inCoupon: boolean): void {
    if (this.applicationQuery.inCoupon !== inCoupon) {
      this.applicationStore.updateUI({ inCoupon });
    }
  }

  updateInMyBets(inMyBets: boolean): void {
    if (this.applicationQuery.inMyBets !== inMyBets) {
      this.applicationStore.updateUI({ inMyBets });
    }
  }

  updateInSportsSection(isSportsSection: boolean): void {
    if (this.applicationQuery.isSportsSection !== isSportsSection) {
      this.applicationStore.updateUI({ isSportsSection });
    }
  }
}
