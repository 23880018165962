import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { CashoutSource } from 'src/app/shared/models/cashout.model';
import { BetDetailsModel, MyBetsContentModel, RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsCouponStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { VirtualsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-virtuals.service';
import { SportsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-sports.service';
import { MyBetsProductInterface } from 'src/app/modules/my-bets/services/interfaces/my-bets-product-interface';
import { ProductType } from 'src/app/shared/models/product.model';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { APIType } from 'src/app/shared/models/api.model';
import { MyBetsLiveService } from 'src/app/modules/my-bets/services/my-bets-live-service';
import { JackpotBetsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-jackpotbets.service';

@Injectable({
  providedIn: 'root',
})
export class MyBetsService implements OnDestroy {
  private myBetsProductService: MyBetsProductInterface = undefined;
  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly cashoutStore: CashoutStore,
    private readonly languageService: LanguageService,
    private readonly myBetsLiveService: MyBetsLiveService,
    private readonly myBetsQuery: MyBetsQuery,
    private readonly myBetsStore: MyBetsStore,
    private readonly sportsMyBetsService: SportsMyBetsService,
    private readonly virtualsMyBetsService: VirtualsMyBetsService,
    private readonly jackpotBetsMyBetsService: JackpotBetsMyBetsService
  ) {
    this.handleProductChange();
    this.handleLivePolling();
  }

  initialize(): void {
    this.apiService
      .get(APIType.CMS, `MyBets/MyBetsContent?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`)
      .pipe(
        tap((response: MyBetsContentModel) => {
          this.myBetsStore.updateMyBetsContent(response);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getOpenBets(): void {
    const complete = () => {
      this.clearSettledBets();
      this.myBetsStore.setOpenLoading(false);
    };
    const error = () => {
      // Better not show anything, than show historic data
      this.clearOpenBets();

      complete();
    };

    this.myBetsStore.setOpenLoading();
    this.myBetsProductService.getMyBetsCall(MyBetsCouponStatus.Open).subscribe(this.handleOpenBetsResponse, error, complete);
  }

  getSettledBets(): void {
    const complete = () => {
      this.clearOpenBets();
      this.myBetsStore.setSettledLoading(false);
    };
    const error = () => {
      // Better not show anything, than show historic data
      this.clearSettledBets();

      complete();
    };
    this.myBetsStore.setSettledLoading();
    this.myBetsProductService.getMyBetsCall(MyBetsCouponStatus.Settled).subscribe(this.handleSettledResponse, error, complete);
  }

  addNewBet(couponCode: string): void {
    this.myBetsStore.addNewBet(couponCode);
  }

  getBetDetails(bet: RecentBetModel): Observable<boolean> {
    if (!bet || !bet.couponCode) {
      return new Observable<boolean>();
    }

    this.toggleCollapsed(bet);

    return this.myBetsProductService.getBetDetails(bet).pipe(
      map(betDetailsData => {
        if (!betDetailsData) {
          return false;
        }

        const betDetails: BetDetailsModel = this.myBetsProductService.parseBetDetailsResponse(betDetailsData);
        if (betDetails) {
          this.myBetsStore.updateBetDetails(bet.id, betDetails, bet.couponStatusId);
        }

        return true;
      })
    );
  }

  toggleCollapsed(bet: RecentBetModel): void {
    this.myBetsStore.toggleCollapsed(bet);
  }

  toggleBetInfoCollapsed(bet: RecentBetModel): void {
    this.myBetsStore.toggleBetInfoCollapsed(bet);
  }

  clearCashouts(): void {
    if (this.myBetsQuery.isCashoutEnabled) {
      this.cashoutStore.remove(entity => entity.cashoutSource === CashoutSource.RecentBets);
    }
  }

  clearSettledBets(): void {
    this.myBetsStore.clearSettledBets();
  }

  clearOpenBets(): void {
    this.myBetsStore.clearOpenBets();
  }

  clearExpandedBets(): void {
    this.myBetsStore.clearOpenExpandedBets();
  }

  removeFromOpenBets(couponCode: string): void {
    this.myBetsStore.removeOpenBet(couponCode);
  }

  isRecentBetRebetEnabled(recentBet: RecentBetModel): boolean {
    return recentBet.betDetails && recentBet.betDetails.events.length > 0
      ? recentBet.betDetails.events.some(event => event.odds.some(odd => odd.oddStatusId === -1))
      : false;
  }

  private readonly handleOpenBetsResponse = (open): void => {
    const bets = this.myBetsProductService.parseGetMyBetsCallResponse(open);
    if (this.myBetsQuery.isInMyBets && this.myBetsQuery.isCashoutEnabled) {
      this.myBetsProductService.retrieveCashoutStatusesForOpenBets(bets);
    }

    this.myBetsStore.setOpenBets(bets);
  };

  private readonly handleSettledResponse = (settled): void => {
    this.myBetsStore.setSettledBets(this.myBetsProductService.parseGetMyBetsCallResponse(settled, false));
    this.myBetsStore.setSettledLoading(false);
  };

  private readonly handleLivePolling = () => {
    const updateLiveBetsDetailsFunction = liveBetDetails => {
      this.myBetsStore.updateLiveBetsDetails(liveBetDetails);
    };

    const getLiveDataCallFunction = () => this.myBetsProductService.getLiveDataCall();

    this.myBetsLiveService.livePolling(
      getLiveDataCallFunction,
      updateLiveBetsDetailsFunction,
      this.myBetsQuery.pollLiveDetailsInterval,
      this.myBetsQuery.isLiveDetailsPollingEnabled$,
      this.myBetsQuery.expandedLiveBetsCount$,
      this.destroy$
    );
  };

  private readonly handleProductChange = () => {
    this.myBetsQuery.selectedProductTab$
      .pipe(
        distinctUntilChanged(),
        tap(product => {
          switch (product) {
            case ProductType.Virtuals:
              this.myBetsProductService = this.virtualsMyBetsService;
              break;
            default:
            case ProductType.SportsBook:
              this.myBetsProductService = this.sportsMyBetsService;
              break;
            case ProductType.JackpotBets:
              this.myBetsProductService = this.jackpotBetsMyBetsService;
              break;
          }

          if (this.myBetsQuery.isInMyBets) {
            this.myBetsStore.updateLiveBetsDetails(undefined);
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  };
}
