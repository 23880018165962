import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { expandCollapse } from 'src/app/shared/animations';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-single-line-event',
  templateUrl: './single-line-event.component.html',
  styleUrls: ['./single-line-event.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLineEventComponent {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showDate = true;
  @Input() showBorder: boolean = true;
  @Input() showDetails: boolean = true;
  @Input() last?: boolean;
  @Input() selectedMarket: MarketModel = undefined;

  constructor(readonly statisticsQuery: StatisticsQuery, readonly matchService: MatchService) {}

  getOdds(): OddModel[] {
    return this.selectedMarket
      ? this.selectedMarket.selections
        ? this.selectedMarket.selections.map(m =>
            this.event.odds.find(odd => odd.marketName === this.selectedMarket.name && odd.selectionName === m.name)
          )
        : this.event.odds.filter(odd => odd.marketTypeId === this.selectedMarket.typeId)
      : this.event.odds;
  }
}
