import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// Components
import { AppBannerComponent } from 'src/app/shared/components/app-banner/app-banner.component';
import { BreadcrumbComponent } from 'src/app/shared/components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from 'src/app/shared/components/button/button.component';
import { CardComponent } from 'src/app/shared/components/app-card/app-card.component';
import { CashoutComponent } from 'src/app/shared/components/cashout/cashout.component';
import { CorrectScoreEventComponent } from 'src/app/shared/components/events/correct-scores-event/correct-score-event.component';
import { CorrectScoresMarketOddsComponent } from 'src/app/shared/components/market-odds/correct-scores/correct-scores-market-odds.component';
import { CouponCheckComponent } from 'src/app/shared/components/coupon-check/coupon-check.component';
import { CouponEvaluationComponent } from 'src/app/shared/components/coupon-evaluation/coupon-evaluation.component';
import { DropdownComponent } from 'src/app/shared/components/dropdown/dropdown.component';
import { EventDetailsComponent } from 'src/app/shared/components/events/event-details/event-details.component';
import { EventTournamentDetailsComponent } from 'src/app/shared/components/events/event-tournament-details/event-tournament-details.component';
import { EventsMarketsHeaderComponent } from 'src/app/shared/components/events/events-markets-header/events-markets-header.component';
import { EventsSummaryComponent } from 'src/app/shared/components/events/events-summary/events-summary.component';
import { FavouriteToggleComponent } from 'src/app/shared/components/sports/favourite-toggle/favourite-toggle.component';
import { FooterSectionComponent } from 'src/app/shared/components/footer-section/footer-section.component';
import { FormItemGroupComponent } from 'src/app/shared/components/form-item-group/form-item-group.component';
import { FormMessageComponent } from 'src/app/shared/components/form-message/form-message.component';
import { HelpItemsComponent } from 'src/app/shared/components/help-items/help-items.component';
import { InfoModalComponent } from 'src/app/shared/components/info-modal/info-modal.component';
import { InnerCardComponent } from 'src/app/shared/components/inner-card/inner-card.component';
import { InsufficientBalanceComponent } from 'src/app/shared/components/insufficient-balance/insufficient-balance.component';
import { LanguagePageComponent } from 'src/app/shared/components/langauge-page/language-page.component';
import { LanguageSelectorComponent } from 'src/app/shared/components/langauge-selector/language-selector.component';
import { LegendTableComponent } from 'src/app/shared/components/legend-table/legend-table.component';
import { LiveOddComponent } from 'src/app/shared/components/live/live-odd/live-odd.component';
import { LiveOddsComponent } from 'src/app/shared/components/live/live-odds/live-odds.component';
import { LoadingBarComponent } from 'src/app/shared/components/loading/loading-bar/loading-bar.component';
import { LoadingBarOverlayComponent } from 'src/app/shared/components/loading/loading-bar-overlay/loading-bar-overlay.component';
import { LoadingCircleComponent } from 'src/app/shared/components/loading/loading-circle/loading-circle.component';
import { MenuHowToPlayComponent } from 'src/app/shared/components/menu/menu-how-to-play/menu-how-to-play.component';
import { MenuPageWrapperComponent } from 'src/app/shared/components/menu/menu-page-wrapper/menu-page-wrapper.component';
import { MenuPromosComponent } from 'src/app/shared/components/menu/menu-promos/menu-promos.component';
import { MenuQuicklinksComponent } from 'src/app/shared/components/menu/menu-quicklinks/menu-quicklinks.component';
import { MenuSportsComponent } from 'src/app/shared/components/menu/menu-sports/menu-sports.component';
import { MenuTabsComponent } from 'src/app/shared/components/menu/menu-tabs/menu-tabs.component';
import { MenuWrapperComponent } from 'src/app/shared/components/menu/menu-wrapper/menu-wrapper.component';
import { MobilePrefixComponent } from 'src/app/shared/components/mobile-prefix/mobile-prefix.component';
import { MultiLineEventComponent } from 'src/app/shared/components/events/multi-line-event/multi-line-event.component';
import { MultiLineMarketOddsComponent } from 'src/app/shared/components/market-odds/multi-line/multi-line-market-odds.component';
import { NavBarBetslipSelectionsComponent } from 'src/app/shared/components/nav-bar/nav-bar-betslip-selections/nav-bar-betslip-selections.component';
import { NavBarAccaBonusUnacceptableSelectionsPromptComponent } from 'src/app/shared/components/nav-bar/nav-bar-acca-bonus-unacceptable-selections-prompt/nav-bar-acca-bonus-unacceptable-selections-prompt.component';
import { NavBarComponent } from 'src/app/shared/components/nav-bar/nav-bar.component';
import { NavBarItemsComponent } from 'src/app/shared/components/nav-bar/nav-bar-items/nav-bar-items.component';
import { OTPInputFieldComponent } from 'src/app/shared/components/otp/otp-input-field/otp-input-field.component';
import { OTPScreenComponent } from 'src/app/shared/components/otp/otp-screen/otp-screen.component';
import { OddChangedPipe } from 'src/app/shared/pipes/odd-changed.pipe';
import { OddComponent } from 'src/app/shared/components/odd/odd.component';
import { OddGroupComponent } from 'src/app/shared/components/odd-group/odd-group.component';
import { OverlayDropdownComponent } from 'src/app/shared/components/overlay-dropdown/overlay-dropdown.component';
import { PaymentPopulatorDirective } from 'src/app/shared/directives/payment-populator.directive';
import { PhoneUnverifiedTooltipComponent } from 'src/app/shared/components/phone-unverified-tooltip/phone-unverified-tooltip.component';
import { PhoneVerificationComponent } from 'src/app/shared/components/phone-verification/phone-verification.component';
import { PhoneVerificationPopupComponent } from 'src/app/shared/components/phone-verification-popup/phone-verification-popup.component';
import { PlaceholderComponent } from 'src/app/shared/components/placeholder/placeholder.component';
import { ProceedBarComponent } from 'src/app/shared/components/proceed-bar/proceed-bar/proceed-bar.component';
import { ProceedBarsContainerComponent } from 'src/app/shared/components/proceed-bar/proceed-bars-container.component';
import { RebetComponent } from 'src/app/shared/components/rebet/rebet.component';
import { RecentSearchesComponent } from 'src/app/shared/components/recent-searches/recent-searches.component';
import { RegionAreaDropdownComponent } from 'src/app/shared/components/region-area-dropdown/region-area-dropdown.component';
import { ScrollToTopComponent } from 'src/app/shared/components/scroll-to-top/scroll-to-top.component';
import { SfkChatComponent } from 'src/app/shared/components/sfk-chat/sfk-chat.component';
import { SingleLineEventComponent } from 'src/app/shared/components/events/single-line-event/single-line-event.component';
import { SingleLineMarketOddsComponent } from 'src/app/shared/components/market-odds/single-line/single-line-market-odds.component';
import { SlidePanelComponent } from 'src/app/shared/components/slide-panel/slide-panel.component';
import { SportLinkComponent } from 'src/app/shared/components/sports/sport-link/sport-link.component';
import { SportsListComponent } from 'src/app/shared/components/sports/sports-list/sports-list.component';
import { StatisticsComponent } from 'src/app/shared/components/statistics/statistics.component';
import { ToastNotificationComponent } from 'src/app/shared/components/toast-notification/toast-notification.component';
import { VirtualsOfferingsComponent } from 'src/app/shared/components/virtuals-offerings/virtuals-offerings.component';
import { NotificationMessageComponent } from 'src/app/shared/components/notification-message/notification-message.component';

// Directives
import { AutocompleteOTPInputFieldDirective } from 'src/app/shared/directives/autocomplete-otp-input-field.directive';
import { BadgeDirective } from 'src/app/shared/directives/badge.directive';
import { DropdownArrowRotatorDirective } from 'src/app/shared/directives/dropdown-arrow-rotator.directive';
import { DynamicSvgBackgroundDirective } from 'src/app/shared/directives/dynamic-svg-background.directive';
import { DynamicSvgMaskDirective } from 'src/app/shared/directives/dynamic-svg-mask.directive';
import { HideIfEmptyDirective } from 'src/app/shared/directives/hide-if-empty.directive';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';
import { NavBarSvgLoaderDirective } from 'src/app/shared/directives/navbar-svg-loader.directive';
import { ResizeOnScrollDirective } from 'src/app/shared/directives/resize-on-scroll.directive';
import { ScrollToTopPositionerDirective } from 'src/app/shared/directives/scroll-to-top-positioner.directive';
import { SelectAllDirective } from 'src/app/shared/directives/select-all.directive';
import { SelectedTabCenterizerDirective } from 'src/app/shared/directives/selected-tab-centerizer.directive';
import { UnselectAllDirective } from 'src/app/shared/directives/unselect-all.directive';
import { VirtualsLoaderDirective } from 'src/app/shared/directives/virtuals-loader.directive';
import { ZIndexDirective } from 'src/app/shared/directives/z-index.directive';
import { MatchScrollDirective } from 'src/app/shared/directives/match-scroll.directive';

// Pipes
import { AdjustContainerOnHorizontalScrollDirective } from 'src/app/shared/directives/adjust-container-on-horizontal-scroll.directive';
import { AdjustTabOnSelectedDirective } from 'src/app/shared/directives/adjust-tab-on-selected.directive';
import { AppMessageComponent } from 'src/app/shared/components/app-message/app-message.component';
import { BetFinalStateToUI } from 'src/app/shared/pipes/bet-final-state-to-ui.pipe';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { DisplayScorePipe } from 'src/app/shared/pipes/score.pipe';
import { EventDetailsLiveComponent } from 'src/app/shared/components/events/event-details-live/event-details-live.component';
import { EventHeaderLiveComponent } from 'src/app/shared/components/events/event-header-live/event-header-live.component';
import { EventsSummaryLiveComponent } from 'src/app/shared/components/events/events-summary-live/events-summary-live.component';
import { FilterByTermAndPropertyPipe } from 'src/app/shared/pipes/filter-by-term-property.pipe';
import { FilterMatchesPipe } from 'src/app/shared/pipes/filter-matches.pipe';
import { FilterSearchPipe } from 'src/app/shared/pipes/filter-search.pipe';
import { FormatOddPipe } from 'src/app/shared/pipes/format-odd.pipe';
import { GroupNamePipe } from 'src/app/shared/pipes/group-name.pipe';
import { GroupOddsByMarketPipe } from 'src/app/shared/pipes/group-odds-by-market.pipe';
import { HasGroupingsPipe } from 'src/app/shared/pipes/has-groupings.pipe';
import { HeaderComponent } from 'src/app/shared/components/header/header.component';
import { Highlight } from 'src/app/shared/pipes/highlight.pipe';
import { IncludesPipe } from 'src/app/shared/pipes/includes.pipe';
import { IsGroupingVisiblePipe } from 'src/app/shared/pipes/is-grouping-visible.pipe';
import { IsOddSelectedPipe } from 'src/app/shared/pipes/is-odd-selected.pipe';
import { LastMinuteSortByPipe } from 'src/app/shared/pipes/last-minute-sort-by.pipe';
import { LiveOddsWidgetComponent } from 'src/app/shared/components/live/live-odds-widget/live-odds-widget.component';
import { LottieModule } from 'ngx-lottie';
import { ObjectKeysPipe } from 'src/app/shared/pipes/object-keys.pipe';
import { OddPipe } from 'src/app/shared/pipes/odd.pipe';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { PinToTopDirective } from 'src/app/shared/directives/pin-to-top.directive';
import { RemoveCommaPipe } from 'src/app/shared/pipes/remove-comma.pipe';
import { SafeHTML } from 'src/app/shared/pipes/safe-html.pipe';
import { SafeUrl } from 'src/app/shared/pipes/safe-url.pipe';
import { SingleLineEventLiveComponent } from 'src/app/shared/components/events/single-line-event-live/single-line-event-live.component';
import { TeamNameOrderPipe } from 'src/app/shared/pipes/team-name-order.pipe';
import { TitleCasePipe } from 'src/app/shared/pipes/title-case.pipe';
import { WeekDaysPipe } from 'src/app/shared/pipes/weekdays.pipe';
import { ToggleSwitchComponent } from 'src/app/shared/components/toggle-switch/toggle-switch.component';
import { LoginDialogComponent } from 'src/app/shared/components/login-dialog/login-dialog.component';
import { NavbarAccumulatorBonusProgressionBarComponent } from './components/nav-bar/nav-bar-acca-bonus-progression-bar/nav-bar-acca-bonus-progression-bar.component';

const DIRECTIVES = [
  AdjustContainerOnHorizontalScrollDirective,
  AdjustTabOnSelectedDirective,
  AutocompleteOTPInputFieldDirective,
  BadgeDirective,
  DropdownArrowRotatorDirective,
  DynamicSvgBackgroundDirective,
  DynamicSvgMaskDirective,
  HideIfEmptyDirective,
  LazyLoadDirective,
  NavBarSvgLoaderDirective,
  PaymentPopulatorDirective,
  PinToTopDirective,
  ResizeOnScrollDirective,
  ResizeOnScrollDirective,
  ScrollToTopPositionerDirective,
  ScrollToTopPositionerDirective,
  SelectedTabCenterizerDirective,
  VirtualsLoaderDirective,
  ZIndexDirective,
  MatchScrollDirective,
];

const EXPORTS = [
  AppMessageComponent,
  AppBannerComponent,
  BreadcrumbComponent,
  ButtonComponent,
  CardComponent,
  CashoutComponent,
  CorrectScoreEventComponent,
  CorrectScoresMarketOddsComponent,
  CouponCheckComponent,
  CouponEvaluationComponent,
  DropdownComponent,
  EventDetailsComponent,
  EventDetailsLiveComponent,
  EventHeaderLiveComponent,
  EventTournamentDetailsComponent,
  EventsMarketsHeaderComponent,
  EventsSummaryComponent,
  EventsSummaryLiveComponent,
  FavouriteToggleComponent,
  FooterSectionComponent,
  FooterSectionComponent,
  FormItemGroupComponent,
  FormMessageComponent,
  HeaderComponent,
  HelpItemsComponent,
  InfoModalComponent,
  InnerCardComponent,
  InsufficientBalanceComponent,
  LanguagePageComponent,
  LanguageSelectorComponent,
  LegendTableComponent,
  LiveOddComponent,
  LiveOddsComponent,
  LiveOddsWidgetComponent,
  LoadingBarComponent,
  LoadingBarOverlayComponent,
  LoadingCircleComponent,
  MenuHowToPlayComponent,
  MenuPageWrapperComponent,
  MenuPromosComponent,
  MenuQuicklinksComponent,
  MenuSportsComponent,
  MenuTabsComponent,
  MenuWrapperComponent,
  MobilePrefixComponent,
  MultiLineEventComponent,
  MultiLineMarketOddsComponent,
  NavBarComponent,
  NavBarItemsComponent,
  OTPInputFieldComponent,
  OTPScreenComponent,
  OddComponent,
  OddGroupComponent,
  OverlayDropdownComponent,
  PhoneUnverifiedTooltipComponent,
  PhoneVerificationComponent,
  PhoneVerificationPopupComponent,
  PlaceholderComponent,
  ProceedBarsContainerComponent,
  RebetComponent,
  RecentSearchesComponent,
  RegionAreaDropdownComponent,
  ScrollToTopComponent,
  ScrollToTopComponent,
  SfkChatComponent,
  SingleLineEventComponent,
  SingleLineEventLiveComponent,
  SingleLineMarketOddsComponent,
  SlidePanelComponent,
  SportLinkComponent,
  SportsListComponent,
  StatisticsComponent,
  ToastNotificationComponent,
  ToggleSwitchComponent,
  LoginDialogComponent,
  VirtualsOfferingsComponent,
  NotificationMessageComponent,
];

const COMPONENTS = [
  ...EXPORTS,
  NavBarAccaBonusUnacceptableSelectionsPromptComponent,
  NavBarBetslipSelectionsComponent,
  NavbarAccumulatorBonusProgressionBarComponent,
  ProceedBarComponent,
];

const PIPES = [
  BetFinalStateToUI,
  CorrectScoresMarketOddsComponent,
  CurrencyFormatPipe,
  DisplayScorePipe,
  EventDetailsComponent,
  EventTournamentDetailsComponent,
  FilterByTermAndPropertyPipe,
  FilterMatchesPipe,
  FilterSearchPipe,
  FormatOddPipe,
  GroupNamePipe,
  GroupOddsByMarketPipe,
  HasGroupingsPipe,
  Highlight,
  IncludesPipe,
  IsGroupingVisiblePipe,
  IsOddSelectedPipe,
  LastMinuteSortByPipe,
  ObjectKeysPipe,
  ObjectKeysPipe,
  OddChangedPipe,
  OddPipe,
  OrderByPipe,
  OrderByPipe,
  RemoveCommaPipe,
  SafeHTML,
  SafeUrl,
  SelectAllDirective,
  TeamNameOrderPipe,
  TitleCasePipe,
  UnselectAllDirective,
  WeekDaysPipe,
];

const MODULES = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, SweetAlert2Module, ScrollingModule, LottieModule];

@NgModule({
  imports: [...MODULES],
  exports: [...EXPORTS, ...DIRECTIVES, ...PIPES, ...MODULES],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class SharedModule {}
