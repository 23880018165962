export enum ButtonType {
  Primary,
  Secondary,
  Tertiary,
  Accent,
  AccentSecondary,
  Highlight,
  Success,
  Danger,
  DangerTransparent,
  Warn,
  Basic,
  Transparent,
  TransparentSecondary,
  WhiteText,
}

export class ButtonModel {
  text: string;
  faIcon: string;
  buttonType: ButtonType = ButtonType.Primary;
  type: string;
  loading: boolean;

  constructor(init: Partial<ButtonModel>) {
    Object.assign(this, init);
  }

  get hasIcon(): boolean {
    // icon identifier must start with 'fa-' to be valid
    return (this.faIcon && this.faIcon.startsWith('fa-')) || this.loading;
  }

  get icon(): string {
    return this.loading ? 'fa-spinner fa-pulse' : this.faIcon;
  }

  get buttonClass(): string {
    switch (this.buttonType) {
      case ButtonType.Success:
        return 'btn-success';
      case ButtonType.Danger:
        return 'btn-danger';
      case ButtonType.DangerTransparent:
        return 'btn-danger-transparent';
      case ButtonType.Warn:
        return 'btn-warning';
      case ButtonType.Accent:
        return 'btn-accent';
      case ButtonType.AccentSecondary:
        return 'btn-accent-secondary';
      case ButtonType.Secondary:
        return 'btn-secondary';
      case ButtonType.Tertiary:
        return 'btn-tertiary';
      case ButtonType.Highlight:
        return 'btn-highlight';
      case ButtonType.Basic:
        return 'btn-basic';
      case ButtonType.Transparent:
        return 'btn-transparent';
      case ButtonType.TransparentSecondary:
        return 'btn-transparent-secondary';
      case ButtonType.WhiteText:
        return 'btn-transparent-white-text';
      default:
        return 'btn-primary';
    }
  }
}
