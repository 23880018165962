import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { VirtualsState } from 'src/app/shared/models/virtuals.model';

function createInitialState(): VirtualsState {
  return {
    menuSections: undefined,
    groupEntityId: undefined,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'virtuals' })
export class VirtualsStore extends Store<VirtualsState> {
  constructor() {
    super(createInitialState());
  }

  updateMenuSections(menuSections: Partial<VirtualsState['menuSections']>): void {
    this.update(state => ({
      ...state,
      menuSections: menuSections,
    }));
  }

  updateGroupEntityId(groupEntityId: number): void {
    this.update({ groupEntityId });
  }

  clearGroupEntityId(): void {
    this.update({ groupEntityId: undefined });
  }
}
