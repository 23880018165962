import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BetCoupon,
  BetCouponGlobalVariable,
  BetCouponGroup,
  BetCouponOdd,
  Bonus,
  ClientsideCouponService,
  CouponAction,
  Dictionary,
  Selection,
  UpdateCouponRequest,
  UpdateCouponResponse,
} from 'clientside-coupon';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, finalize, first, map } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  BookedCoupon,
  CouponGroupingType,
  CouponOddsModel,
  CouponSettings,
  CouponUIState,
  DefaultCouponStake,
  ExpiredEvents,
  ExpiredEventsModel,
  OddChanges,
  OddModel,
} from 'src/app/shared/models/coupon.model';
import { APIService } from 'src/app/core/services/api.service';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { LiveQuery } from 'src/app/core/state/live/live.query';
import { LiveStore } from 'src/app/core/state/live/live.store';

@Injectable({
  providedIn: 'root',
})
export class CouponService implements OnDestroy {
  loading: boolean = false;
  enforceSingleCombination: boolean = false;
  allowCompleteDeselectOfEventOdds: boolean = true;

  readonly insertCouponStatusCodes: any = {
    0: $localize`An error has occurred, please try again`,
    1: $localize`Bet was successful`,
    2: $localize`At this time you cannot place bets. Please contact customer support for more information`,
    3: $localize`One of the chosen events has expired`,
    4: $localize`One of the odds has changed.`,
    5: $localize`Wrong Password`,
    6: $localize`Username Equals Agent name`,
    7: $localize`Timeout For Coupon Cancellation`,
    8: $localize`Wrong User Network`,
    9: $localize`Wrong User Information`,
    10: $localize`Username Already Exists`,
    11: $localize`Account Insert Data Error`,
    12: $localize`Error Inserting Contact User`,
    13: $localize`Error Inserting Parameters User`,
    14: $localize`Error Updating User`,
    15: $localize`Wrong Currency`,
    16: $localize`Bet not successful: Insufficient account balance. Deposit now to complete your bet`,
    17: $localize`Bet Not Paid By System`,
    18: $localize`Agent Bettor Mismatch`,
    19: $localize`Bet Already Paid`,
    20: $localize`Max Number Of Coupons Cancelled`,
    21: $localize`Bet Lost or Open`,
    22: $localize`Banker Not Allowed On Integrals`,
    23: $localize`Email Already Exist`,
    24: $localize`MinLimit By Player Reached`,
    25: $localize`MaxLimit By Player Reached`,
    26: $localize`Single Bet Max Limit By User`,
    27: $localize`Multiple Bet Max Limit By User`,
    28: $localize`Combination Bet Max Limit By User`,
    29: $localize`Concurrency Issue`,
    30: $localize`Team Already Exists`,
    31: $localize`Error Inserting User Risk Settings`,
    32: $localize`Results Already Exists`,
    33: $localize`Error Inserting User Limits`,
    34: $localize`Missing Mappings`,
    35: $localize`Stake Too High To Cancel`,
    36: $localize`Operation Already Done`,
    37: $localize`Cannot Cancel Live Bet`,
    38: $localize`Coupon Already Paid`,
    39: $localize`Max Coupon Loss Limit Exceeded`,
    40: $localize`Incorrect number of events`,
    41: $localize`Role Already Exists`,
    43: $localize`Operator Already Exists`,
    44: $localize`Country Mismatch`,
    45: $localize`Over Max Win Threshold`,
    46: $localize`Has To Change Password`,
    49: $localize`Maximum combinability exceeded`,
    50: $localize`Cannot Cancel Has Sub Events`,
    51: $localize`Cannot Cancel Has Markets`,
    52: $localize`Maximum stake allowed exceeded`,
    53: $localize`Maximum winning allowed exceeded`,
    54: $localize`Stake is lower than amount allowed`,
    55: $localize`Stake for each group is lower than amount allowed`,
    69: $localize`The maximum number of events allowed has been exceeded`,
    70: $localize`Incompatible events in the coupon`,
    200: $localize`Record Not Found`,
    201: $localize`Operation Not Allowed`,
  };

  readonly updateCouponStatusCodes: any = {
    0: $localize`Success`,
    1: $localize`Operation Not Allowed`,
    2: $localize`Selection has already been added`,
    3: $localize`OutrightError`,
    4: $localize`Cannot add a selection with a unique event`,
    5: $localize`Cannot add a unique event`,
    6: $localize`Max number of selections reached`,
    7: $localize`Cannot add more than 30 events`,
    8: $localize`Banker not allow in cross combination`,
    9: $localize`Cannot insert cross combination on live`,
    10: $localize`Banker not allowed on live`,
    11: $localize`Related events are incompatible`,
    12: $localize`Minimum combinability not reached`,
    13: $localize`Odd Does Not Exist`,
    14: $localize`Could not remove odd`,
    15: $localize`Could not remove grouping`,
    16: $localize`Wrong game play. Requires more events.`,
    17: $localize`One of the odds has changed`,
    18: $localize`One of the chosen events has expired`,
    19: $localize`Stake under minimum amount allowed`,
    20: $localize`Stake over maximum amount allowed`,
    21: $localize`Over maximum winning amount`,
    22: $localize`Group stake under minimum amount allowed`,
    23: $localize`Group stake over maximum amount allowed`,
    405: $localize`Booking code is no longer available. All events are expired.`,
  };
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly couponQuery: CouponQuery,
    private readonly couponStore: CouponStore,
    private readonly currencyFormat: CurrencyFormatPipe,
    private readonly dataLayerService: DataLayerService,
    private readonly evaluationService: EvaluationService,
    private readonly loadingService: LoadingService,
    private readonly myBetsService: MyBetsService,
    private readonly notificationService: NotificationService,
    private readonly liveQuery: LiveQuery,
    private readonly liveStore: LiveStore
  ) {
    this.couponQuery.couponSettings$.subscribe(sub => {
      this.couponStore.updateCouponSettings(sub);
    });
  }

  get currencySymbol(): string {
    return this.accountQuery.userData && this.accountQuery.userData.currency.symbol
      ? this.accountQuery.userData.currency.symbol
      : this.appConfig.get('sports').coupon.defaultCurrency;
  }

  initialize(): void {
    this.populateSportsbookVariables()
      .pipe(first())
      .subscribe(() => {
        this.couponStore.updateCouponInitialized(true);
      });
  }

  rebetCoupon(couponCode: string, language: string = 'en'): Observable<any> {
    return this.apiService.get(APIType.Sportsbook, `api/coupons/rebet/byCode/${couponCode}/language/${language}`).pipe(
      map(data => {
        if (data.BetCoupon) {
          this.updateCoupon(data.BetCoupon);
          this.applicationService.showCoupon();
          return data;
        } else {
          return false;
        }
      })
    );
  }

  isOddInCoupon(oddId: number): boolean {
    if (this.couponQuery.couponData === undefined || this.couponQuery.couponData.Odds === undefined) {
      return false;
    }
    return this.couponQuery.couponData.Odds.findIndex(o => o.SelectionId === oddId) > -1;
  }

  addOdd(odd: OddModel, language: string = 'en'): UpdateCouponResponse {
    if (odd.eventCategory && odd.eventCategory.toUpperCase() === 'L' && this.appConfig.get('live').useServerSideCoupon) {
      this.addOddServerSide(odd, language).subscribe();
      return new UpdateCouponResponse({});
    } else {
      return this.addOddClientSide(odd);
    }
  }

  addToOddChanges(oddId: number, oddValue: number): void {
    this.couponStore.addToOddChanges(oddId, oddValue);
  }

  removeOdd(oddId: number, marketId?: number): UpdateCouponResponse {
    let proceedWithRemoval = true;
    if (!this.allowCompleteDeselectOfEventOdds) {
      // See whether this market has any selections left in the coupon
      proceedWithRemoval = this.couponQuery.couponData.Odds.filter(o => o.MarketId === marketId).length > 1;
    }

    if (proceedWithRemoval) {
      this.couponStore.clearGroupingTab();

      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: this.couponQuery.couponData,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection,
        })
      );

      this.couponStore.updateCouponData(response.updatedCoupon);

      this.removeFromOddChanges(oddId);
      this.liveStore.removeLiveAreaSelectionIds(oddId);

      this.applicationService.showQuickCoupon(false);

      return response;
    } else {
      return undefined;
    }
  }

  removeOdds(oddIds: number[]): UpdateCouponResponse {
    let updatedCoupon: BetCoupon = this.couponQuery.couponData;
    let allOddsRemoved: boolean = true;

    this.couponStore.clearGroupingTab();

    oddIds.forEach(oddId => {
      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: updatedCoupon,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection,
        })
      );

      updatedCoupon = response.updatedCoupon;
      if (response.success) {
        this.removeFromOddChanges(oddId);
        this.liveStore.removeLiveAreaSelectionIds(oddId);
      } else {
        allOddsRemoved = false;
      }
    });

    this.couponStore.updateCouponData(updatedCoupon);
    this.applicationService.showQuickCoupon(false);

    return new UpdateCouponResponse({
      success: allOddsRemoved,
      updatedCoupon: updatedCoupon,
    });
  }

  removeFromOddChanges(oddId: number): void {
    this.couponStore.removeFromOddChanges(oddId);
  }

  updateOddBankerStatus(oddId: number, isBanker: boolean): UpdateCouponResponse {
    const selection = new Selection();
    selection.oddId = oddId;

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateOddBankerStatus,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: selection,
        isBanker: isBanker,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  clearAllBankers(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ClearAllBankers,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateStakeValue(stakeValue: number): UpdateCouponResponse {
    if (stakeValue >= 0) {
      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.UpdateStakeValue,
          brandID: this.appConfig.get('brandId'),
          coupon: this.couponQuery.couponData,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          stakeValue: stakeValue,
        })
      );

      this.couponStore.updateCouponData(response.updatedCoupon);

      return response;
    }
  }

  updateGroupings(groupings: BetCouponGroup[]): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupings,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        groupings: groupings,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateGroupingStakeValue(grouping: BetCouponGroup): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupingStakeValue,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        groupings: [grouping],
        groupingStakeValue: grouping.Stake,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  isGroupingVisible(grouping: BetCouponGroup, isLast: boolean): boolean {
    const groupingsTabSelected = this.couponQuery.groupingsTabSelected;

    if (groupingsTabSelected === CouponGroupingType.Split && isLast) {
      return true;
    } else if (groupingsTabSelected === CouponGroupingType.Combination) {
      if (!isLast && grouping.Grouping !== 1) {
        return true;
      }
    } else if (groupingsTabSelected === CouponGroupingType.Singles && grouping.Grouping === 1) {
      return true;
    }

    return false;
  }

  validateCoupon(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );

    return response;
  }

  validateAndPostCoupon(): Observable<boolean> {
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const validation = this.validateCoupon();

    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postCoupon();
    }
  }

  validateAndPostBookCoupon(): Observable<any> {
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const bookedCouponCount = this.couponQuery.bookedCoupons.length;
    const maxNumberOfBookedBets = 20;

    if (bookedCouponCount >= maxNumberOfBookedBets) {
      this.notificationService.showErrorNotification(
        $localize`Maximum number of booked bets reached. Please remove previously booked bets.`
      );
      return of(false);
    }

    const validation = this.validateCoupon();
    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postBookCoupon();
    }
  }

  updateUI(ui: CouponUIState): void {
    this.couponStore.update({ ui });
  }

  updateCouponSetting(couponSettingKey: string, couponSetting: any): void {
    const setting = new CouponSettings({});
    setting[couponSettingKey] = couponSetting;
    this.couponStore.updateCouponSetting(setting);
  }

  clearCouponData(): void {
    this.couponStore.clearCouponData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  removeBookedCoupon(couponCode: string): void {
    this.couponStore.removeBookedCoupon(couponCode);
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    this.couponStore.updateDefaultCouponStake(defaultCouponStake);
  }

  getStatus(statusCode: number): string {
    return this.getInsertCouponStatus(statusCode);
  }

  updateCoupon(couponData: any): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateExpiredEvents(expiredEvents: ExpiredEventsModel): void {
    this.couponStore.updateExpiredEvents(expiredEvents);
  }

  clearExpiredEvents(): void {
    this.couponStore.clearExpiredEvents();
  }

  getOddsChanged(couponOdds: CouponOddsModel[], language: string = 'en'): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const bodyData = couponOdds;

    return this.apiService.post<any>(APIType.Sportsbook, `api/feeds/oddschanged/${language}`, bodyData, apiSettings).pipe(
      map(responseData => {
        if (!responseData || responseData.length === 0) {
          return;
        }
        const couponDataCopy: BetCoupon = cloneDeep(this.couponQuery.couponData);

        responseData.forEach(changedOdd => {
          const couponOdd = couponDataCopy.Odds.find(o => o.SelectionId === changedOdd.SelectionID);

          if (couponOdd) {
            couponOdd.OddValue = changedOdd.SelectionValue;
            couponOdd.ConfirmedOddValue = changedOdd.SelectionValue;
            couponOdd.IsLocked = changedOdd.IsLocked;
            couponOdd.IsExpired = changedOdd.IsExpired;
          }

          this.couponStore.updateOddChange(changedOdd.SelectionID, changedOdd.SelectionValue);
        });
        this.couponStore.updateCouponData(couponDataCopy);

        // TODO:this has to be changed with a new clientside method.
        this.updateStakeValue(this.couponQuery.couponData.StakeGross);
      })
    );
  }

  acceptOddChanges(): void {
    const oddChangesCopy: OddChanges[] = cloneDeep(this.couponQuery.oddChanges);

    oddChangesCopy.forEach(o => {
      o.initialOddValue = o.latestOddValue;
      o.valueChanged = false;
    });

    this.couponStore.updateOddChanges(oddChangesCopy);
  }

  getInsertCouponStatus(statusCode: number): string {
    return this.insertCouponStatusCodes[statusCode];
  }

  getUpdateCouponStatus(statusCode: number): string {
    return statusCode === 21 ? $localize`Over maximum winning amount` : this.updateCouponStatusCodes[statusCode];
  }

  checkCouponStake(): boolean {
    if (this.couponQuery.couponData.Stake < this.couponQuery.globalVariables.MinBetStake) {
      this.notificationService.showErrorNotification($localize`${this.updateCouponStatusCodes[19]} of ${this.getFormattedMinStake()}`);
      return false;
    }

    if (this.couponQuery.couponData.Stake > this.couponQuery.globalVariables.MaxBetStake) {
      this.notificationService.showErrorNotification($localize`${this.updateCouponStatusCodes[19]} of ${this.getFormattedMinStake()}`);
      return false;
    }

    return true;
  }

  showExpiredNotification(): void {
    let expiredMessage;
    const numOfExpiredEvents = this.couponQuery.expiredEvents.originalEventCount - this.couponQuery.expiredEvents.availableEventCount;
    if (numOfExpiredEvents === 1) {
      expiredMessage = $localize`1 selection has expired. The selection has been removed from the Betslip.`;
    } else if (numOfExpiredEvents > 1) {
      const numOfExpired = this.couponQuery.expiredEvents.originalEventCount - this.couponQuery.expiredEvents.availableEventCount;
      expiredMessage = $localize`${numOfExpired} selections have expired and we have recalculated your returns accordingly.`;
    }

    this.notificationService.showWarningMessage(expiredMessage, 5000, false);
  }

  createCouponFromSelectionIds(selectionIds: { SelectionId: number }[], stake: number, language: string = 'en'): Observable<void> {
    this.loadingService.enqueueLoader();
    return this.apiService
      .post(
        APIType.Sportsbook,
        `api/coupons/create/language/${language}`,
        stake
          ? {
              Selections: selectionIds,
              Stake: stake,
            }
          : {
              Selections: selectionIds,
            },
        new APISettings({
          noAuthToken: true,
        })
      )
      .pipe(
        map(responseData => {
          if (!responseData || responseData.length === 0 || !responseData.BetCoupon) {
            return;
          }

          this.couponStore.clearCouponData();

          const betCoupon = this.clientsideCouponService.formatCoupon(responseData.BetCoupon);
          this.couponStore.updateCouponData(betCoupon);
          betCoupon.Odds.forEach((odd: BetCouponOdd) => {
            this.couponStore.addToOddChanges(odd.SelectionId, odd.OddValue);
          });
        }),
        finalize(() => {
          this.loadingService.dequeueLoader();
        })
      );
  }

  updatePreviousPage(path: string): void {
    this.couponStore.updatePreviousPage(path);
  }

  private addOddClientSide(odd: OddModel): UpdateCouponResponse {
    this.couponStore.clearGroupingTab();

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.AddOdd,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: odd.toSelection(),
        allowSameMatchSelections: this.appConfig.get('sports').allowSameMatchSelections,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);

    if (!response.success) {
      this.handleErrorMessage(response.statusCode);
    }

    const newOdd = response.updatedCoupon.Odds.find(o => o.SelectionId === odd.id);
    if (newOdd) {
      this.addToOddChanges(odd.id, newOdd.OddValue);
    }

    if (response.updatedCoupon.Odds.length === 1) {
      this.applicationService.showQuickCoupon(true);
    } else {
      this.applicationService.showQuickCoupon(false);
    }

    return response;
  }

  private addOddServerSide(odd: OddModel, language: string = 'en'): Observable<UpdateCouponResponse> {
    // If allowSameMatchSelections is set to false and there is already an odd with the same
    // match of the new odd, remove the previous odd before calling the server
    if (!this.appConfig.get('sports').allowSameMatchSelections) {
      const prevSelection = this.couponQuery.getSameMatchSelection(odd.matchId);
      if (prevSelection) {
        this.removeOdd(prevSelection.SelectionId);
      }
    }

    const couponEmpty = this.couponQuery.couponData === undefined;
    const url = `api/LiveCoupons/${couponEmpty ? 'AddSelections' : 'AddSelectionsToCoupon'}/${language}?selectionIds[0]=${odd.id}`;
    const bodyData = this.couponQuery.couponData || {};
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });

    return this.apiService.post<any>(APIType.Sportsbook, url, bodyData, apiSettings).pipe(
      map(responseData => {
        if (!responseData.BetCoupon) {
          return new UpdateCouponResponse({
            success: false,
            statusCode: responseData.ResponseStatus,
          });
        }

        responseData.BetCoupon.MaxPercentageBonus = isNaN(responseData.BetCoupon.MaxBonusPerc) ? 0 : responseData.BetCoupon.MaxBonusPerc;
        responseData.BetCoupon.MinPercentageBonus = isNaN(responseData.BetCoupon.MinBonusPerc) ? 0 : responseData.BetCoupon.MinBonusPerc;
        delete responseData.BetCoupon.MaxBonusPerc;
        delete responseData.BetCoupon.MinBonusPerc;

        const updatedCoupon = this.clientsideCouponService.formatCoupon(responseData.BetCoupon);
        this.couponStore.updateCouponData(updatedCoupon);
        const newOdd = updatedCoupon.Odds.find(o => o.SelectionId === odd.id);
        if (newOdd) {
          this.addToOddChanges(odd.id, newOdd.OddValue);
        }

        const selectedAreaInMatchView = this.liveQuery.selectedAreaInMatchView;
        if (selectedAreaInMatchView) {
          this.liveStore.updateLiveAreaSelectionIds({ [odd.id]: selectedAreaInMatchView.id });
        }

        if (updatedCoupon.Odds.length === 1) {
          this.applicationService.showQuickCoupon(true);
        } else {
          this.applicationService.showQuickCoupon(false);
        }

        return new UpdateCouponResponse({
          success: true,
          updatedCoupon: updatedCoupon,
          statusCode: responseData.ResponseStatus,
        });
      })
    );
  }

  private postCoupon(): Observable<boolean> {
    this.loading = true;
    return this.syncCouponToServer().pipe(
      concatMap(syncResponse => {
        if (!syncResponse.success) {
          this.loading = false;
          this.handleErrorMessage(syncResponse.statusCode);
          return of(false);
        }

        return this.insertCoupon().pipe(
          map(insertResponse => {
            this.loading = false;
            if (!insertResponse.success) {
              this.notificationService.showErrorNotification(
                this.getInsertCouponStatus(insertResponse.statusCode),
                $localize`Coupon Not Posted`
              );

              return false;
            }

            const betResponseStatus = insertResponse.statusCode;
            const coupon = insertResponse.updatedBetCoupon;

            if (betResponseStatus === 1) {
              // Coupon saved successfully.
              const inEvaluation = coupon !== undefined && coupon.CurrentEvalReason !== 0;
              if (inEvaluation) {
                coupon.CouponId = insertResponse.couponId;
                coupon.CouponCode = insertResponse.couponCode;
                coupon.CouponDate = insertResponse.couponDate;

                this.evaluationService.addToEvaluation(coupon);
                this.evaluationService.runEvaluation().subscribe();

                this.notificationService.showInfoNotification(
                  $localize`Coupon is being evaluated by operator`,
                  $localize`Coupon in Evaluation`
                );
              } else {
                this.accountService.updateBalance();
                this.myBetsService.addNewBet(insertResponse.couponCode);
                this.dataLayerService.createDataLayerEvent({
                  event: 'btk.betPlaced',
                  betPlaced: insertResponse.updatedBetCoupon.StakeGross,
                });
                this.dataLayerService.createDataLayerEvent({
                  event: 'user-place-bet',
                  userId: this.accountQuery.userData.id,
                  couponID: insertResponse.couponCode,
                  totalStake: insertResponse.updatedBetCoupon.StakeGross,
                });

                this.couponStore.update({ lastPlacedCouponCode: insertResponse.couponCode });
              }
              this.couponStore.update({ isLastPlacedCouponInEvaluation: inEvaluation });
            } else if (betResponseStatus === 3) {
              // One of the chosen events has expired.
              if (coupon !== undefined) {
                this.updateCoupon(coupon);
              }
              this.notificationService.showErrorNotification(this.getStatus(betResponseStatus), $localize`Coupon Not Posted`);
            } else if (betResponseStatus === 4) {
              // One of the odds has changed.
              if (coupon !== undefined) {
                this.updateCoupon(coupon);
              }

              this.notificationService.showErrorNotification(this.getStatus(betResponseStatus), $localize`Coupon Not Posted`);
            } else {
              if (coupon !== undefined) {
                this.updateCoupon(coupon);
              }
              this.notificationService.showErrorNotification(this.getStatus(betResponseStatus), $localize`Coupon Not Posted`);
            }
            return true;
          })
        );
      })
    );
  }

  private postBookCoupon(): Observable<any> {
    this.loading = true;

    return this.bookCoupon().pipe(
      map(bookResponse => {
        this.loading = false;
        if (!bookResponse.success) {
          this.notificationService.showErrorNotification(this.getInsertCouponStatus(bookResponse.statusCode), $localize`Coupon Not Booked`);
          return false;
        }

        const bookedCoupon = new BookedCoupon({
          couponCode: bookResponse.couponCode,
          date: format(new Date(), 'dd/MM/yy'),
        });

        this.couponStore.updateBookedCoupon(bookedCoupon);
        return bookedCoupon;
      })
    );
  }

  private handleErrorMessage(statusCode: number): void {
    const errorMessage = this.getUpdateCouponStatus(statusCode);

    // Show error message according to the status code returned
    if (statusCode === 18) {
      // 18 => 'One of the chosen events has expired'. In that case we include the number of expired events
      this.showExpiredNotification();
    } else if (statusCode === 19) {
      // 19 => 'Stake under minimum amount allowed'. In that case we include the minimum stake amount
      this.notificationService.showErrorNotification(`${errorMessage} of ${this.getFormattedMinStake()}`, $localize`Coupon Error`);
    } else if (statusCode === 22) {
      // 22 => 'Group stake under minimum amount allowed'. In that case we include the minimum group stake amount
      const minMessage = $localize`The minimum acceptable total stake is ${this.getFormattedTotalStake()}.`;
      this.notificationService.showErrorNotification(
        `${errorMessage} of ${this.getFormattedMinGroupStake()}. ${minMessage}`,
        $localize`Coupon Error`
      );
    } else {
      this.notificationService.showErrorNotification(
        errorMessage,
        statusCode === 21 ? $localize`Maximum Win Exceeded` : $localize`Coupon Error`
      );
    }
  }

  private populateSportsbookVariables(): Observable<void> {
    const apiCalls = [
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/bonuslist`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/globalvariables`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/incompatiblemarketexceptions`),
    ];
    const correctScoreOddsMatrix = this.couponQuery.correctScoreOddsMatrix;
    if (
      !correctScoreOddsMatrix ||
      correctScoreOddsMatrix.cacheVersion !== this.appConfig.get('siteVersion') ||
      correctScoreOddsMatrix.data === undefined
    ) {
      apiCalls.push(this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/selectionCompatibilityMatrix`));
    }

    return forkJoin(apiCalls).pipe(
      map(([bonusListData, globalVariablesData, marketExceptionsData, correctScoreOddsMatrixData]) => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }

        if (globalVariablesData !== undefined) {
          const globalVariables: BetCouponGlobalVariable = globalVariablesData;
          this.couponStore.updateGlobalVariables(globalVariables);
        }

        if (marketExceptionsData !== undefined) {
          const marketExceptions: Dictionary<number, number[]> = marketExceptionsData;
          this.couponStore.updateMarketExceptions(marketExceptions);
        }

        if (correctScoreOddsMatrixData !== undefined) {
          this.couponStore.updateCorrectScoreOddsMatrix({
            cacheVersion: this.appConfig.get('siteVersion'),
            data: correctScoreOddsMatrixData,
          });
        }
      })
    );
  }

  private syncCouponToServer(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    return this.apiService.put<any>(APIType.Sportsbook, `api/BetCoupons/UpdateCoupon`, this.couponQuery.couponData, apiSettings).pipe(
      map(response => {
        let couponSuccess = false;
        if (response.ResponseStatus === 0) {
          // BTK-1299: The following line has been temporarily commented out because the response returned by the api
          // doesn't yet match our model structure, resulting in lost/misplaced data
          // this.setCouponData(this.clientsideCouponService.formatCoupon(response.BetCoupon));

          // BTK-1419: Temporarily creating a copy of the OddValue field in a new field called ConfirmedOddValue.
          // This is usually done by the api but for now we have to do it manually due to the above commented out line
          const couponData = cloneDeep(this.couponQuery.couponData);
          couponData.Odds.forEach(odd => {
            odd.ConfirmedOddValue = odd.OddValue;
          });
          this.couponStore.updateCouponData(couponData);

          couponSuccess = true;
        } else if (response.ResponseStatus === 17) {
          // One of the odds has changed.
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          if (this.couponQuery.couponSettings.allowOddChanges) {
            couponSuccess = true;
          }
        } else if (response.ResponseStatus === 18) {
          // One of the chosen events has expired
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          if (response.ExpiredEvents !== undefined && response.ExpiredEvents.length > 0) {
            const expiredEventsModel = new ExpiredEventsModel({
              availableEventCount: response.AvailableEventCount,
              originalEventCount: response.OriginalEventCount,
              expiredEvents: [],
            });

            response.ExpiredEvents.forEach(event => {
              const expiredEvents = new ExpiredEvents({
                eventId: event.IDEvent,
                smartBetCode: event.SmartCode,
                eventName: event.EventName,
                eventDate: event.EventDate,
                eventCategory: event.EventCategory,
                marketName: event.MarketName,
                selectionName: event.SelectionName,
                oddValue: event.OddValue,
              });

              expiredEventsModel.expiredEvents.push(expiredEvents);
            });

            this.updateExpiredEvents(expiredEventsModel);
          }
        } else {
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }
        }

        return {
          success: couponSuccess,
          statusCode: response.ResponseStatus,
        };
      })
    );
  }

  private insertCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    const bodyData = this.appConfig.get('sports').coupon.sendBookedCouponCode
      ? {
          AllowOddChanges: this.couponQuery.couponSettings.allowOddChanges,
          AllowStakeReduction: this.couponQuery.couponSettings.allowStakeReduction,
          // Sportbook requested that a null value is sent - [SB-6184]
          BookedCouponCode: this.couponQuery.couponSettings.bookedCouponCode ? this.couponQuery.couponSettings.bookedCouponCode : null,
          BetCoupon: {
            ...this.couponQuery.couponData,
          },
          RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
          TransferStakeFromAgent: this.couponQuery.couponSettings.transferUserId === null ? false : true,
        }
      : {
          AllowOddChanges: this.couponQuery.couponSettings.allowOddChanges,
          AllowStakeReduction: this.couponQuery.couponSettings.allowStakeReduction,
          BetCoupon: {
            ...this.couponQuery.couponData,
          },
          RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
          TransferStakeFromAgent: this.couponQuery.couponSettings.transferUserId === null ? false : true,
        };

    return this.apiService.post<any>(APIType.Sportsbook, `api/coupons/InsertCoupon`, bodyData, apiSettings).pipe(
      map(responseData => {
        let couponPosted = false;
        if (responseData.ResponseStatus === 1) {
          couponPosted = true;
        }

        return {
          success: couponPosted,
          statusCode: responseData.ResponseStatus,
          couponId: responseData.CouponId,
          couponCode: responseData.CouponCode,
          couponDate: responseData.CouponDate,
          updatedBetCoupon: responseData.UpdatedBetCoupon,
        };
      }),
      catchError(() =>
        of({
          success: false,
        })
      )
    );
  }

  private bookCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    const requestTransactionId = this.clientsideCouponService.generateTransactionId(); // TODO: don't regenerate every time
    const bodyData = this.couponQuery.couponData;

    return this.apiService.post<any>(APIType.Sportsbook, `api/BetCoupons/Book/${requestTransactionId}`, bodyData, apiSettings).pipe(
      map(responseData => {
        let couponBooked = false;
        if (responseData.ResponseStatus === 1) {
          couponBooked = true;
        }

        return {
          success: couponBooked,
          statusCode: responseData.ResponseStatus,
          couponCode: responseData.BookedCouponCode,
        };
      })
    );
  }

  private getFormattedMinStake(): string {
    return this.currencyFormat.transform(this.couponQuery.globalVariables.MinBetStake, '1.0-0');
  }

  private getFormattedMinGroupStake(): string {
    return this.currencyFormat.transform(this.couponQuery.globalVariables.MinGroupingsBetStake, '1.0-0');
  }

  private getFormattedTotalStake(): string {
    let totalCombinations = 0;

    this.couponQuery.couponData.Groupings.forEach(group => {
      totalCombinations += group.Combinations;
    });

    return this.currencyFormat.transform(this.couponQuery.globalVariables.MinGroupingsBetStake * totalCombinations);
  }
}
