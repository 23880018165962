import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductType } from 'src/app/shared/models/product.model';

import { MyBetsPageWrapperComponent } from './components/my-bets-page-wrapper/my-bets-page-wrapper.component';

const routes: Routes = [
  {
    path: 'sports',
    component: MyBetsPageWrapperComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.SportsBook },
  },
  {
    path: 'virtuals',
    component: MyBetsPageWrapperComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.Virtuals },
  },
  {
    path: 'jackpotbets',
    component: MyBetsPageWrapperComponent,
    pathMatch: 'full',
    data: { inMyBets: true, inCoupon: false, product: ProductType.JackpotBets },
  },
  { path: '**', redirectTo: 'sports' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyBetsRoutingModule {}
