<div class="proceed-bar-wrapper" [@slideUp] (click)="depth === 3 ? filter() : proceed()">
  <div class="left-elements">
    <div class="label" i18n>
      Selections:
    </div>
    <div class="value" *ngIf="(sportQuery.selectedQuicklink$ | async) as selectedQuicklink">
      <span *ngIf="selectedQuicklink.id === 2 && depth === 1">
        {{(sportQuery.competitionsAZSelectedElements$ | async) ? (sportQuery.competitionsAZSelectedElements$ | async).length : 0}}
      </span>

      <span *ngIf="selectedQuicklink.id === 0 && depth === 1">
        {{(sportQuery.topCompetitionsSelectedElements$ | async) ? (sportQuery.topCompetitionsSelectedElements$ | async).length : 0}}
      </span>

      <span *ngIf="selectedQuicklink.id === 4 && depth === 1">
        {{(sportQuery.allCompetitionByCountrySelectedElements$ | async) ? (sportQuery.allCompetitionByCountrySelectedElements$ | async).length : 0}}
      </span>
      <span *ngIf="selectedQuicklink.id === 4  && depth === 2">
        {{(sportQuery.allCompetitionByCountrySelectedElementsDepth2$ | async) ? (sportQuery.allCompetitionByCountrySelectedElementsDepth2$ | async).length : 0}}
      </span>

      <span *ngIf="selectedQuicklink.id === 5 && depth === 1">
        {{(sportQuery.outrightsSelectedElements$ | async) ? (sportQuery.outrightsSelectedElements$ | async).length : 0}}
      </span>
      <span *ngIf="selectedQuicklink.id === 5 && depth === 2">
        {{(sportQuery.outrightsSelectedElementsDepth2$ | async) ? (sportQuery.outrightsSelectedElementsDepth2$ | async).length : 0}}
      </span>

      <span *ngIf="(selectedQuicklink.id === 6) && depth === 1">
        {{(sportQuery.specialSportsSelectedElements$ | async) ? (sportQuery.specialSportsSelectedElements$ | async).length : 0}}
      </span>
      <span *ngIf="(selectedQuicklink.id === 6) && depth === 2">
        {{(sportQuery.specialSportsSelectedElementsDepth2$ | async) ? (sportQuery.specialSportsSelectedElementsDepth2$ | async).length : 0}}
      </span>

      <span *ngIf="(selectedQuicklink.id === 7) && depth === 1">
        {{(sportQuery.goalscorerSportSelectedElements$ | async) ? (sportQuery.goalscorerSportSelectedElements$ | async).length : 0}}
      </span>
      <span *ngIf="(selectedQuicklink.id === 7) && depth === 2">
        {{(sportQuery.goalscorerSportSelectedElementsDepth2$ | async) ? (sportQuery.goalscorerSportSelectedElementsDepth2$ | async).length : 0}}
      </span>

      <span *ngIf="(selectedQuicklink.id === 8) && depth === 1">
        {{(sportQuery.oddsBoostSportSelectedElements$ | async) ? (sportQuery.oddsBoostSportSelectedElements$ | async).length : 0}}
      </span>
      <span *ngIf="(selectedQuicklink.id === 8) && depth === 2">
        {{(sportQuery.oddsBoostSelectedElementsDepth2$ | async) ? (sportQuery.oddsBoostSelectedElementsDepth2$ | async).length : 0}}
      </span>

    </div>
    <div class="value" *ngIf="depth === 3">
      <span>
        {{(sportQuery.selectedPrematchSelectedElementCount$ | async) || (sportQuery.selectedPrematchPlayersDataSelectedElementCount$ | async)}}
      </span>
    </div>
  </div>
  <div class="right-elements">
    <div class="label" i18n>
      Next
    </div>
    <i class="fa fa-caret-right" aria-hidden="true"></i>
  </div>

</div>
