import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { RecentBetsStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { BetLiveDetailsModel, RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsQuery } from 'src/app/modules/my-bets/state/my-bets.query';
import { expandCollapse } from 'src/app/shared/animations';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';

@Component({
  selector: 'my-bets-recent-bet',
  templateUrl: './recent-bet.component.html',
  styleUrls: ['./recent-bet.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentBetComponent implements OnInit {
  @Input() item: RecentBetModel;
  @Input() filterBets: string = '';
  @Input() isRebetEnabled = false;
  @Input() loadCollapsed = true;
  @Input() liveDetails: BetLiveDetailsModel[] = [];

  @Output() readonly getBetDetails = new EventEmitter();
  @Output() readonly toggleBetInfo = new EventEmitter();
  @Output() readonly navigateToBetDetails = new EventEmitter();

  isCollapsed = true;
  isBetInfoCollapsed = true;
  taxPercentageToShow = this.appConfigService.get('sports').coupon.stakeTaxPercentageToShow;

  readonly betFinalState = BetFinalState;
  readonly recentBetsStatus = RecentBetsStatus;

  private readonly detailsRetrieved = false;
  private readonly betInfoRetrieved = false;

  constructor(
    private readonly router: Router,
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    readonly appConfigService: AppConfigService,
    readonly applicationQuery: ApplicationQuery,
    readonly applicationService: ApplicationService,
    readonly myBetsQuery: MyBetsQuery
  ) {}

  ngOnInit(): void {
    this.isCollapsed = this.loadCollapsed;
    this.isBetInfoCollapsed = this.loadCollapsed;

    if (!this.loadCollapsed) {
      // Loading bet in an open state, so we need to immediately get the details
      this.getBetDetails.emit(this.item);
    }
  }

  redirectToLiveEvent(eventId: number): void {
    if (eventId) {
      this.router.navigate([`/live/${eventId}`]);
    }
  }

  getBetDetailsCall(): void {
    this.isCollapsed = !this.isCollapsed;
    if (!(this.isCollapsed && this.detailsRetrieved)) {
      // Bet details are open and have not yet been retrieved, so get details
      this.getBetDetails.emit(this.item);
    }
  }

  parseSelectionName(selectionName, homeTeam, awayTeam): string {
    return this.applicationService.parseSelectionName(selectionName, homeTeam, awayTeam);
  }

  getWonCount(): number {
    if (this.item.betDetails.events && this.item.betDetails.events.length > 0) {
      return this.item.betDetails.events.filter(item => item.eventStatusId === 1).length;
    } else {
      return 0;
    }
  }

  toggleBetInfoCall(): void {
    this.isBetInfoCollapsed = !this.isBetInfoCollapsed;
    if (!(this.isBetInfoCollapsed && this.betInfoRetrieved)) {
      // Bet info is open and has not yet been retrieved, so get info
      this.toggleBetInfo.emit(this.item);
    }
  }

  navigateToBetDetailsCall(): void {
    this.navigateToBetDetails.emit(this.item);
  }

  readonly getLiveEventDetails = (eventId: number): BetLiveDetailsModel =>
    this.liveDetails.filter(bets => bets).find(bet => bet.eventId === eventId);
}
