<div class="header" [class.dark-mode]="darkMode">
  <ng-container *ngIf="{
    isAuthenticated: accountQuery.isAuthenticated$ | async,
    userData: accountQuery.userData$ | async,
    refreshingBalance: accountQuery.refreshingBalance$ | async,
    messageCount: messagesQuery.unreadMessagesCount$ | async
  } as subscriptions">
    <div class="logo" (click)="goHome()" [class.logged-in]="subscriptions.isAuthenticated"></div>
    <div class="header-content" *ngIf="subscriptions.isAuthenticated; else loggedOutActionTemplate">
      <div class="refresh-section" [class.no-padding]="!showIcons" (click)="refreshBalance()">
        <i class="material-icons-outlined" [ngClass]="{ 'spin': subscriptions.refreshingBalance }">{{'refresh'}}</i>
        <span class="amount">{{ subscriptions.userData.wallets[0].balance | currencyFormat }}</span>
      </div>
      <div *ngIf="showIcons" class="message" [routerLink]="['/message-center']">
        <span *ngIf="subscriptions.messageCount" [appBadge]="subscriptions.messageCount" [badgeSize]="17"
          badgeClass="badge-red"></span>
        <span class="material-icons">{{'mail'}}</span>
      </div>
      <div *ngIf="showIcons" class="account">
        <span *ngIf="menuItemProfileStatus()" class="indicator"></span>
        <span [routerLink]="['/account']" class="material-icons">{{'person'}}</span>
      </div>
      <div class="balance bonus" *ngIf="subscriptions.userData.bonusBalance > 0">
        <i class="fa fa-bolt"></i>
        <span>{{ subscriptions.userData.bonusBalance | currencyFormat }}</span>
      </div>
    </div>

    <ng-template #loggedOutActionTemplate>
      <div class="header-content">
        <span *ngIf="!subscriptions.isAuthenticated">
          <span class="text" [routerLink]="['/account/login']" i18n>Login</span>
        </span>
        <span *ngIf="!subscriptions.isAuthenticated">
          <span class="button" [routerLink]="['/register']" i18n>Join</span>
        </span>
      </div>

    </ng-template>

  </ng-container>
</div>
