import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';

import { AuthGuard } from 'src/app/core/guards/auth-guard.service';
import { DepositRouteGuard } from 'src/app/core/guards/deposit-route-guard.service';
import { GlobalBetGuard } from 'src/app/core/guards/global-bet-guard.service';
import { GoldenRaceGuard } from 'src/app/core/guards/golden-race-guard.service';
import { NoAuthGuard } from 'src/app/core/guards/no-auth-guard.service';
import { RegistrationRouteGuard } from 'src/app/core/guards/registration-route-guard.service';
import { RestrictRolesGuard } from 'src/app/core/guards/restrict-roles-guard.service';
import { UserDataCheckGuard } from 'src/app/core/guards/user-data-check-guard.service';
import { EnsureModuleLoadedOnceGuard } from 'src/app/core/module-import-guard';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';

export function appConfigServiceInit(appConfig: AppConfigService): any {
  return () => appConfig.loadAppConfig();
}

const APP_INIT = {
  // load the app config during app initialization
  provide: APP_INITIALIZER,
  useFactory: appConfigServiceInit,
  deps: [AppConfigService],
  multi: true,
};
const GUARDS = [
  AuthGuard,
  DepositRouteGuard,
  GlobalBetGuard,
  GoldenRaceGuard,
  NoAuthGuard,
  RegistrationRouteGuard,
  RestrictRolesGuard,
  UserDataCheckGuard,
];

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [APP_INIT, ...GUARDS, CurrencyFormatPipe],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
