<div class="totals-container" [class.no-groupings]="couponData.CouponType !== couponType.System">
  <div class="amount-container" *ngIf="!bookedCoupon">
    <div class="amount-details">
      <div class="content" *ngIf="couponData.CouponType !== couponType.System">
        <div class="label" i18n>Total Odds:</div>
        <div class="value">{{ couponData.TotalOdds | odd }}</div>
      </div>
      <div class="content" *ngIf="couponData.CouponType === couponType.System">
        <div class="label" i18n>Max Odds:</div>
        <div class="value">{{ couponData.MaxOdd | odd }}</div>
      </div>
      <div class="stake-amount-labels">
        <span class="label" i18n>Stake Amount<span
            *ngIf="(this.couponQuery.minBetStake$ | async) === false">:</span>&nbsp;</span>
        <span class="label" *ngIf="(this.couponQuery.minBetStake$ | async)" i18n>
          (Min {{ (this.couponQuery.minBetStake$ | async ) | currencyFormat: '1.0-0' }})
        </span>
      </div>
    </div>
    <div class="stake-amount">
      <div class="value" *ngIf="(this.couponQuery.minBetStake$ | async) as minBet">
        <span class="currency-symbol">{{ currencySymbol }}</span>
        <input type="number" step=".01" class="stake-amount" [min]="minBet | number: '1.0-0'"
          [value]="couponData.StakeGross | number: '1.0-2' | removeComma" (change)="updateInputStakeValue($event)"
          appSelectAll>
        <span (click)="resetStakeValue()" class="reset-stake material-icons">{{'cancel'}}</span>
      </div>
    </div>
  </div>

  <div class="delimiters-content" *ngIf="!bookedCoupon">
    <div *ngFor="let amount of fixedStakes; trackBy: indexTrackBy;" class="delimiter delimiter-{{amount}}"
      (click)="addStakeValue(amount)">
      {{ amount | number }}
    </div>
  </div>

  <div class="details-row totals booked header" *ngIf="bookedCoupon">
    <div class="content code">
      <div class="label" i18n>Booking Code:</div>
      <div class="value">{{ bookedCouponCode }}</div>
    </div>
  </div>

  <div class="details-row totals booked" *ngIf="bookedCoupon">
    <div class="content">
      <div class="label" i18n>Booked:</div>
      <div class="value">{{ bookedCouponDate }}</div>
    </div>
    <div class="content">
      <div class="label" i18n>Type:</div>
      <div class="value" [ngSwitch]="couponData.CouponType">
        <span *ngSwitchCase="couponType.Single" i18n>Single</span>
        <span *ngSwitchCase="couponType.Multiple" i18n>Multiple</span>
        <span *ngSwitchCase="couponType.System" i18n>System</span>
        <span *ngSwitchCase="couponType.Unknown" i18n>Unknown</span>
      </div>
    </div>
  </div>

  <ng-container [ngTemplateOutlet]="winningsDetails" *ngIf="(winningsInfoBubble$ | async) === false"></ng-container>
  <coupon-acca-bonus-details *ngIf="winningsInfoBubble$ | async" [bonusAmount]="couponData.MaxBonus"
    [disableAnimations]="true" [cmsContent]="accumulatorBonusQuery.cmsContent$ | async"
    [percentage]="accumulatorBonusQuery.maxAccumulatorBonus$ | async"
    [show]="couponData.MaxBonus > 0 && accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled && couponData.CouponType !== couponType.System"
    [inCoupon]="true" class="details-row totals"></coupon-acca-bonus-details>

  <div class="details-row totals accumulator-bonus outer-bonus" *ngIf="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled &&
           couponData.CouponType === couponType.System && (couponData.MinBonus + couponData.MaxBonus > 0)">
    <div class="content bonus">
      <div class="label">
        <span i18n>Min. Bonus</span>
        <span class="perc" *ngIf="couponData.MinPercentageBonus !== 0">({{ couponData.MinPercentageBonus * 100
          }}%)</span>
      </div>
      <div class="value">{{ couponData.MinBonus | currencyFormat }}</div>
    </div>
    <div class="content bonus">
      <div class="label">
        <span i18n>Max. Bonus</span>
        <span class="perc" *ngIf="couponData.MaxPercentageBonus !== 0">({{ couponData.MaxPercentageBonus * 100
          }}%)</span>
      </div>
      <div class="value">{{ couponData.MaxBonus | currencyFormat }}</div>
    </div>
  </div>

  <div class="details-row pot-win" *ngIf="!bookedCoupon">
    <div class="content">
      <div class="label" i18n>Net Potential Winnings</div>
      <div class="value">
        <span>{{ couponData.MaxWinNet | currencyFormat }}</span>
        <span class="material-icons" *ngIf="winningsInfoBubble$ | async"
          (click)="toggleWinningsDetails()">{{'info'}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="winningDetailsExpanded$ | async" class="info-bubble-overlay" (click)="hideInfoBubble()"
    [@fadeInOutDelay]="{ value: '', params: { delay: 30 } }"></div>

  <div class="winnings-info-bubble" *ngIf="winningDetailsExpanded$ | async"
    [@fadeInOutDelay]="{ value: '', params: { delay: 30 } }">
    <div class="top-header">
      <span i18n>Bet Info</span>
      <span class="info-bubble-close material-icons" (click)="hideInfoBubble()">{{'close'}}</span>
    </div>
    <ng-container [ngTemplateOutlet]="winningsDetails"></ng-container>
  </div>
</div>

<ng-template #winningsDetails>
  <div class="details-row totals" *ngIf="bookedCoupon && couponData.TurnoverTax > 0">
    <div class="content even">
      <div class="label" i18n>Stake Gross</div>
      <div class="value">{{ couponData.StakeGross | currencyFormat }}</div>
    </div>
  </div>
  <div class="details-row totals" *ngIf="couponData.TurnoverTax > 0">
    <div class="content even">
      <div class="label">
        <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>VAT</ng-container>
        <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Excise Duty</ng-container>
        <span class="perc" *ngIf="taxPercentageToShow">({{ taxPercentageToShow }})</span>
      </div>
      <div class="value">{{ couponData.TurnoverTax | currencyFormat }}</div>
    </div>
    <div class="content odd">
      <div class="label" i18n>Net Stake</div>
      <div class="value">{{ (couponData.StakeGross - couponData.TurnoverTax) | currencyFormat }}</div>
    </div>
  </div>

  <!-- System Bet -->
  <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
    <div class="content even">
      <div class="label" i18n>Min. Odds</div>
      <div class="value">{{ couponData.MinOdd | odd }}</div>
    </div>
    <div class="content odd">
      <div class="label" i18n>Max. Odds</div>
      <div class="value">{{ couponData.MaxOdd | odd }}</div>
    </div>
  </div>
  <div class="details-row totals"
    [class.accumulator-bonus]="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled"
    *ngIf="couponData.CouponType === couponType.System && (couponData.MinBonus + couponData.MaxBonus > 0)">
    <div class="content bonus" *ngIf="couponData.MinPercentageBonus !== 0">
      <div class="label" i18n>
        Min. Bonus
        <span class="perc">({{ couponData.MinPercentageBonus * 100 }}%)</span>
      </div>
      <div class="value">{{ couponData.MinBonus | currencyFormat }}</div>
    </div>
    <div class="content bonus" *ngIf="couponData.MaxPercentageBonus !== 0">
      <div class="label">
        <span i18n>Max. Bonus</span>
        <span class="perc">({{ couponData.MaxPercentageBonus * 100 }}%)</span>
      </div>
      <div class="value">{{ couponData.MaxBonus | currencyFormat }}</div>
    </div>
  </div>
  <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
    <div class="content even">
      <div class="label" i18n>Min. Win</div>
      <div class="value">{{ couponData.NetStakeMinWin | currencyFormat }}</div>
    </div>
    <div class="content odd">
      <div class="label" i18n>Max. Win</div>
      <div class="value">{{ couponData.NetStakeMaxWin | currencyFormat }}</div>
    </div>
  </div>
  <div class="details-row totals"
    *ngIf="couponData.CouponType === couponType.System && couponData.MaxWithholdingTax > 0">
    <div class="content even">
      <div class="label">
        <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Min Inc. Tax</ng-container>
        <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Min. WTH Tax</ng-container>
      </div>
      <div class="value">{{ couponData.MinWithholdingTax | currencyFormat }}</div>
    </div>
    <div class="content odd">
      <div class="label">
        <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Max Inc. Tax</ng-container>
        <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Max. WTH Tax</ng-container>
      </div>
      <div class="value">{{ couponData.MaxWithholdingTax | currencyFormat }}</div>
    </div>
  </div>

  <!-- Not System Bet -->
  <coupon-acca-bonus-details *ngIf="(winningsInfoBubble$ | async) === false" [bonusAmount]="couponData.MaxBonus"
    [cmsContent]="accumulatorBonusQuery.cmsContent$ | async" [disableAnimations]="true"
    [percentage]="accumulatorBonusQuery.maxAccumulatorBonus$ | async"
    [show]="couponData.MaxBonus > 0 && accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled && couponData.CouponType !== couponType.System"
    [inCoupon]="true" class="details-row totals"></coupon-acca-bonus-details>
  <div class="details-row totals" *ngIf="(winningsInfoBubble$ | async) && couponData.CouponType !== couponType.System"
    [class.accumulator-bonus]="accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled">
    <div class="content bonus" *ngIf="couponData.MaxPercentageBonus !== 0">
      <div class="label">
        <span i18n>Accumulator Bonus</span>
        <div class="perc">({{ couponData.MaxPercentageBonus * 100 | number: '1.0-0' }}%)</div>
      </div>
      <div class="value">{{ couponData.MaxBonus | currencyFormat }}</div>
    </div>
  </div>
  <div class="details-row totals" *ngIf="(winningsInfoBubble$ | async) && couponData.CouponType !== couponType.System">
    <div class="content even">
      <div class="label" i18n>Potential Winnings (Gross)</div>
      <div class="value">{{ couponData.NetStakeMaxWin | currencyFormat }}</div>
    </div>
  </div>
  <div class="details-row totals"
    *ngIf="couponData.CouponType !== couponType.System && couponData.MaxWithholdingTax > 0">
    <div class="content odd">
      <div class="label">
        <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>Income TAX (15%)</ng-container>
        <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>Withholding Tax</ng-container>
      </div>
      <div class="value">{{ couponData.MaxWithholdingTax | currencyFormat }}</div>
    </div>
  </div>
</ng-template>
