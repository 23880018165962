import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { MetaService } from 'src/app/modules/meta/meta.module';
import { fadeIn, fadeInOutBy, slideUp, slideInToggle } from 'src/app/shared/animations';
import { RegistrationType } from 'src/app/shared/models/registration.model';
import Hammer from '@egjs/hammerjs';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  animations: [fadeIn(), slideUp(), slideInToggle(), fadeInOutBy()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrapperComponent implements OnInit, OnDestroy {
  lastOrientation: string | number = window.orientation;
  lastWidth: number = window.innerWidth;
  lastHeight: number = window.innerHeight;
  destroy$ = new Subject<boolean>();

  readonly RegistrationType: typeof RegistrationType = RegistrationType;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationQuery: ApplicationQuery,
    readonly registrationQuery: RegistrationQuery,
    readonly sportQuery: SportQuery,
    private readonly applicationService: ApplicationService,
    private readonly metaService: MetaService,
    private readonly router: Router
  ) {}

  @ViewChild('overlayContent')
  set registerOverlayGestures(overlayContent: ElementRef) {
    // register gesture event listeners on the overlay
    if (overlayContent) {
      const hammer = new Hammer(overlayContent.nativeElement);
      hammer.on('tap', () => {
        this.closeMiniSlideUp();
      });
    }
  }

  ngOnInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const urlSplit = event.url.split('/');
      urlSplit.shift();
      this.applicationService.updateActiveUrl(urlSplit);
      this.metaService.useDataFromCMS(event);
    });
  }

  closeMiniSlideUp(): void {
    this.applicationService.showQuickCoupon(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
