import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfigService,
    readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService
  ) {}

  get<T>(apiType: APIType, apiEndpoint: string, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings),
    };

    return this.httpClient.get<T>(apiUrl, httpOptions);
  }

  post<T>(apiType: APIType, apiEndpoint: string, data: any, settings?: APISettings): Observable<any> {
    const apiSettings: APISettings = settings || new APISettings();
    if (!apiSettings.contentType) {
      apiSettings.contentType = 'application/json';
    }

    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, apiSettings),
    };

    return this.httpClient.post<T>(apiUrl, data, httpOptions);
  }

  put<T>(apiType: APIType, apiEndpoint: string, data: any, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings),
    };

    return this.httpClient.put<T>(apiUrl, data, httpOptions);
  }

  delete<T>(apiType: APIType, apiEndpoint: string, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings),
    };

    return this.httpClient.delete<T>(apiUrl, httpOptions);
  }

  // eslint-disable-next-line complexity
  private generateUrl(apiType: APIType, apiEndpoint: string): string {
    const apiBaseUrl = this.appConfig.get('apiBaseUrl');
    let baseUrl: string;
    switch (apiType) {
      case APIType.BetRadarResults:
        baseUrl = apiBaseUrl.betRadarResults;
        break;
      case APIType.BetRadarStatistics:
        baseUrl = apiBaseUrl.betRadarStatistics;
        break;
      case APIType.CMS:
        if (environment.production) {
          baseUrl = apiBaseUrl.cms;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdCMSUrl');
          baseUrl = overrideUrl || apiBaseUrl.cms;
        }
        break;
      case APIType.Guardian:
        if (environment.production) {
          baseUrl = apiBaseUrl.guardian;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdGuardianUrl');
          baseUrl = overrideUrl || apiBaseUrl.guardian;
        }
        break;
      case APIType.F2PGuardian:
        if (environment.production) {
          baseUrl = apiBaseUrl.f2pGuardian;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdF2PGuardianUrl');
          baseUrl = overrideUrl || apiBaseUrl.f2pGuardian;
        }
        break;
      case APIType.Local:
        baseUrl = '';
        break;
      case APIType.Platform:
        if (environment.production) {
          baseUrl = apiBaseUrl.platform;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdPlatformUrl');
          baseUrl = overrideUrl || apiBaseUrl.platform;
        }
        break;
      case APIType.Sportsbook:
        if (environment.production) {
          baseUrl = apiBaseUrl.sportsbook;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSportsbookUrl');
          baseUrl = overrideUrl || apiBaseUrl.sportsbook;
        }
        break;
      case APIType.SportsbookFeed:
        if (environment.production) {
          baseUrl = apiBaseUrl.sportsbookFeed;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSportsbookFeedUrl');
          baseUrl = overrideUrl || apiBaseUrl.sportsbookFeed;
        }
        break;
      case APIType.VirtualsSportsbook:
        if (environment.production) {
          baseUrl = apiBaseUrl.virtuals;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdVirtualsUrl');
          baseUrl = overrideUrl || apiBaseUrl.virtuals;
        }
        break;
      case APIType.VirtualsSportsbookFeed:
        if (environment.production) {
          baseUrl = apiBaseUrl.virtualsFeed;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdVirtualsFeedUrl');
          baseUrl = overrideUrl || apiBaseUrl.virtualsFeed;
        }
        break;
      case APIType.Website:
        if (environment.production) {
          baseUrl = apiBaseUrl.website;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdFrontendServicesUrl');
          baseUrl = overrideUrl || apiBaseUrl.website;
        }
        break;
      case APIType.JackpotBets:
        if (environment.production) {
          baseUrl = apiBaseUrl.jackpotBets;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdJackpotBetsUrl');
          baseUrl = overrideUrl || apiBaseUrl.jackpotBets;
        }
        break;
      case APIType.JackpotBetsCms:
        if (environment.production) {
          baseUrl = apiBaseUrl.jackpotBetsCms;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdJackpotbetsCmsUrl');
          baseUrl = overrideUrl || apiBaseUrl.jackpotBetsCms;
        }
        break;
      case APIType.SuperPicksValidationDomain:
        if (environment.production) {
          baseUrl = apiBaseUrl.superPicksValidationDomain;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSuperPicksValidationDomain');
          baseUrl = overrideUrl || apiBaseUrl.superPicksValidationDomain;
        }
        break;
      default:
        baseUrl = '';
        console.error(`No valid APIType provided for api endpoint '${apiEndpoint}'`);
        break;
    }

    return this.languageService.enableLanguageSelection$.value && apiType === APIType.CMS
      ? apiEndpoint.indexOf('?') > -1
        ? `${baseUrl}/${apiEndpoint}&language=${this.languageService.selectedLanguage.locale.toLowerCase()}`
        : `${baseUrl}/${apiEndpoint}?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`
      : `${baseUrl}/${apiEndpoint}`;
  }

  private getLocalStorageOverride(key: string): string {
    // making use of window.localStorage directly in order to avoid having
    // to include the ngx-webstorage prefix value to the keys
    return window.localStorage.getItem(key);
  }

  private generateHeaders(apiType: APIType, settings?: APISettings): HttpHeaders {
    // using VND naming convention for headers which are used only for application logic,
    // following the convention specified in RFC6648 - https://tools.ietf.org/html/rfc6648
    const apiSettings: APISettings = settings || new APISettings();
    let httpHeaders = new HttpHeaders();

    if (
      apiType === APIType.Platform ||
      apiType === APIType.Guardian ||
      apiType === APIType.F2PGuardian ||
      apiType === APIType.Website ||
      apiType === APIType.SuperPicksValidationDomain
    ) {
      const isGhanaOrKenya = this.applicationQuery.isBrand(['Kenya']) || this.applicationQuery.isBrand(['Ghana']);
      const brandId =
        apiType === APIType.F2PGuardian && !isGhanaOrKenya
          ? this.appConfig.get('f2pBrandId').toString()
          : this.appConfig.get('brandId').toString();

      if (brandId) {
        httpHeaders = httpHeaders.append('X-BRAND-ID', brandId);
      }
    }

    if (apiType === APIType.JackpotBets) {
      httpHeaders = httpHeaders.append('X-API-BRAND', this.appConfig.get('brandId').toString());
      httpHeaders = httpHeaders.append(
        'X-API-PLAYSOURCE',
        this.appConfig.get('jackpotBets') ? this.appConfig.get('jackpotBets').playSourceHeader.toString() : '1'
      );
    }

    if (apiSettings.contentType) {
      httpHeaders = httpHeaders.append('Content-Type', apiSettings.contentType);
    }

    if (apiSettings.inBehalfOf) {
      httpHeaders = httpHeaders.append('inBehalfOf', apiSettings.inBehalfOf);
    }

    if (apiSettings.noAuthToken) {
      httpHeaders = httpHeaders.append('VND.noAuthToken', 'true');
    } else if (apiSettings.forceAuthToken) {
      httpHeaders = httpHeaders.append('VND.forceAuthToken', apiSettings.forceAuthToken);
    }

    if (apiSettings.sendActivitySource) {
      httpHeaders = httpHeaders.append('VND.sendActivitySource', 'true');
    }

    return httpHeaders;
  }
}
