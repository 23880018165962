<div class="nav-bar-items">
  <ng-container *ngFor="let navBarItem of (navBarItems$ | async); let i = index; trackBy: navBarItemTrackBy">
    <div class="nav-bar-item" (click)="navbarEvent(navBarItem)" [ngClass]="{middle: i === 2}"
      *ngIf="(navBarItem.showLoggedIn && (accountQuery.isAuthenticated$ | async)) || (navBarItem.showLoggedOut && (accountQuery.isAuthenticated$ | async) === false)">
      <span class="icon" [appNavBarSvgLoader]="'images/navbar-icons/navbar-icons-' + navBarItem.icon"
        [isSelected]="navBarItem.isSelected$ && navBarItem.isSelected$ | async">
        <ng-container *ngIf="navBarItem.badge$ | async as badge">
          <span *ngIf="badge" [appBadge]="badge" [badgeSize]="16" [rightAdjustment]="-5"></span>
        </ng-container>
      </span>
      <ng-container *ngIf="coupon$ | async as coupon">
        <span class="title">
          {{ navBarItem.title }}
        </span>
      </ng-container>
    </div>
  </ng-container>
</div>
