<div class="acca-bonus-progression-bar-container" *ngIf="accumulatorBonusValidSelectionCount > 1"
  (click)="openAccBonusInfoPopup()">
  <div class="before-acca-bonus" *ngIf="(hasAccumulatorBonus$ | async) === false">
    <div class="info-icon material-icons" *ngIf="showInfoIcon">{{'info'}}</div>
    <div class="selection-count-text">
      {{ prepareAccumulatorBonusSelectionsTillBonusText() }}
    </div>
  </div>
  <div class="has-acca-bonus" *ngIf="hasAccumulatorBonus$ | async">
    <div class="info-icon material-icons" *ngIf="showInfoIcon">{{'info'}}</div>
    <div class="acca-bonus-step-container">
      <div class="acca-bonus-step" *ngFor="let bonus of accumulatorBonusList; let i = index; trackBy: accumulatorBonusListTrackBy"
        #accaBonusStep>
        <div class="acca-bonus-step-details" [class.previous-step]="bonus.Percentage < accumulatorBonusPercentage"
          [class.upcoming-step]="bonus.Percentage > accumulatorBonusPercentage" *ngIf="i < accumulatorBonusPercentageIndex">
          <span class="plus-icon material-icons" *ngIf="bonus.Percentage <= accumulatorBonusPercentage">{{'add'}}</span>
          <span class="lock-icon material-icons" *ngIf="bonus.Percentage > accumulatorBonusPercentage">{{'lock'}}</span>
          <span>{{ bonus.Percentage }}%</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-info-modal [title]="cmsContent?.accumulatorBonusInfoModalTitle"
  [subtitle]="cmsContent?.accumulatorBonusInfoModalSubtitle" [bodyHTML]="cmsContent?.accumulatorBonusInfoModalBody"
  [buttonText]="cmsContent?.accumulatorBonusInfoModalCloseCTA" [show]="showInfoModal$ | async"
  (closeModal)="closeAccBonusInfoPopup()"></app-info-modal>
