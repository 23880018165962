import {
  animate,
  AnimationTriggerMetadata,
  group,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { animationDuration } from 'src/app/core/services/variables.service';

export const fadeIn = (): AnimationTriggerMetadata =>
  trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate('.3s ease-in', style({ opacity: 1 }))])]);

export const fadeInBy = (): AnimationTriggerMetadata =>
  trigger('fadeInBy', [transition('void => *', [style({ opacity: 0 }), animate('{{duration}}ms ease-in', style({ opacity: 1 }))])]);

export const fadeInByStaggered = (): AnimationTriggerMetadata =>
  trigger('fadeInByStaggered', [
    transition('void => *', [
      query('.staggered', [style({ opacity: 0 }), stagger(25, [animate('{{duration}}ms ease-in', style({ opacity: 1 }))])], {
        optional: true,
      }),
    ]),
  ]);

export const fadeOut = (): AnimationTriggerMetadata =>
  trigger('fadeOut', [transition('* => void', [style({ opacity: 1 }), animate('.4s ease-in', style({ opacity: 0 }))])]);

export const fadeOutBy = (): AnimationTriggerMetadata =>
  trigger('fadeOutBy', [transition('* => void', [style({ opacity: 1 }), animate('{{duration}}ms ease-in', style({ opacity: 0 }))])]);

export const fadeInOut = (): AnimationTriggerMetadata =>
  trigger('fadeInOut', [
    transition('* => void', [style({ opacity: 1 }), animate('.4s ease-in', style({ opacity: 0 }))]),
    transition('void => *', [style({ opacity: 0 }), animate('.4s ease-in', style({ opacity: 1 }))]),
  ]);

export const fadeInOutBy = (): AnimationTriggerMetadata =>
  trigger('fadeInOutBy', [
    transition('void => *', [style({ opacity: 0 }), animate('{{duration}}ms ease-in', style({ opacity: 1 }))]),
    transition('* => void', [style({ opacity: 1 }), animate('{{duration}}ms ease-out', style({ opacity: 0 }))]),
  ]);

export const fadeInOutDelay = (): AnimationTriggerMetadata =>
  trigger('fadeInOutDelay', [
    transition('void => *', [style({ opacity: 0 }), animate('.2s {{delay}}ms ease-in', style({ opacity: 1 }))]),
    transition('* => void', [style({ opacity: 1 }), animate('.2s ease-out', style({ opacity: 0 }))]),
  ]);

export const slideInToggle = (): AnimationTriggerMetadata =>
  trigger('slideInToggle', [
    transition('void => *', [style({ transform: 'translateY(100%)' }), animate('300ms ease-in', style({ transform: 'translateY(0%)' }))]),
    transition('* => void', [style({ opacity: 1 }), animate('400ms ease-in-out', style({ opacity: 0 }))]),
  ]);

export const slideInOut = (): AnimationTriggerMetadata =>
  trigger('slideInOut', [
    transition('void => *', [style({ transform: 'translateX(100%)' }), animate('400ms ease', style({ transform: 'translateX(0%)' }))]),
    transition('* => void', [style({ transform: 'translateX(0%)' }), animate('400ms ease', style({ transform: 'translateX(100%)' }))]),
  ]);

export const slideUp = (): AnimationTriggerMetadata =>
  trigger('slideUp', [
    transition('void => *', [style({ transform: 'translateY(100%)' }), animate('200ms ease', style({ transform: 'translateY(0%)' }))]),
    transition('* => void', [style({ transform: 'translateY(0%)' }), animate('200ms ease', style({ transform: 'translateY(100%)' }))]),
  ]);

export const slidePanelSide = (): AnimationTriggerMetadata =>
  trigger('slidePanelSide', [
    state('left', style({ transform: 'translateX(0)' })),
    state('right', style({ transform: 'translateX(-50%)' })),
    transition('* => *', animate('300ms ease-in-out')),
  ]);

export const expandCollapse = (): AnimationTriggerMetadata =>
  trigger('expandCollapse', [
    state('cancel-animation', style({ height: '0px', minHeight: '0px' }), { params: { minHeight: '0px' } }),
    state('collapsed', style({ height: '{{ minHeight }}', minHeight: '{{ minHeight }}' }), { params: { minHeight: '0px' } }),
    state('expanded', style({ height: '*', minHeight: '*' })),
    transition('expanded <=> collapsed', animate(`${animationDuration}ms ease-in-out`)),
    transition('void => *', animate(`0ms`)),
  ]);

export const expandCollapseSimpler = (): AnimationTriggerMetadata =>
  trigger('expandCollapseSimpler', [
    transition('void => *', [
      style({ height: '0', minHeight: '0' }),
      animate(`${animationDuration}ms ease-in-out`, style({ height: '*', minHeight: '*' })),
    ]),
    transition('* => void', [
      style({ height: '*', minHeight: '*' }),
      animate(`${animationDuration}ms ease-in-out`, style({ height: '0', minHeight: '0' })),
    ]),
  ]);

export const growAnimation = (): AnimationTriggerMetadata =>
  trigger('growAnimation', [transition(':increment', [style({ transform: 'scale(1.5)' }), animate('0.8s ease-out', style('*'))])]);

export const preventInitialChildAnimations = (): AnimationTriggerMetadata =>
  trigger('preventInitialChildAnimations', [transition(':enter', [query(':enter', [], { optional: true })])]);

const blinking = [
  style({ opacity: 1, offset: 0 }),
  style({ opacity: 0, offset: 0.25 }),
  style({ opacity: 1, offset: 0.5 }),
  style({ opacity: 0, offset: 0.75 }),
  style({ opacity: 1, offset: 1 }),
];

export const oddChange = (): AnimationTriggerMetadata =>
  trigger('oddChange', [
    transition(
      ':increment',
      group([
        query('.odd-value', [animate(`${animationDuration}ms ease-out`, style({ top: '0', transform: 'translateY(-100%)' }))]),
        query(
          '.animation-helper',
          [
            style({ top: '100%', display: 'block' }),
            animate(`${animationDuration}ms ease-out`, style({ top: '50%', transform: 'translateY(-50%)' })),
          ],
          { optional: true }
        ),
        query(
          '.increased',
          [
            style({ top: '100%', display: 'block', opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ top: '50%', transform: 'translateY(-50%)', opacity: 1 })),
            animate(`3s ease-out`, keyframes(blinking)),
            animate(`${animationDuration}ms ease-out`, style({ top: '0', display: 'none', opacity: 0 })),
          ],
          { optional: true }
        ),
      ])
    ),
    transition(
      ':decrement',
      group([
        query('.odd-value', [animate(`${animationDuration}ms ease-out`, style({ top: '100%', transform: 'translateY(100%)' }))]),
        query(
          '.animation-helper',
          [
            style({ top: '0', transform: 'translateY(-100%)', display: 'block' }),
            animate(`${animationDuration}ms ease-out`, style({ top: '50%', transform: 'translateY(-50%)' })),
          ],
          { optional: true }
        ),
        query(
          '.decreased',
          [
            style({ top: '0%', display: 'block', opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ top: '50%', transform: 'translateY(-50%)', opacity: 1 })),
            animate(`3s ease-out`, keyframes(blinking)),
            animate(`${animationDuration}ms ease-out`, style({ top: '100%', display: 'none', opacity: 0 })),
          ],
          { optional: true }
        ),
      ])
    ),
  ]);
