import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';
import {
  AreaModel,
  EventSummaryModel,
  OddsBoostInfoModalContent,
  PlayerViewModel,
  QuicklinksState,
  RegionModel,
  SportModel,
  SportState,
} from 'src/app/shared/models/sport.model';

import { LocalStorageService } from 'ngx-webstorage';

const createInitialState = (): SportState => ({
  match: undefined,
  selectedPrematch: undefined,
  sportsList: undefined,
  eventSelection: {
    allCompetitionByCountry: undefined,
    areaAndRegionCache: undefined,
    areaMarkets: undefined,
    autoForwardHappened: false,
    competitionsAZ: undefined,
    eventSelectionDepth: 1,
    eventSelectionLoaded: false,
    eventSelectionQuicklinks: undefined,
    filterToDepthFour: false,
    goalscorerSport: undefined,
    isSportsListOpened: false,
    oddsBoostSport: undefined,
    outrights: undefined,
    quickLinksState: QuicklinksState.Pending,
    restoreAreaRegionsCache: false,
    selectedArea: undefined,
    selectedAreaId: undefined,
    selectedIdsAtDepthFour: undefined,
    selectedMarket: undefined,
    selectedQuicklink: undefined,
    selectedSport: undefined,
    specialSports: undefined,
    topCompetitions: undefined,
    visibleCardsAtDepthFour: undefined,
  },
  ui: {
    preMatchCloseAll: false,
    filterQuery: '',
  },
  playersData: [],
  isItCorrectScore: undefined,
  areas: undefined,
  regions: undefined,
  selectedPlayerIds: [],
  favouriteSports: [],
  oddsBoostInfoModalCMSContent: undefined,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sport' })
export class SportStore extends Store<SportState> {
  private readonly favouriteSportsKey = 'favouriteSports';

  constructor(private readonly localStorage: LocalStorageService) {
    super(createInitialState());

    this.readFavouritesFromLocalStorage();
  }

  updateSportsList(sportsList: SportModel[]): void {
    this.update(state => ({ sportsList }));
  }

  updateIsItCorrectScore(isItCorrectScore: boolean): void {
    this.update({ isItCorrectScore });
  }

  updatePlayersData(playersData: PlayerViewModel): void {
    const currentData = [...this._value().playersData];
    const index = currentData.findIndex(data => data.tournamentId === playersData.tournamentId);
    if (index > -1) {
      currentData[index] = playersData;
    } else {
      currentData.push(playersData);
    }
    this.update({ playersData: currentData });
  }

  clearPlayersData(): void {
    this.update({ playersData: [] });
  }

  updateSelectedPrematch(selectedPrematch: EventSummaryModel[]): void {
    this.update({ selectedPrematch });
  }

  updateEventSelection(eventSelection: Partial<SportState['eventSelection']>): void {
    this.update(state => ({
      eventSelection: {
        ...state.eventSelection,
        ...eventSelection,
      },
    }));
  }

  updateMatch(match: SportModel): void {
    this.update({ match });
  }

  updateAreas(areas: AreaModel[]): void {
    this.update({ areas });
  }

  updateRegions(regions: RegionModel[]): void {
    this.update({ regions });
  }

  updateUI(ui: Partial<SportState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateFavourites(favouriteSports: number[]): void {
    this.localStorage.store(this.favouriteSportsKey, favouriteSports);
    this.update({ favouriteSports });
  }

  updateOddsBoostInfoModalCMSContent(oddsBoostInfoModalCMSContent: OddsBoostInfoModalContent): void {
    this.update({ oddsBoostInfoModalCMSContent });
  }

  private readFavouritesFromLocalStorage(): void {
    const ids = this.localStorage.retrieve(this.favouriteSportsKey);

    if (Array.isArray(ids)) {
      this.updateFavourites(ids);
    }
  }
}
