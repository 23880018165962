<swal #evaluation (confirm)="evaluationReplyClicked(couponData.CouponCode, true, couponData.UserId)"
  (cancel)="evaluationReplyClicked(couponData.CouponCode, false, couponData.UserId)" [swalOptions]="options">
  <div *swalPortal class="evaluation-container">
    <div class="max-win-changed">
      <span class="label" i18n>Potential winnings have changed:</span>
      <div [class.taxed]="couponData.MaxWithholdingTax > 0">
        <span class="value">{{ couponData.MaxWinNet | currencyFormat }}</span>
        <span class="tax value" *ngIf="couponData.MaxWithholdingTax > 0">
          <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])" i18n>(Income TAX (15%):</ng-container>
          <ng-container *ngIf="!applicationQuery.isBrand(['Ethiopia'])" i18n>(Tax:</ng-container>
          {{ couponData.MaxWithholdingTax | currencyFormat }}
          <ng-container>)</ng-container>
        </span>
      </div>
    </div>

    <div class="coupon-code">{{couponData.CouponCode}}</div>

    <div class="event-details" *ngIf="couponData.EvalCouponStatusId !== 8">
      <ng-container *ngFor="let event of couponData.Odds; trackBy: indexTrackBy;">
        <div class="match-content" *ngIf="event.OddValue !== event.ConfirmedOddValue">
          <div class="match-details">
            <div class="smart-code" *ngIf="event.EventCategory !== 'L'">{{ event.OnLineCode }}</div>
            <div class="live" *ngIf="event.EventCategory === 'L'" i18n>L</div>
            <span class="divider">|</span>
            <div class="match-name">{{ event.EventName }}</div>
          </div>
          <div class="market-details">
            <div class="market">
              <span class="market-name">{{ event.MarketName }}</span>
              <span class="divider">-</span>
              <span class="selection-name">{{ parseSelectionName(event.SelectionName, event.EventName) }}</span>
            </div>
            <div class="odd">
              <span class="odd-value">
                {{ event.OddValue | number:'1.2-2' }}
              </span>
              <i class='fa fa-caret-right' [class.low]="event.OddValue > event.ConfirmedOddValue"
                [class.high]="event.OddValue < event.ConfirmedOddValue"></i>
              <span class="confirmed-odd">
                {{ event.ConfirmedOddValue | number:'1.2-2' }}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="stake-details" *ngIf="couponData.EvalCouponStatusId !== 9">
      <div class="old-stake">
        <span class="label" i18n>Old Stake:</span>
        <span class="value">{{ couponData.Stake | currencyFormat }}</span>
      </div>
      <div class="confirmed-stake">
        <span class="label" i18n>Confirmed Stake:</span>
        <span class="value">{{ couponData.ConfirmedStake | currencyFormat }}</span>
      </div>
    </div>
  </div>
</swal>
