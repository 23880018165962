import { Injectable } from '@angular/core';
import { MatchModel, SportModel } from 'src/app/shared/models/sport.model';
import { Store, StoreConfig } from '@datorama/akita';
import { BetCoupon, BetCouponGlobalVariable, Dictionary } from 'clientside-coupon';
import { cloneDeep } from 'lodash-es';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { CouponReceiptContentModel, CouponReceiptPhoneVerificationContentModel } from 'src/app/shared/models/coupon-receipt.model';
import {
  BookedCoupon,
  CouponGroupingType,
  CouponSettings,
  CouponState,
  CouponUIState,
  DefaultCouponStake,
  ExpiredEventsModel,
  MarketMatchMap,
  OddChanges,
} from 'src/app/shared/models/coupon.model';

const createInitialState = (): CouponState => ({
  bookedBetData: undefined,
  bookedCoupons: undefined,
  correctScoreOddsMatrix: undefined,
  couponData: undefined,
  couponInitialized: false,
  couponReceiptContent: undefined,
  couponReceiptPhoneVerificationContent: undefined,
  couponSettings: undefined,
  defaultCouponStake: undefined,
  editCouponData: undefined,
  expiredEvents: undefined,
  globalVariables: undefined,
  groupingsTabSelected: undefined,
  lastPlacedCouponCode: '',
  betslipScrollTop: undefined,
  isLastPlacedCouponInEvaluation: false,
  marketExceptions: undefined,
  oddChanges: undefined,
  previousPagePath: '/',
  selectionMarketMatches: undefined,
  selections: undefined,
  ui: {
    showCoupon: false,
    showQuickCoupon: false,
    couponForEdit: {
      matchId: undefined,
      marketTypeId: undefined,
    },
  },
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'coupon' })
export class CouponStore extends Store<CouponState> {
  private readonly globalVariablesKey: string = 'sportsbook.globalVariables';
  private readonly marketExceptionsKey: string = 'sportsbook.marketExceptions';
  private readonly correctScoreOddsMatrixKey: string = 'sportsbook.correctScoreOddsMatrix';
  private readonly couponDataKey = 'couponData';
  private readonly couponSettingsKey: string = 'couponSettings';
  private readonly defaultCouponStakeKey: string = 'defaultCouponStake';
  private readonly oddChangesKey: string = 'oddChanges';
  private readonly bookedCouponsKey: string = 'bookedCoupons';
  private readonly expiredEventsKey: string = 'expiredEvents';

  constructor(
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly localStorage: LocalStorageService,
    private readonly sessionStorage: SessionStorageService
  ) {
    super(createInitialState());

    this.localStorage.observe(this.couponDataKey).subscribe(couponData => {
      this.update({ couponData });
    });
    this.updateCouponData(this.localStorage.retrieve(this.couponDataKey));

    this.localStorage.observe(this.couponSettingsKey).subscribe(couponSettings => {
      this.update({ couponSettings });
    });
    this.updateCouponSettings(this.localStorage.retrieve(this.couponSettingsKey));

    this.localStorage.observe(this.defaultCouponStakeKey).subscribe(defaultCouponStake => {
      this.update({ defaultCouponStake });
    });
    this.updateDefaultCouponStake(this.localStorage.retrieve(this.defaultCouponStakeKey));

    this.localStorage.observe(this.oddChangesKey).subscribe(oddChanges => {
      this.update({ oddChanges });
    });
    this.updateOddChanges(this.localStorage.retrieve(this.oddChangesKey));

    this.localStorage.observe(this.bookedCouponsKey).subscribe(bookedCoupons => {
      this.update({ bookedCoupons });
    });
    this.updateBookedCoupons(this.localStorage.retrieve(this.bookedCouponsKey));

    this.localStorage.observe(this.expiredEventsKey).subscribe(expiredEvents => {
      this.update({ expiredEvents });
    });
    this.updateExpiredEvents(this.localStorage.retrieve(this.expiredEventsKey));

    this.updateCorrectScoreOddsMatrix(this.sessionStorage.retrieve(this.correctScoreOddsMatrixKey));
  }

  updateCouponData(couponData: BetCoupon): void {
    if (!couponData) {
      this.clearCouponData();
      return;
    }

    this.localStorage.store(this.couponDataKey, couponData);

    if (this.localStorage.retrieve(this.couponSettingsKey) === null) {
      const couponSettings = new CouponSettings({
        allowOddChanges: true,
        allowStakeReduction: false,
        allowTransfer: false,
        transferUserId: undefined,
      });

      this.updateCouponSettings(couponSettings);
    }
  }

  updateCouponInitialized(couponInitialized: boolean): void {
    this.update({ couponInitialized });
  }

  updateCouponSettings(couponSettings: CouponSettings): void {
    if (!couponSettings) {
      this.clearCouponSettings();
      return;
    }

    this.localStorage.store(this.couponSettingsKey, couponSettings);
  }

  updateCouponSetting(couponSettings: Partial<CouponState['couponSettings']>): void {
    this.update(state => ({
      couponSettings: {
        ...state.couponSettings,
        ...couponSettings,
      },
    }));

    this.localStorage.store(this.couponSettingsKey, {
      ...this.localStorage.retrieve(this.couponSettingsKey),
      ...couponSettings,
    });
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    if (!defaultCouponStake) {
      this.clearDefaultCouponStake();
      return;
    }

    this.localStorage.store(this.defaultCouponStakeKey, defaultCouponStake);
  }

  updateOddChanges(oddChanges: OddChanges[]): void {
    if (!oddChanges) {
      this.clearOddChanges();
      return;
    }

    this.localStorage.store(this.oddChangesKey, oddChanges);
  }

  addToOddChanges(oddId: number, oddValue: number): void {
    if (this.localStorage.retrieve(this.oddChangesKey)) {
      this.update(state => {
        const oddChanges = [
          ...state.oddChanges,
          new OddChanges({
            oddId: oddId,
            initialOddValue: oddValue,
            latestOddValue: oddValue,
            valueChanged: false,
          }),
        ];

        this.localStorage.store(this.oddChangesKey, oddChanges);
        return { oddChanges };
      });
    } else {
      const oddChanges = [
        new OddChanges({
          oddId: oddId,
          initialOddValue: oddValue,
          latestOddValue: oddValue,
          valueChanged: false,
        }),
      ];

      this.localStorage.store(this.oddChangesKey, oddChanges);
    }
  }

  removeFromOddChanges(oddId: number): void {
    this.update(state => {
      const oddChanges = state.oddChanges.filter(o => o.oddId !== oddId);
      this.localStorage.store(this.oddChangesKey, oddChanges);
      return { oddChanges };
    });
  }

  updateOddChange(oddId: number, newOddValue: number): void {
    this.update(state => {
      const oddChangesCopy: OddChanges[] = cloneDeep(state.oddChanges);

      oddChangesCopy.forEach(odd => {
        if (odd.oddId === oddId) {
          odd.valueChanged = true;
          odd.latestOddValue = newOddValue;
        }
      });

      this.localStorage.store(this.oddChangesKey, oddChangesCopy);
      return { oddChanges: oddChangesCopy };
    });
  }

  updateOddChangrgte(oddId: number, oddValue: number): void {
    if (this.localStorage.retrieve(this.oddChangesKey)) {
      this.update(state => {
        const oddChanges = [
          ...state.oddChanges,
          new OddChanges({
            oddId: oddId,
            initialOddValue: oddValue,
            latestOddValue: oddValue,
            valueChanged: false,
          }),
        ];

        this.localStorage.store(this.oddChangesKey, oddChanges);
        return { oddChanges };
      });
    } else {
      const oddChanges = [
        new OddChanges({
          oddId: oddId,
          initialOddValue: oddValue,
          latestOddValue: oddValue,
          valueChanged: false,
        }),
      ];

      this.localStorage.store(this.oddChangesKey, oddChanges);
    }
  }

  updateExpiredEvents(expiredEvents: ExpiredEventsModel): void {
    if (!expiredEvents) {
      this.clearExpiredEvents();
      return;
    }

    this.localStorage.store(this.expiredEventsKey, expiredEvents);
  }

  updateBookedCoupons(bookedCoupons: BookedCoupon[]): void {
    if (!bookedCoupons) {
      this.clearBookedCoupons();
      return;
    }

    this.localStorage.store(this.bookedCouponsKey, bookedCoupons);
  }

  updateBookedCoupon(bookedCoupon: BookedCoupon): void {
    const bookedCoupons: BookedCoupon[] = [];
    const storedBookedCoupons = this.localStorage.retrieve(this.bookedCouponsKey);
    const storedBookedCouponsCopy: BookedCoupon[] = cloneDeep(storedBookedCoupons);

    if (!storedBookedCouponsCopy) {
      bookedCoupons.push(bookedCoupon);
      this.localStorage.store(this.bookedCouponsKey, bookedCoupons);
    } else {
      storedBookedCouponsCopy.push(bookedCoupon);
      this.localStorage.store(this.bookedCouponsKey, storedBookedCouponsCopy);
    }
  }

  updateGlobalVariables(globalVariables: BetCouponGlobalVariable): void {
    if (!globalVariables) {
      this.clearGlobalVariables();
      return;
    }

    this.update({ globalVariables });
    this.sessionStorage.store(this.globalVariablesKey, globalVariables);
  }

  updateMarketExceptions(marketExceptions: Dictionary<number, number[]>): void {
    if (!marketExceptions) {
      this.clearMarketExceptions();
      return;
    }

    this.update({ marketExceptions });
    this.sessionStorage.store(this.marketExceptionsKey, marketExceptions);
  }

  updateCorrectScoreOddsMatrix(correctScoreOddsMatrix: any): void {
    if (!correctScoreOddsMatrix) {
      this.clearCorrectScoreOddsMatrix();
      return;
    }

    this.update({ correctScoreOddsMatrix });
    this.sessionStorage.store(this.correctScoreOddsMatrixKey, correctScoreOddsMatrix);
  }

  updateGroupingTab(groupingsTabSelected: CouponGroupingType): void {
    if (!groupingsTabSelected) {
      this.clearGroupingTab();
      return;
    }

    this.update({ groupingsTabSelected });
  }

  updateUI(ui: Partial<CouponUIState>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui,
      },
    }));
  }

  updateEditCouponData(editCouponData: MatchModel): void {
    this.update({ editCouponData });
  }

  updateOpenedForEditCoupon(matchId: number, marketTypeId: number): void {
    this.updateUI({
      couponForEdit: {
        matchId,
        marketTypeId,
      },
    });
  }

  updateSelections(selections: SportModel[]): void {
    this.update({ selections });
  }

  updateMarketMatches(selectionMarketMatches: MarketMatchMap[]): void {
    this.update({ selectionMarketMatches });
  }

  clearCouponData(): void {
    this.localStorage.clear(this.couponDataKey);
    this.clearCouponSettings();
    this.clearOddChanges();
    this.clearExpiredEvents();

    this.clearGroupingTab();

    // Reset Acc Bonus User preference on coupon clear
    this.accumulatorBonusStore.update({
      dismissedAccumulatorBonusOddsValuePopup: false,
    });
  }

  clearCouponSettings(): void {
    this.localStorage.clear(this.couponSettingsKey);
  }

  clearDefaultCouponStake(): void {
    this.localStorage.clear(this.defaultCouponStakeKey);
  }

  clearOddChanges(): void {
    this.localStorage.clear(this.oddChangesKey);
  }

  clearExpiredEvents(): void {
    this.localStorage.clear(this.expiredEventsKey);
  }

  clearBookedCoupons(): void {
    this.localStorage.clear(this.bookedCouponsKey);
  }

  removeBookedCoupon(couponCode: string): void {
    const storedBookedCoupons = this.localStorage.retrieve(this.bookedCouponsKey);
    let storedBookedCouponsCopy: BookedCoupon[] = cloneDeep(storedBookedCoupons);

    if (!storedBookedCouponsCopy) {
      this.clearBookedCoupons();
    } else {
      storedBookedCouponsCopy = storedBookedCouponsCopy.filter(o => o.couponCode !== couponCode);

      if (storedBookedCouponsCopy.length > 0) {
        this.localStorage.store(this.bookedCouponsKey, storedBookedCouponsCopy);
      } else {
        this.clearBookedCoupons();
      }
    }
  }

  clearGlobalVariables(): void {
    this.update({ globalVariables: undefined });
    this.sessionStorage.clear(this.globalVariablesKey);
  }

  clearMarketExceptions(): void {
    this.update({ marketExceptions: undefined });
    this.sessionStorage.clear(this.marketExceptionsKey);
  }

  clearCorrectScoreOddsMatrix(): void {
    this.update({ correctScoreOddsMatrix: undefined });
    this.sessionStorage.clear(this.correctScoreOddsMatrixKey);
  }

  clearGroupingTab(): void {
    this.update({ groupingsTabSelected: undefined });
  }

  updateCouponReceiptContent(couponReceiptContent: CouponReceiptContentModel): void {
    this.update({ couponReceiptContent });
  }

  updateCouponReceiptPhoneVerificationContent(couponReceiptPhoneVerificationContent: CouponReceiptPhoneVerificationContentModel): void {
    this.update({ couponReceiptPhoneVerificationContent });
  }

  updateBookedBetData(bookedBetData: BookBetModel): void {
    this.update({ bookedBetData });
  }

  clearBookedBetData(): void {
    this.update({ bookedBetData: undefined });
  }

  updatePreviousPage(previousPagePath: string): void {
    this.update({ previousPagePath });
  }
}
