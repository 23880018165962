import { Injectable } from '@angular/core';
import { CookieService } from 'src/app/core/services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class ABTestService {
  private readonly GAEXP_STORAGE_KEY = '_gaexp';

  constructor(private readonly cookieService: CookieService) {}

  getOptimizeURL(defaultUrl: string, newUrl: string): string {
    const getOptimizeVersion = this.getOptimizeVersion();

    if (getOptimizeVersion === undefined) {
      return undefined;
    } else if (getOptimizeVersion === 1) {
      return newUrl;
    } else {
      return defaultUrl;
    }
  }

  private getOptimizeVersion(): number {
    const gaExpCookie = this.cookieService.getCookie(this.GAEXP_STORAGE_KEY);
    if (gaExpCookie === undefined || gaExpCookie === '') {
      return undefined;
    }

    return gaExpCookie.charAt(gaExpCookie.length - 1) === '1' ? 1 : 0;
  }
}
