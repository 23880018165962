import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { VirtualsMenuSection, VirtualsState } from 'src/app/shared/models/virtuals.model';

@Injectable({
  providedIn: 'root',
})
export class VirtualsQuery extends Query<VirtualsState> {
  // Data
  menuSections$ = this.select(state => state.menuSections);
  groupEntityId$ = this.select(s => s.groupEntityId);

  constructor(protected store: VirtualsStore) {
    super(store);
  }

  get menuSections(): VirtualsMenuSection[] {
    return this.getValue().menuSections;
  }

  get groupEntityId(): number {
    return this.getValue().groupEntityId;
  }
}
