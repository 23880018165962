import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AccumulatorBonusContentModel } from 'src/app/modules/accounts/modules/auth/models/bonus.model';

@Component({
  selector: 'coupon-acca-bonus-details',
  templateUrl: './coupon-acca-bonus-details.component.html',
  styleUrls: ['./coupon-acca-bonus-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccumulatorBonusProgressionBarComponent {
  @Input() bonusAmount = 0;
  @Input() percentage = 0;
  @Input() show = true;
  @Input() showInfoIcon = true;
  @Input() cmsContent: AccumulatorBonusContentModel = {
    accumulatorBonusInfoModalBody: '',
    accumulatorBonusInfoModalCloseCTA: 'Okay',
    accumulatorBonusInfoModalSubtitle: '',
    accumulatorBonusInfoModalTitle: '',
    accumulatorBonusOddsValuePopupText: '',
    accumulatorBonusSelectionsTillBonusText: '',
    accumulatorBonusOneSelectionTillBonusText: '',
  };

  readonly showInfoModal$ = new BehaviorSubject(false);

  openAccBonusInfoPopup(): void {
    this.showInfoModal$.next(true);
  }

  closeAccBonusInfoPopup(): void {
    this.showInfoModal$.next(false);
  }
}
