<div class="odds-table-container" *ngIf="eventsInfo">
  <ng-container *ngIf="eventsInfo.marketSelected?.selections.length >= 4">
    <!-- Multi-line -->
    <app-multi-line-event
      *ngFor="let event of eventsInfo.matches; trackBy: eventTrackBy"
      [event]="event"
      [market]="eventsInfo.marketSelected"
      [sportId]="eventsInfo.sportId"
    ></app-multi-line-event>
  </ng-container>
  <ng-container *ngIf="eventsInfo.marketSelected?.selections.length < 4">
    <!-- Single-line -->
    <div class="markets-header">
      <app-events-markets-header [showMarketName]="showMarketName" [marketName]="eventsInfo.marketSelected?.name" [markets]="eventsInfo.marketSelected?.selections"></app-events-markets-header>
    </div>
    <app-single-line-event
      *ngFor="let event of eventsInfo.matches; trackBy: eventTrackBy; let last = last"
      [event]="event"
      [sportId]="eventsInfo.sportId"
      [last]="last"
      [selectedMarket]="eventsInfo.marketSelected"
    ></app-single-line-event>
  </ng-container>
</div>
