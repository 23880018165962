<div class="event-tournament-details">
  <div class="tournament-details">
    <span class="material-icons-outlined chart-icon" *ngIf="enableStatistics" aria-hidden="true"
      (click)="statisticsService.statisticsBetRadar(event.externalId)">{{'bar_chart'}}</span>
    <div class="location" (click)="navigateToEvent()">
      <div class="category-tournament">{{ event.categoryName }} - {{ event.tournamentName }}</div>
    </div>
  </div>
  <div class="odds-count" *ngIf="showOddCount" (click)="matchService.navigateToMatch(event.id, event.name)">
    <ng-container *ngIf="event?.oddCount">
      +{{ event.oddCount }}
    </ng-container>
  </div>
</div>
