<div class="my-bets-wrapper">
  <div class="my-bets-header">
    <span class="my-bets-title" i18n>My Bets</span>
    <span class="my-bets-close material-icons" (click)="goBack()">{{ 'close' }}</span>
  </div>

  <div class="top-panel">
    <div class="bets-tab-container">
      <div class="bets-tab selected" [class.selected]="(betsTabSelected$ | async) === betsTab.Open" (click)="changeBetsTab(betsTab.Open)" i18n>
        Open
      </div>
      <div class="bets-tab" [class.selected]="(betsTabSelected$ | async) === betsTab.Settled" (click)="changeBetsTab(betsTab.Settled)" i18n>
        Settled
      </div>
      <div
        class="bets-tab"
        [class.disabled]="(myBetsQuery.isBookedBetEnabled$ | async) === false"
        [class.selected]="(betsTabSelected$ | async) === betsTab.Booked"
        (click)="changeBetsTab(betsTab.Booked)"
        i18n
      >
        Booked
      </div>
    </div>
  </div>

  <div class="main-content" [ngClass]="{ authenticated: (accountQuery.isAuthenticated$ | async) }">
    <div class="product-tabs-container" appAdjustTabOnSelected>
      <div
        class="product-tab"
        *ngFor="let product of (productList$ | async)"
        [class.selected]="(productTabSelected$ | async) === product"
        (click)="changeProductTab(product)"
      >
        <div class="product-name" i18n>{{ product }}</div>
      </div>
    </div>

    <ng-container [ngSwitch]="(betsTabSelected$ | async)">
      <my-bets-recent-bets
        *ngSwitchCase="betsTab.Open"
        [bets]="myBetsQuery.myOpenBetsView$ | async"
        [loading]="myBetsQuery.openLoading$ | async"
        [productTabSelected]="productTabSelected$ | async"
      >
      </my-bets-recent-bets>
      <my-bets-recent-bets
        *ngSwitchCase="betsTab.Settled"
        [bets]="myBetsQuery.mySettledBets$ | async"
        [loading]="myBetsQuery.settledLoading$ | async"
        [isSettled]="true"
        [productTabSelected]="productTabSelected$ | async"
      ></my-bets-recent-bets>
      <my-bets-booked-bets *ngSwitchCase="betsTab.Booked"></my-bets-booked-bets>
    </ng-container>
  </div>
</div>
