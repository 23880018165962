<div class="acca-bonus-details-container" *ngIf="show">
  <div class="icon-and-perc">
    <span class="material-icons" (click)="openAccBonusInfoPopup()" *ngIf="showInfoIcon">{{'info'}}</span>
    <div class="percentage" i18n>{{ percentage | number: '1.0-0' }}% Accumulator Bonus Applied!</div>
  </div>
  <div class="amount" *ngIf="bonusAmount > 0">+ {{ bonusAmount | currencyFormat }}</div>
</div>

<app-info-modal [title]="cmsContent?.accumulatorBonusInfoModalTitle"
  [subtitle]="cmsContent?.accumulatorBonusInfoModalSubtitle" [bodyHTML]="cmsContent?.accumulatorBonusInfoModalBody"
  [buttonText]="cmsContent?.accumulatorBonusInfoModalCloseCTA" [show]="showInfoModal$ | async"
  (closeModal)="closeAccBonusInfoPopup()"></app-info-modal>
