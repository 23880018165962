import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { filter, map, takeUntil, tap, distinctUntilChanged, startWith, mergeMap } from 'rxjs/operators';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { ChangeDetectionStrategy, Component, OnDestroy, AfterViewInit, OnInit, ViewChild } from '@angular/core';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { fadeOut } from 'src/app/shared/animations';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusService } from 'src/app/core/services/bonuses/accumulator-bonus.service';
import { combineLatest, BehaviorSubject, Subject, Observable } from 'rxjs';
import { NavbarAccumulatorBonusProgressionBarComponent } from 'src/app/shared/components/nav-bar/nav-bar-acca-bonus-progression-bar/nav-bar-acca-bonus-progression-bar.component';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  animations: [fadeOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<boolean>();
  private readonly couponQuerySubsDestroy$ = new Subject<boolean>();
  private readonly MIN_ACCA_ODD_VALUE_KEY = '[[Min_Acca_Odd_Value]]';
  private readonly couponQuery$ = new BehaviorSubject<CouponQuery | VirtualsCouponQuery>(this.couponQuery);
  private readonly navbarAccumulatorBonusProgressionBarComponent$ = new BehaviorSubject<NavbarAccumulatorBonusProgressionBarComponent>(
    null
  );
  readonly betslipSelectionsTextValue$ = new BehaviorSubject('');
  readonly cmsContent$ = this.accumulatorBonusQuery.cmsContent$;
  readonly accumulatorBonusOddsValuePopupText$ = this.cmsContent$.pipe(
    filter(cmsContent => !!cmsContent),
    map(cmsContent =>
      cmsContent.accumulatorBonusOddsValuePopupText.includes(this.MIN_ACCA_ODD_VALUE_KEY)
        ? cmsContent.accumulatorBonusOddsValuePopupText.replace(
            this.MIN_ACCA_ODD_VALUE_KEY,
            this.accumulatorBonusQuery.minOddsValue.toString()
          )
        : cmsContent.accumulatorBonusOddsValuePopupText
    ),
    takeUntil(this.destroy$)
  );
  readonly couponSelectionsTotalOdds$ = new BehaviorSubject(0);

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    readonly applicationQuery: ApplicationQuery,
    readonly couponQuery: CouponQuery,
    readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly accumulatorBonusService: AccumulatorBonusService,
    private readonly applicationService: ApplicationService
  ) {}

  get accaBonusProgressBarElementVisibility$(): Observable<boolean> {
    // ACCA Bonus outer element visibilty is determined via three conditions
    // - Whether the user is in the coupon
    // - Whether the user is in whitelisted url
    // - Whether the ACCA bonus is enabled
    return combineLatest([this.applicationQuery.inCoupon$, this.accumulatorBonusQuery.isActiveUrlInAccumulatorBonusWhiteList$]).pipe(
      map(([inCoupon, isActiveUrlInAccumulatorBonusWhiteList]) => {
        return !inCoupon && isActiveUrlInAccumulatorBonusWhiteList && this.accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled;
      })
    );
  }

  @ViewChild(NavbarAccumulatorBonusProgressionBarComponent)
  set navbarAccCompSetter(component: NavbarAccumulatorBonusProgressionBarComponent) {
    if (component) {
      this.navbarAccumulatorBonusProgressionBarComponent$.next(component);
    }
  }

  closeOddsValueInfoPopup(): void {
    this.accumulatorBonusService.dismissAccumulatorBonusOddsValuePopup();
  }

  ngOnInit(): void {
    this.applicationQuery.isVirtuals$
      .pipe(
        distinctUntilChanged(),
        tap(isVirtuals => {
          this.couponQuerySubsDestroy$.next(true);
          this.couponQuery$.next(isVirtuals ? this.virtualsCouponQuery : this.couponQuery);
          // When switching between sports and virtuals, since we are changing the query we need to
          // close current subscriptions on the old query
          // and open new ones using the new query
          this.initialiseCouponQuerySubscriptions();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Maintain visibility of acca bonus unacceptable selection prompt
    combineLatest([
      this.accumulatorBonusQuery.showOddsValueInfoPopup$,
      this.applicationQuery.inCoupon$,
      this.accumulatorBonusQuery.isActiveUrlInAccumulatorBonusWhiteList$,
    ])
      .pipe(
        tap(([showOddsValueInfoPopup, inCoupon, isActiveUrlInAccumulatorBonusWhiteList]) => {
          setTimeout(() => {
            this.applicationService.updateUI({
              isShowingAccumulatorBonusUnacceptableSelectionsPrompt:
                showOddsValueInfoPopup &&
                isActiveUrlInAccumulatorBonusWhiteList &&
                !inCoupon &&
                this.accumulatorBonusQuery.isAccumulatorBonusProgressionBarEnabled,
            });
          }, 0);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.initialiseCouponQuerySubscriptions();
  }

  ngAfterViewInit(): void {
    this.navbarAccumulatorBonusProgressionBarComponent$
      .pipe(
        filter(navbarAccumulatorBonusProgressionBarComponent => !!navbarAccumulatorBonusProgressionBarComponent),
        mergeMap(navbarAccumulatorBonusProgressionBarComponent =>
          // There are two conditionals that determine the visibility of the bonus bar
          // - The inner conditional that is determined via the business logic
          //   of the acca bonus bar component - NavbarAccumulatorBonusProgressionBarComponent.isVisible$
          // - The outer conditional handled via accaBonusProgressBarElementVisibility$
          combineLatest([navbarAccumulatorBonusProgressionBarComponent.isVisible$, this.accaBonusProgressBarElementVisibility$]).pipe(
            startWith([false, false]),
            tap(([accumulatorBonusProgressionBarIsVisible, accaBonusProgressBarElementVisibility]) => {
              setTimeout(() => {
                this.applicationService.updateUI({
                  isShowingNavbarBetslipAccumulatorBonusBar:
                    accumulatorBonusProgressionBarIsVisible && accaBonusProgressBarElementVisibility,
                });
              }, 0);
            })
          )
        )
      )
      .subscribe();
  }

  private readonly initialiseCouponQuerySubscriptions = () => {
    combineLatest([
      this.couponQuery$.getValue().hasCouponSelections$,
      this.applicationQuery.inCoupon$,
      this.accumulatorBonusQuery.isActiveUrlInAccumulatorBonusWhiteList$,
    ])
      .pipe(
        tap(([couponSelections, inCoupon, isActiveUrlInAccumulatorBonusWhiteList]) => {
          this.applicationService.updateUI({
            isShowingNavbarBetslipSelections: couponSelections && !inCoupon && isActiveUrlInAccumulatorBonusWhiteList,
          });
        }),
        takeUntil(this.couponQuerySubsDestroy$)
      )
      .subscribe();

    this.couponQuery$
      .getValue()
      .couponTotalOdds$.pipe(
        tap(totalOdds => {
          this.couponSelectionsTotalOdds$.next(totalOdds);
        }),
        takeUntil(this.couponQuerySubsDestroy$)
      )
      .subscribe();

    this.couponQuery$
      .getValue()
      .couponData$.pipe(
        tap(couponData =>
          this.betslipSelectionsTextValue$.next(
            couponData && couponData.Odds && couponData.Odds.length > 0
              ? couponData.Odds.length === 1
                ? $localize`1 Selection`
                : $localize`${couponData.Odds.length} Selections`
              : ''
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe();
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.couponQuerySubsDestroy$.next(true);
    this.couponQuerySubsDestroy$.complete();
  }
}
