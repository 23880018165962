import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Bonus } from 'clientside-coupon';
import { SessionStorageService } from 'ngx-webstorage';
import { AccumulatorBonusContentModel, AccumulatorBonusState } from 'src/app/modules/accounts/modules/auth/models/bonus.model';

export const createInitialApplicationState = (): AccumulatorBonusState => ({
  dismissedAccumulatorBonusOddsValuePopup: false,
  showInfoModal: false,
  cmsContent: {
    accumulatorBonusInfoModalBody: '',
    accumulatorBonusInfoModalCloseCTA: 'Okay',
    accumulatorBonusInfoModalSubtitle: '',
    accumulatorBonusInfoModalTitle: '',
    accumulatorBonusOddsValuePopupText: 'Odds need to be greater than [[Min_Acca_Odd_Value]] contribute to your Accumulator Bonus',
    accumulatorBonusSelectionsTillBonusText: "Add [[Selection_Count]] more qualifying selections & we'll add 5% to your winnings",
    accumulatorBonusOneSelectionTillBonusText: "Add one more qualifying selection & we'll add 5% to your winnings",
  },
  bonusList: undefined,
});

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'accumulator-bonus' })
export class AccumulatorBonusStore extends Store<AccumulatorBonusState> {
  private readonly SPORTS_BONUSLIST_KEY: string = 'sportsbook.bonusList';
  private readonly VIRTUALS_BONUSLIST_KEY: string = 'virtuals.bonusList';

  constructor(private readonly applicationQuery: ApplicationQuery, private readonly sessionStorage: SessionStorageService) {
    super(createInitialApplicationState());
  }

  dismissAccumulatorBonusOddsValuePopup(): void {
    this.update({ dismissedAccumulatorBonusOddsValuePopup: true });
  }

  updateShowInfoModal(showInfoModal: boolean): void {
    this.update({ showInfoModal });
  }

  updateCMSContent(cmsContentValue: AccumulatorBonusContentModel): void {
    const storeCMSContent = this._value().cmsContent;
    this.update({
      cmsContent: {
        accumulatorBonusInfoModalBody: cmsContentValue.accumulatorBonusInfoModalBody
          ? cmsContentValue.accumulatorBonusInfoModalBody
          : storeCMSContent.accumulatorBonusInfoModalBody,
        accumulatorBonusInfoModalCloseCTA: cmsContentValue.accumulatorBonusInfoModalCloseCTA
          ? cmsContentValue.accumulatorBonusInfoModalCloseCTA
          : storeCMSContent.accumulatorBonusInfoModalCloseCTA,
        accumulatorBonusInfoModalSubtitle: cmsContentValue.accumulatorBonusInfoModalSubtitle
          ? cmsContentValue.accumulatorBonusInfoModalSubtitle
          : storeCMSContent.accumulatorBonusInfoModalSubtitle,
        accumulatorBonusInfoModalTitle: cmsContentValue.accumulatorBonusInfoModalTitle
          ? cmsContentValue.accumulatorBonusInfoModalTitle
          : storeCMSContent.accumulatorBonusInfoModalTitle,
        accumulatorBonusOddsValuePopupText: cmsContentValue.accumulatorBonusOddsValuePopupText
          ? cmsContentValue.accumulatorBonusOddsValuePopupText
          : storeCMSContent.accumulatorBonusOddsValuePopupText,
        accumulatorBonusSelectionsTillBonusText: cmsContentValue.accumulatorBonusSelectionsTillBonusText
          ? cmsContentValue.accumulatorBonusSelectionsTillBonusText
          : storeCMSContent.accumulatorBonusSelectionsTillBonusText,
        accumulatorBonusOneSelectionTillBonusText: cmsContentValue.accumulatorBonusOneSelectionTillBonusText
          ? cmsContentValue.accumulatorBonusOneSelectionTillBonusText
          : storeCMSContent.accumulatorBonusOneSelectionTillBonusText,
      },
    });
  }

  updateBonusList(bonusList: Bonus[]): void {
    if (!bonusList) {
      this.clearBonusList();
      return;
    }

    this.update({ bonusList });
    this.sessionStorage.store(this.applicationQuery.isVirtuals ? this.VIRTUALS_BONUSLIST_KEY : this.SPORTS_BONUSLIST_KEY, bonusList);
  }

  clearBonusList(): void {
    this.update({ bonusList: undefined });
    this.sessionStorage.clear(this.applicationQuery.isVirtuals ? this.VIRTUALS_BONUSLIST_KEY : this.SPORTS_BONUSLIST_KEY);
  }
}
