import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { Bonus } from 'clientside-coupon';
import { BehaviorSubject, Subject, fromEvent, combineLatest } from 'rxjs';
import { filter, takeUntil, tap, debounceTime, startWith } from 'rxjs/operators';
import { AccumulatorBonusContentModel } from 'src/app/modules/accounts/modules/auth/models/bonus.model';

@Component({
  selector: 'app-nav-bar-acca-bonus-progression-bar',
  templateUrl: './nav-bar-acca-bonus-progression-bar.component.html',
  styleUrls: ['./nav-bar-acca-bonus-progression-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarAccumulatorBonusProgressionBarComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() accumulatorBonusValidSelectionCount = 0;
  @Input() accumulatorBonusPercentage = 0;
  @Input() accumulatorBonusList: Bonus[] = [];
  @Input() lastPercentageItemShown: number = 0;
  @Input() showInfoIcon = true;
  @Input() cmsContent: AccumulatorBonusContentModel = undefined;

  @ViewChildren('accaBonusStep') accaBonusSteps!: QueryList<ElementRef>;

  private readonly destroy$ = new Subject<boolean>();
  private readonly minimumSelectionCount$ = new BehaviorSubject(0);
  private readonly STEP_SCROLLING_OFFSET = 2;
  private readonly stepsScrollingTrigger$ = new BehaviorSubject(false);
  readonly showInfoModal$ = new BehaviorSubject(false);
  readonly hasAccumulatorBonus$ = new BehaviorSubject(false);
  readonly isVisible$ = new BehaviorSubject(false);
  accumulatorBonusPercentageIndex = 0;

  openAccBonusInfoPopup(): void {
    this.showInfoModal$.next(true);
  }

  closeAccBonusInfoPopup(): void {
    this.showInfoModal$.next(false);
  }

  prepareAccumulatorBonusSelectionsTillBonusText(): string {
    if (this.cmsContent) {
      const selectionsTillBonusCount = this.minimumSelectionCount$.getValue() - this.accumulatorBonusValidSelectionCount;
      return selectionsTillBonusCount === 1
        ? this.cmsContent.accumulatorBonusOneSelectionTillBonusText
        : this.cmsContent.accumulatorBonusSelectionsTillBonusText
            .replace('[[Selection_Count]]', selectionsTillBonusCount.toString())
            .toString();
    } else {
      return '';
    }
  }

  accumulatorBonusListTrackBy(bonus: Bonus): string {
    return `${bonus.NumberOfEvents}_${bonus.Percentage}`;
  }

  ngAfterViewInit(): void {
    combineLatest([this.accaBonusSteps.changes.pipe(startWith(undefined as any)), this.stepsScrollingTrigger$])
      .pipe(
        filter(() => this.accaBonusSteps && this.accaBonusSteps.length > 0),
        tap(() => {
          this.reduceAccumulatorBonusList();
          this.handleStepScrolling(this.accaBonusSteps);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    fromEvent(window, 'resize')
      .pipe(
        debounceTime(500),
        tap(() => {
          this.triggerStepScrolling();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  reduceAccumulatorBonusList(): void {
    if (this.lastPercentageItemShown) {
      this.accumulatorBonusPercentageIndex =
        this.accumulatorBonusList?.findIndex(bonus => bonus.Percentage === this.lastPercentageItemShown) + 1;
    } else {
      this.accumulatorBonusPercentageIndex = this.accumulatorBonusList?.length;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reduceAccumulatorBonusList();
    if (
      changes.accumulatorBonusList &&
      changes.accumulatorBonusList.previousValue !== changes.accumulatorBonusList.currentValue &&
      changes.accumulatorBonusList.currentValue !== []
    ) {
      this.minimumSelectionCount$.next(
        changes.accumulatorBonusList.currentValue.reduce((prev, curr) => (prev.NumberOfEvents < curr.NumberOfEvents ? prev : curr))
          .NumberOfEvents
      );
    }

    if (changes.accumulatorBonusList && changes.accumulatorBonusList.firstChange) {
      this.triggerStepScrolling();
    }

    if (
      changes.accumulatorBonusPercentage &&
      changes.accumulatorBonusPercentage.previousValue !== changes.accumulatorBonusPercentage.currentValue
    ) {
      // Check Bonus state
      this.hasAccumulatorBonus$.next(changes.accumulatorBonusPercentage.currentValue > 0);

      // Manage Step Scrolling
      if (changes.accumulatorBonusPercentage.currentValue !== changes.accumulatorBonusPercentage.previousValue) {
        this.triggerStepScrolling();
      }
    }

    // Check component visibility
    if (
      changes.accumulatorBonusValidSelectionCount &&
      changes.accumulatorBonusValidSelectionCount.previousValue !== changes.accumulatorBonusValidSelectionCount.currentValue
    ) {
      this.isVisible$.next(
        this.hasAccumulatorBonus$.getValue() ||
          (!this.hasAccumulatorBonus$.getValue() && changes.accumulatorBonusValidSelectionCount.currentValue > 1)
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private handleStepScrolling(steps: QueryList<ElementRef>): void {
    const scrollPosition = this.calculateScrollingPosition(steps);

    steps.forEach(element => {
      element.nativeElement.style.right = scrollPosition;
    });
  }

  private calculateScrollingPosition(steps: QueryList<ElementRef>): string {
    let scrollPosition = 0;
    // Get index to scroll to
    const scrollItemIndex = this.accumulatorBonusList.findIndex(bonus => bonus.Percentage === this.accumulatorBonusPercentage);

    // Get offset of current selected step to the middle of the screen
    if (scrollItemIndex > this.STEP_SCROLLING_OFFSET && scrollItemIndex < this.accumulatorBonusList.length - this.STEP_SCROLLING_OFFSET) {
      // Get step element width
      const stepWidth = steps.first.nativeElement.getBoundingClientRect().width;
      // Get Current Position shift and parse to float
      let currentPositionShift = steps.first.nativeElement.style.right.replace('px', '');
      currentPositionShift = currentPositionShift === '' ? 0 : parseFloat(currentPositionShift);
      // Get middle of window
      const windowMidPosition = window.innerWidth / 2;
      // Get offset of current item to window
      scrollPosition = steps.get(scrollItemIndex).nativeElement.getBoundingClientRect().right - windowMidPosition - stepWidth / 2;
      // Calculate scroll position
      scrollPosition = scrollPosition + (currentPositionShift as number);
    }

    // Set final scroll position
    return `${scrollPosition}px`;
  }

  private triggerStepScrolling(): void {
    this.stepsScrollingTrigger$.next(!this.stepsScrollingTrigger$.value);
  }
}
