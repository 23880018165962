import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { MobileNumberInputService } from 'src/app/core/services/account/mobile-number-input.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { OTPInputFieldComponent } from 'src/app/shared/components/otp/otp-input-field/otp-input-field.component';
import { ButtonType } from 'src/app/shared/models/button.model';
import { OTPScreenContentModel } from 'src/app/shared/models/otp.model';

@Component({
  selector: 'app-otp-screen',
  templateUrl: './otp-screen.component.html',
  styleUrls: ['./otp-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OTPScreenComponent implements OnInit, OnDestroy {
  @Input() content: OTPScreenContentModel;
  // To be used only for Registration or Reset Password
  // when user may not be logged in and needs OTP on his username, if he has a verified mobile number
  @Input() username: string;
  // If the username is present, the mobile prefix and mobile number is ignored
  @Input() mobilePrefix: string;
  @Input() mobileNumber: string;
  @Input() genericError = false;
  @Input() otpOption: number;
  // This will be used once the OTP screen will be implemented for when an unverified user logs in and is shown the OTP process.
  @Input() otpOptionAfterResend: number = undefined;
  @ViewChild(OTPInputFieldComponent) otpInputField: OTPInputFieldComponent;
  @Output() readonly postOTP = new EventEmitter();
  @Output() readonly resendCodeOutput = undefined;
  @Output() readonly codeResent = new EventEmitter();
  @Output() readonly startLoading = new EventEmitter();
  @Output() readonly stopLoading = new EventEmitter();

  buttonType = ButtonType;
  inputOTPMessage: string[];
  inputOTPMessageHasUserPhoneNumber: boolean;
  inputOTPMessageHasUserUsername: boolean;
  invalidCode$ = new BehaviorSubject<boolean>(false);
  tooManyRequests$ = new BehaviorSubject<boolean>(false);
  limitExceeded$ = new BehaviorSubject<boolean>(false);
  otpCodeLength = this.appConfig.get('otp').otpCodeLength;
  otpAllowResendWithoutTimer = this.appConfig.get('otp').otpAllowResendWithoutTimer;
  resendTimer = this.appConfig.get('otp').otpResendTimer;
  verifyingCode$ = new BehaviorSubject<boolean>(false);
  mobilePrefixHasPlusSymbol: boolean;

  ctaAdditionalStyles = {
    fontSize: '14px',
    minWidth: '110px',
    height: '40px',
  };

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly hasResentCode$ = new BehaviorSubject(false);

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly notificationService: NotificationService,

    private readonly mobileNumberInputService: MobileNumberInputService
  ) {}

  ngOnInit(): void {
    this.initialiseInputOTPView();

    /*
      There might be a case with a LoginType of UsernameOrMobile
      that the user submits a full number (prefix wihtout + & mobile number),
      which is then accepted under the criteria of a username.
      This is still a valid user, as at BE level, the username will be constructed from the mobileprefix (without +),
      and the mobile number
      In this case, we need to format what we use in the OTP process to cater for this, and avoid having duplicate prefixes.
    */
    if (!this.username) {
      // check mobile Number if it has '0' in front
      this.mobileNumber = this.mobileNumberInputService.mobileNumberCleanup(this.mobileNumber);

      this.mobileNumber =
        !this.mobileNumber.match(this.appConfig.get('registration').mobileRegex) &&
        this.mobileNumber.startsWith(this.mobilePrefix.replace('+', ''))
          ? this.mobileNumber.replace(this.mobilePrefix.replace('+', ''), '')
          : this.mobileNumber;
    }

    this.verifyingCode$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      if (status) {
        this.startLoading.emit();
      } else {
        this.stopLoading.emit();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  resendCode(): void {
    const body: any = {
      RequestTypeOption: this.otpOption,
      Data: {},
    };

    switch (this.otpOption) {
      case 2: // Reset Password
      case 3: // Registration
        body.Data = {
          Username: this.username || `${this.mobilePrefix.replace('+', '')}${this.mobileNumber}`,
        };
        break;
      case 4: // Change Phone Number
      case 5: // Verify Phone Number
        body.Data = {
          UserId: this.accountQuery.userData.id,
          Mobile: `${this.mobilePrefix}${this.mobileNumber}`,
        };
        break;
      default:
        break;
    }

    // clear previous error statuses
    this.tooManyRequests$.next(false);
    this.limitExceeded$.next(false);

    this.accountService
      .regenerateTokenForService(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.hasResentCode$.next(true);
          this.codeResent.emit();
        },
        error: error => {
          if (error.error.ResponseCode === 20805) {
            this.limitExceeded$.next(true);
          } else if (error.error.ResponseCode === 20810) {
            this.tooManyRequests$.next(true);
          } else {
            this.notificationService.showErrorNotification($localize`An error occurred during PIN generation. Please try again later.`);
          }
        },
      });
  }

  verifyCode(): void {
    if (this.otpInputField.code$.value) {
      const username = this.accountQuery.isAuthenticated
        ? this.accountQuery.userData.username
        : this.username || `${this.mobilePrefix.replace('+', '')}${this.mobileNumber}`;
      this.verifyingCode$.next(true);
      this.accountService
        .validateOneTimePassword(
          username,
          this.otpInputField.code$.value,
          this.hasResentCode$.value && this.otpOptionAfterResend !== undefined ? this.otpOptionAfterResend : this.otpOption
        )
        .pipe(
          finalize(() => {
            this.verifyingCode$.next(false);
            this.otpInputField.clearCode();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe(
          () => {
            // Success
            this.postOTP.emit({
              username,
              otpCode: this.otpInputField.code$.value,
            });
          },
          () => {
            // Error
            this.invalidCode$.next(true);
          }
        );
    } else {
      this.invalidCode$.next(true);
    }
  }

  private initialiseInputOTPView(): void {
    if (this.content) {
      this.inputOTPMessageHasUserPhoneNumber = this.content.sentOTPMessage.includes('[[User_PhoneNumber]]');
      this.inputOTPMessageHasUserUsername = this.content.sentOTPMessage.includes('[[User_Username]]');
      this.inputOTPMessage = this.inputOTPMessageHasUserPhoneNumber
        ? this.content.sentOTPMessage.split('[[User_PhoneNumber]]')
        : this.inputOTPMessageHasUserUsername
        ? this.content.sentOTPMessage.split('[[User_Username]]')
        : (this.inputOTPMessage = [this.content.sentOTPMessage]);
    }

    this.mobilePrefixHasPlusSymbol = this.mobilePrefix.includes('+');
  }
}
