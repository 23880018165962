export enum APIMyBetsCouponStatus {
  Unknown = 0,
  All = 1,
  Open = 2,
  Won = 3,
  Lost = 4,
  Cancelled = 5,
  Settled = 6,
}

export enum RecentBetsStatus {
  Running = -1,
  Won = 1,
  PartiallyWon = 2,
  Lost = 3,
  Cancelled = 4,
  CashedOut = 5,
  HalfLost = 6,
  HalfWon = 7,
}

export enum MyBetsCouponStatus {
  Open = 1,
  Settled = 2,
}

export enum BetsTab {
  Open,
  Settled,
  Booked,
}
