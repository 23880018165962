import { formatCurrency } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BetCoupon,
  BetCouponGlobalVariable,
  BetCouponGroup,
  Bonus,
  ClientsideCouponService,
  CouponAction,
  CouponType,
  Dictionary,
  Selection,
  UpdateCouponRequest,
  UpdateCouponResponse,
} from 'clientside-coupon';
import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, first, map, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { RecentBets } from 'mobile-virtuals-soccer';
import { LocalStorageService } from 'ngx-webstorage';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';
import { CouponGroupingType, CouponSettings, DefaultCouponStake } from 'src/app/shared/models/coupon.model';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { APIService } from 'src/app/core/services/api.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsCouponService implements OnDestroy {
  loading: boolean = false;
  enforceSingleCombination: boolean = false;
  private readonly couponDataKey = 'virtualsCouponData';
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly couponService: CouponService,
    private readonly dataLayerService: DataLayerService,
    private readonly localStorage: LocalStorageService,
    private readonly myBetsService: MyBetsService,
    private readonly notificationService: NotificationService,
    private readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly virtualsCouponStore: VirtualsCouponStore
  ) {
    this.virtualsCouponQuery.couponSettings$.subscribe(sub => {
      this.virtualsCouponStore.updateCouponSettings(sub);
    });
  }

  get currencySymbol(): string {
    return this.accountQuery.userData && this.accountQuery.userData.currency.symbol
      ? this.accountQuery.userData.currency.symbol
      : this.appConfig.get('sports').coupon.defaultCurrency;
  }

  initialize(): void {
    this.populateSportsbookVariables()
      .pipe(first())
      .subscribe(() => {
        this.virtualsCouponStore.updateCouponInitialized(true);
        this.removeExpiredOdds();
      });
  }

  rebetCoupon(couponCode: string, language: string = 'en'): Observable<any> {
    return this.apiService.get(APIType.VirtualsSportsbook, `api/coupons/rebet/byCode/${couponCode}/language/${language}`).pipe(
      map(data => {
        if (data.BetCoupon) {
          this.updateCoupon(data.BetCoupon);
          this.applicationService.showCoupon({ isVirtuals: true });
          return data;
        } else {
          return false;
        }
      })
    );
  }

  addOdd(odd: Selection, language: string = 'en'): UpdateCouponResponse {
    return this.addOddClientSide(odd);
  }

  removeOdd(oddId: number): UpdateCouponResponse {
    this.virtualsCouponStore.clearGroupingTab();

    const selection = new Selection();
    selection.oddId = oddId;

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.RemoveOdd,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
        selection: selection,
      })
    );

    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);

    return response;
  }

  updateOddBankerStatus(oddId: number, isBanker: boolean): UpdateCouponResponse {
    const selection = new Selection();
    selection.oddId = oddId;

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateOddBankerStatus,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
        selection: selection,
        isBanker: isBanker,
      })
    );
    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  clearAllBankers(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ClearAllBankers,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrix,
      })
    );
    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  removeOdds(oddIds: number[]): void {
    let updatedCoupon = this.virtualsCouponQuery.couponData;

    oddIds.forEach(odd => {
      const selection = new Selection();
      selection.oddId = odd;

      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: updatedCoupon,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.virtualsCouponQuery.globalVariables,
          marketExceptions: this.virtualsCouponQuery.marketExceptions,
          correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
          selection: selection,
        })
      );

      updatedCoupon = response.updatedCoupon;
    });

    this.virtualsCouponStore.updateCouponData(updatedCoupon);
  }

  /** Returns the number of removed odds. */
  removeExpiredOdds(): number {
    if (!this.virtualsCouponQuery.couponData) {
      return;
    }

    const expiredOdds = this.virtualsCouponQuery.couponData.Odds.filter(odd => new Date(odd.EventDate) < new Date());
    this.removeOdds(expiredOdds.map(odd => odd.SelectionId));

    return expiredOdds.length;
  }

  setCouponData(couponData: BetCoupon): void {
    if (!couponData) {
      this.virtualsCouponStore.clearCouponData();
    } else {
      this.virtualsCouponStore.updateCouponData(couponData);
    }
  }

  updateStakeValue(stakeValue: number): UpdateCouponResponse {
    if (stakeValue >= 0) {
      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.UpdateStakeValue,
          brandID: this.appConfig.get('brandId'),
          coupon: this.virtualsCouponQuery.couponData,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.virtualsCouponQuery.globalVariables,
          marketExceptions: this.virtualsCouponQuery.marketExceptions,
          correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
          stakeValue: stakeValue,
        })
      );

      this.virtualsCouponStore.updateCouponData(response.updatedCoupon);

      return response;
    }
  }

  updateGroupings(groupings: BetCouponGroup[]): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupings,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
        groupings: groupings,
      })
    );

    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateGroupingStakeValue(grouping: BetCouponGroup): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupingStakeValue,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
        groupings: [grouping],
        groupingStakeValue: grouping.Stake,
      })
    );

    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  isGroupingVisible(grouping: BetCouponGroup, isLast: boolean): boolean {
    const groupingsTabSelected = this.virtualsCouponQuery.groupingsTabSelected;

    if (groupingsTabSelected === CouponGroupingType.Split && isLast) {
      return true;
    } else if (groupingsTabSelected === CouponGroupingType.Combination) {
      if (!isLast && grouping.Grouping !== 1) {
        return true;
      }
    } else if (groupingsTabSelected === CouponGroupingType.Singles && grouping.Grouping === 1) {
      return true;
    }

    return false;
  }

  validateCoupon(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrix,
      })
    );

    return response;
  }

  validateAndPostCoupon(): Observable<boolean> {
    if (this.virtualsCouponQuery.couponData === null) {
      return of(false);
    }

    const validation = this.validateCoupon();

    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postCoupon();
    }
  }

  updateCouponSetting(couponSettingKey: string, couponSetting: any): void {
    const setting = new CouponSettings({});
    setting[couponSettingKey] = couponSetting;
    this.virtualsCouponStore.updateCouponSetting(setting);
  }

  clearCouponData(): void {
    this.virtualsCouponStore.clearCouponData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    this.virtualsCouponStore.updateDefaultCouponStake(defaultCouponStake);
  }

  getStatus(statusCode: number): string {
    return this.getInsertCouponStatus(statusCode);
  }

  updateCoupon(couponData: any): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrix,
      })
    );

    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  getInsertCouponStatus(statusCode: number): string {
    return this.couponService.insertCouponStatusCodes[statusCode];
  }

  getUpdateCouponStatus(statusCode: number): string {
    return this.couponService.updateCouponStatusCodes[statusCode];
  }

  private addOddClientSide(odd: Selection): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.AddOdd,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
        selection: odd,
        allowSameMatchSelections: this.appConfig.get('sports').allowSameMatchSelections,
      })
    );

    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);

    return response;
  }

  private postCoupon(): Observable<boolean> {
    const couponData = this.localStorage.retrieve(this.couponDataKey);
    const couponStake = couponData ? couponData.Stake : undefined;

    this.loading = true;
    return this.syncCouponToServer().pipe(
      concatMap(syncResponse => {
        if (!syncResponse.success) {
          this.loading = false;
          this.handleErrorMessage(syncResponse.statusCode);
          return of(false);
        }

        return this.insertCoupon().pipe(
          map(insertResponse => {
            this.loading = false;
            const betResponseStatus = insertResponse.statusCode;

            if (!insertResponse.success) {
              this.notificationService.showErrorNotification(
                this.getInsertCouponStatus(insertResponse.statusCode),
                $localize`Coupon Not Posted`
              );
              return false;
            }

            // Bet is placed successfully
            if (betResponseStatus === 1) {
              this.dataLayerService.createDataLayerEvent({
                event: 'user-place-virtual-bet',
                userId: this.accountQuery.userData.id,
                couponID: insertResponse.couponCode,
                totalStake: couponStake,
              });
            }

            this.accountService.updateBalance();
            this.myBetsService.addNewBet(insertResponse.couponCode);
            this.notificationService.showSuccessNotification(this.getStatus(insertResponse.statusCode), $localize`Coupon Posted`);

            return true;
          })
        );
      })
    );
  }

  private handleErrorMessage(statusCode: number): void {
    const errorMessage = this.getUpdateCouponStatus(statusCode);

    // Show error message according to the status code returned
    if (statusCode === 19) {
      // 19 => 'Stake under minimum amount allowed'. In that case we include the minimum stake amount
      this.notificationService.showErrorNotification(`${errorMessage} of ${this.getFormattedMinStake()}`, $localize`Coupon Not Posted`);
    } else if (statusCode === 21) {
      const message = $localize`Maximum win amount over the limit of ${this.getFormattedMaxWin()}`;

      this.notificationService.showErrorNotification(message, $localize`Coupon Not Posted`);
    } else if (statusCode === 22) {
      // 22 => 'Group stake under minimum amount allowed'. In that case we include the minimum group stake amount
      const minMessage = $localize`The minimum acceptable total stake is ${this.getFormattedTotalStake()}.`;
      this.notificationService.showErrorNotification(
        `${errorMessage} of ${this.getFormattedMinGroupStake()}. ${minMessage}`,
        $localize`Coupon Not Posted`
      );
    } else {
      this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
    }
  }

  private populateSportsbookVariables(): Observable<void> {
    const apiCalls = [
      this.apiService.get<any>(APIType.VirtualsSportsbookFeed, `api/settings/bonuslist`),
      this.apiService.get<any>(APIType.VirtualsSportsbookFeed, `api/settings/globalvariables`),
      this.apiService.get<any>(APIType.VirtualsSportsbookFeed, `api/settings/incompatiblemarketexceptions`),
    ];
    const correctScoreOddsMatrix = this.virtualsCouponQuery.correctScoreOddsMatrix;
    if (
      !correctScoreOddsMatrix ||
      correctScoreOddsMatrix.cacheVersion !== this.appConfig.get('siteVersion') ||
      !correctScoreOddsMatrix.data
    ) {
      apiCalls.push(this.apiService.get<any>(APIType.VirtualsSportsbookFeed, `api/settings/selectionCompatibilityMatrix`));
    }

    return forkJoin(apiCalls).pipe(
      map(([bonusListData, globalVariablesData, marketExceptionsData, correctScoreOddsMatrixData]) => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }

        if (globalVariablesData !== undefined) {
          const globalVariables: BetCouponGlobalVariable = globalVariablesData;
          this.virtualsCouponStore.updateGlobalVariables(globalVariables);
        }

        if (marketExceptionsData !== undefined) {
          const marketExceptions: Dictionary<number, number[]> = marketExceptionsData;
          this.virtualsCouponStore.updateMarketExceptions(marketExceptions);
        }

        if (correctScoreOddsMatrixData !== undefined) {
          this.virtualsCouponStore.updateCorrectScoreOddsMatrix({
            cacheVersion: this.appConfig.get('siteVersion'),
            data: correctScoreOddsMatrixData,
          });
        }
      })
    );
  }

  private syncCouponToServer(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.virtualsCouponQuery.couponSettings.transferUserId });
    return this.apiService
      .put<any>(APIType.VirtualsSportsbook, `api/BetCoupons/UpdateCouponLite`, this.virtualsCouponQuery.couponData, apiSettings)
      .pipe(
        map(response => {
          let couponSuccess = false;
          if (response.ResponseStatus === 0) {
            // BTK-1299: The following line has been temporarily commented out because the response returned by the api
            // doesn't yet match our model structure, resulting in lost/misplaced data
            // this.setCouponData(this.clientsideCouponService.formatCoupon(response.BetCoupon));

            // BTK-1419: Temporarily creating a copy of the OddValue field in a new field called ConfirmedOddValue.
            // This is usually done by the api but for now we have to do it manually due to the above commented out line
            const couponData = cloneDeep(this.virtualsCouponQuery.couponData);
            couponData.Odds.forEach(odd => {
              odd.ConfirmedOddValue = odd.OddValue;
            });
            this.virtualsCouponStore.updateCouponData(couponData);

            couponSuccess = true;
          } else if (response.ResponseStatus === 17) {
            // One of the odds has changed.
            if (response.BetCoupon !== undefined) {
              this.updateCoupon(response.BetCoupon);
            }

            couponSuccess = true;
          } else if (response.ResponseStatus === 18) {
            // Event expired
            this.removeExpiredOdds();
          } else if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          return {
            success: couponSuccess,
            statusCode: response.ResponseStatus,
          };
        })
      );
  }

  private insertCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.virtualsCouponQuery.couponSettings.transferUserId });
    const bodyData = {
      AllowOddChanges: false, // TODO: Will we have changing odds sometime?
      AllowStakeReduction: this.virtualsCouponQuery.couponSettings.allowStakeReduction,
      BetCoupon: this.virtualsCouponQuery.couponData,
      RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
      TransferStakeFromAgent: this.virtualsCouponQuery.couponSettings.transferUserId === null ? false : true,
    };

    return this.apiService.post<any>(APIType.VirtualsSportsbook, `api/coupons/InsertCouponLite`, bodyData, apiSettings).pipe(
      tap(response => {
        if (response.ResponseStatus === 1) {
          this.saveSelectionsToLocalStorage(bodyData.BetCoupon);
        }
      }),
      map(responseData => {
        let couponPosted = false;
        if (responseData.ResponseStatus === 1) {
          couponPosted = true;
          this.virtualsCouponStore.clearCouponData();
        }

        return {
          success: couponPosted,
          statusCode: responseData.ResponseStatus,
          couponId: responseData.CouponId,
          couponCode: responseData.CouponCode,
        };
      })
    );
  }

  private getFormattedMinStake(): string {
    const minAllowedStake = this.virtualsCouponQuery.globalVariables.MinBetStake;
    return formatCurrency(minAllowedStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private getFormattedMinGroupStake(): string {
    const minAllowedGroupingStake = this.virtualsCouponQuery.globalVariables.MinGroupingsBetStake;
    return formatCurrency(minAllowedGroupingStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private getFormattedTotalStake(): string {
    const minAllowedGroupingStake = this.virtualsCouponQuery.globalVariables.MinGroupingsBetStake;
    let totalCombinations = 0;
    let totalStake = 0;

    this.virtualsCouponQuery.couponData.Groupings.forEach(group => {
      totalCombinations += group.Combinations;
    });

    totalStake = minAllowedGroupingStake * totalCombinations;

    return formatCurrency(totalStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private getFormattedMaxWin(): string {
    let maxWin = 0;
    const couponType =
      this.virtualsCouponQuery.couponData && this.virtualsCouponQuery.couponData.CouponTypeId !== undefined
        ? this.virtualsCouponQuery.couponData.CouponTypeId
        : CouponType.Single;

    switch (couponType) {
      case CouponType.Single: {
        maxWin = this.virtualsCouponQuery.globalVariables.MaxSingleBetWin;
        break;
      }
      case CouponType.Multiple: {
        maxWin = this.virtualsCouponQuery.globalVariables.MaxMultipleBetWin;
        break;
      }
      case CouponType.System: {
        maxWin = this.virtualsCouponQuery.globalVariables.MaxCombinationBetWin;
        break;
      }
      default:
        maxWin = this.virtualsCouponQuery.globalVariables.MaxSingleBetWin;
    }

    return this.formatShortNumber(maxWin);
  }

  private formatShortNumber(num: number): string {
    let abs = Math.abs(num);
    let multiplicatorSymbol = '';

    const multiplicatorSymbols = [
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 },
    ];

    for (const symbol of multiplicatorSymbols) {
      let quotient = abs / symbol.value;
      quotient = Math.round(quotient * 10) / 10;
      if (quotient >= 1) {
        abs = quotient;
        multiplicatorSymbol = symbol.key;
        break;
      }
    }

    // TODO: this doesn't support localization, Intl.NumberFormat could be an option
    return `${this.currencySymbol}${num < 0 ? '-' : ''}${abs}${multiplicatorSymbol}`;
  }

  private saveSelectionsToLocalStorage(coupon: BetCoupon): void {
    const recentBetsStorageKey = this.appConfig.get('virtuals').config.recentBetsStorageKey;

    if (!recentBetsStorageKey) {
      return;
    }

    const recentSelections: RecentBets = JSON.parse(localStorage.getItem(recentBetsStorageKey)) || {};

    coupon.Odds.forEach(odd => {
      const selectionsForTournament = recentSelections[odd.TournamentId] || [];

      if (selectionsForTournament.length === 0) {
        recentSelections[odd.TournamentId] = selectionsForTournament;
      }

      selectionsForTournament.push({
        marketId: odd.MarketId,
        marketName: odd.MarketName,
        matchId: odd.MatchId,
        oddValue: odd.OddValue,
        selectionId: odd.IDSelectionType,
        selectionName: odd.SelectionName,
        oddsBoost: false,
      });
    });

    localStorage.setItem(recentBetsStorageKey, JSON.stringify(recentSelections));
  }
}
