import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ExternalUrlRedirectResolver implements Resolve<any> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    if (window.location.href !== (route.data as any).externalUrl) {
      window.location.href = (route.data as any).externalUrl;
    }
  }
}
